import OutsideClickHandler from 'react-outside-click-handler'
import styled from 'styled-components'
import { useState, useRef, useEffect } from 'react'
import { debounce } from 'lodash'

const Wrapper = styled.div`
  display: inline-block;
  position: relative;
`

const Container = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 1px 5px rgba(16, 24, 40, 0.25);
  position: absolute;
  z-index: 1;
`

const Icon = styled.span`
  display: flex;
`

function Dropdown(props) {
  const [delayHandler, setDelayHandler] = useState(null)
  const [visibile, setVisibile] = useState(false)
  const [programmaticPosition, setProgrammaticPosition] = useState({
    height: '1px',
    width: '1px',
    overflow: 'hidden'
  })
  const container = useRef(null)
  const wrapper = useRef(null)

  const mouseOff = () => {
    if (props.hover) {
      setDelayHandler(setTimeout(() => {
        setVisibile(false)
      }, 500))
    }
  }

  const mouseOn = () => {
    if (props.hover) {
      setVisibile(true)
      clearTimeout(delayHandler)
    }
  }

  const setPosition = () => {
    if (props.positionProgrammatically && wrapper.current) {
      const wrapperRect = wrapper.current.getBoundingClientRect()

      let position = {}

      if (props.positionProgrammatically === 'right') {
        position = {
          transform: `translate(0, calc(-50% + ${wrapperRect.height / 2}px))`,
          left: `${window.scrollX + wrapperRect.left + wrapperRect.width + 10}px`,
          top: `${window.scrollY + wrapperRect.top}px`
        }
      } else {
        position = {
          left: `${window.scrollX + wrapperRect.left}px`,
          top: `${window.scrollY + wrapperRect.top}px`
        }
      }

      setProgrammaticPosition(position)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', debounce(setPosition, 20))

    return () => {
      window.removeEventListener('resize', debounce(setPosition, 20))
    }
  }, [])

  useEffect(() => {
    if (visibile) {
      setPosition()
    }
  }, [visibile])

  return (
    <Wrapper
      ref={wrapper}
      style={{...props.wrapperStyle}}
      onMouseLeave={() => {
        mouseOff()
      }}
      onMouseEnter={() => {
        mouseOn()
      }}
    >
      <OutsideClickHandler
        display="flex"
        onOutsideClick={() => {
          setVisibile(false)
        }}
      >
        <Icon
          onClick={() => {
            if (!props.noOpenOnClick) {
              setVisibile(true)
            }

            setVisibile(!visibile)
          }}
        >
          {props.icon}
        </Icon>

        {
          (!props.disabled && visibile) &&
          <Container
            style={{
              ...props.style,
              ...(props.positionProgrammatically ? programmaticPosition : {})
            }}
            ref={container}
            onClick={() => {
              if (!props.noCloseOnClick) {
                setVisibile(false)
              }
            }}
          >
            {props.children}
          </Container>
        }
      </OutsideClickHandler>
    </Wrapper>
  )
}

export default Dropdown
