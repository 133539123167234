import { useAuth } from '@utilities/auth'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

function Logout() {
  const navigate = useNavigate()
  const auth = useAuth()

  useEffect(() => {
    auth.signOut(() => {
      return navigate('/user/login')
    })
  }, [navigate, auth])

  return null
}

export default Logout
