import { React } from 'react'
import styled from 'styled-components'
import OutsideClickHandler from 'react-outside-click-handler'

import { rem } from 'polished'
import { isFunction } from 'lodash'

const ModalOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  bottom: 0;
  height: 100vh;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  width: 100vw;
  z-index: 1000;
`

const ModalContainer = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04);
  color: var(--text-light);
  left: 50%;
  max-height: calc(100vh - 70px);
  max-width: 90%;
  overflow-y: auto;
  padding: 24px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: ${props => props.modalWidth || `1000px`};
  z-index: 1001;

  @media only screen and (min-width: 992px) {
    max-height: calc(100vh - 120px);
  }
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`

const Icon = styled.div`
  align-items: center;
  ${props => props.type === 'error-prompt' ? 'background-color: var(--modal-icon-background-color-error );' : 'background-color: var(--modal-icon-background-color );'}
  border-radius: 100%;
  ${props => props.type === 'error-prompt' ? 'color: var(--error-text);' : 'color: var(--primary);'}
  display: flex;
  height: 56px;
  justify-content: center;
  margin-right: 12px;
  width: 56px;

  svg {
    ${props => props.iconFill && `fill: var(${props.type === 'error-prompt' ? '--error-text' : '--modal-icon-color'});`}
    ${props => !props.iconFill && `stroke: var(${props.type === 'error-prompt' ? '--error-text' : '--modal-icon-color'});`}
    height: 30px;
    width: 30px;
    font-size: 30px;
  }
`

const Heading = styled.h2`
  font-size: ${rem(18)};
  font-weight: 400;
`

function Modal(props) {
  return (
    <ModalOverlay>
      <OutsideClickHandler
        onOutsideClick={() => {
          if (props.closeOnOutsideClick && isFunction(props.close)) {
            props.close()
          }
        }}
      >
        <ModalContainer modalWidth={props.modalWidth}>
          <TitleContainer>
            <Icon type={props.type} iconFill={props.iconFill} colorSelector={props.colorSelector || ''} >{props.icon}</Icon>
            <Heading>{props.title}</Heading>
          </TitleContainer>

          {props.children}
        </ModalContainer>
      </OutsideClickHandler>
    </ModalOverlay>
  )
}

export default Modal
