import { createGlobalStyle } from 'styled-components'

const LoaderStyles = createGlobalStyle`
  .primary-loader {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    position: relative;
    animation: loaderRotate 1s linear infinite;

    &:before , &:after {
      content: ' ';
      box-sizing: border-box;
      position: absolute;
      inset: 0px;
      border-radius: 50%;
      border: 3px solid #1570ef;
      animation: loaderHalfCircle 2s linear infinite;
    }

    &:after{
      border-color: #1570ef;
      animation: loaderHalfCircle 2s linear infinite , loaderRotate 0.5s linear infinite reverse;
      inset: 4px;
    }
  }

  &.light {
    height: 25px;
    margin: 0 auto;
    width: 25px;

    &:before , &:after {
      border: 2px solid #ffffff;
    }
  }

  @keyframes loaderRotate {
    0% {transform: rotate(0deg)}
    100% {transform: rotate(360deg)}
  }

  @keyframes loaderHalfCircle {
    0% {clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%)}
    100% {clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%)}
  }
`
export default LoaderStyles