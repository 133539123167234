import React, { useEffect, useRef, useCallback } from 'react'
import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames'
import styled from 'styled-components'
import useLocalStorage from 'use-local-storage'
import { atom, useRecoilState } from 'recoil'
import { head, includes, map, without } from 'lodash'
import { rem } from 'polished'
import { useTable, usePagination } from 'react-table'
import { v4 as uuid } from 'uuid'

import Input from '@components/form/Input'
import Pill from '@components/pill/Pill'
import PillWrapper from '@components/pill/PillWrapper'
import Select from '@components/form/Select'
import Tabs from '@components/Tabs'
import { mediaBreakpointUp } from '@utilities/breakpoint'

const rowSpacing = '15px 20px'
const rowSpacingSmall = '10px 20px'

const TableWrapper = styled.div`
  border-radius: 8px;
  border: 1px solid #E4E7EC;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
`

const TableFiltersMedia = `
  ${mediaBreakpointUp('lg', `
    flex-wrap: nowrap;
    margin-top: 0;
    width: auto;
  `)}

  ${mediaBreakpointUp('sm', `
    & > div {
      flex-basis: auto;
    }
  `)}
`

const FilterSearchStyle = `
  .filter-search {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    width: 100%;

    & > div {
      flex-basis: 100%;
    }

    ${TableFiltersMedia}

    .search {
      display: inline-flex;
    }
  }
`

const TableHeader = styled.div`
  align-items: center;
  border-bottom: 1px solid #E4E7EC;
  display: block;
  padding: ${rowSpacing};

  .header {
    display: inline-block;
    font-size: ${rem(18)};
    font-weight: 500;
    margin-right: 10px;
  }

  ${FilterSearchStyle}
`

const TableFilters = styled.div`
  border-bottom: 1px solid #E4E7EC;
  padding: ${rowSpacing};

  ${FilterSearchStyle}
`

const ResponsiveWrapper = styled.div`
  overflow-x: auto;
`

const StyledTable = styled.table`
  border-spacing: 0;
  min-width: 750px;
  width: 100%;
`

const TableHead = styled.thead`
  background: #F9FAFB;
  border: none;
  color: #667085;
  font-size: ${rem(14)};
  text-align: left;

  th {
    border-bottom: 1px solid #E4E7EC;
    font-weight: 400;
    padding: ${rowSpacing};
  }
`

const TableBody = styled.tbody`
  border: none;
  text-align: left;

  td {
    border-bottom: 1px solid #E4E7EC;
    color: #667085;
    font-size: ${rem(14)};
    padding: ${props => props.compact ? rowSpacingSmall : rowSpacing};

    &.collapsible-content {
      padding: 0;
    }
  }

  .center {
    text-align: center;
  }

  .highlight {
    background-color: #F0F9FF;
  }
`

const CollapsibleContent = styled.tr`
  display: none;

  &.visible {
    display: table-row;
  }
`

const Pagination = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: ${rem(14)};
  justify-content: space-between;
  user-select: none;

  .left {
    min-width: 300px;
    text-align: left;

    .label {
      display: inline-block;
      margin-right: 15px;
    }
  }

  .right {
    align-self: center;
    text-align: right;
    justify-content: flex-end;
  }

  .left, .right {
    align-items: center;
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    padding: 10px 20px;
  }
`

const PageNumber = styled.div`
  display: inline-block;
  margin-right: 40px;
`

const NavigationButton = styled.div`
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  display: inline-block;
  margin: 6px 10px;
  opacity: ${props => props.disabled ? '0.4 !important' : 1};
  padding: 2px 6px;
  transition: all 0.1s;

  &:hover {
    opacity: 0.6;
  }
`

const DropdownIcon = styled.td`
  cursor: pointer;

  .icon {
    &.flipped {
      transform: rotate(180deg);
    }
  }
`

const searchTermState = atom({
  key: `searchTerm-${uuid()}`,
  default: {
    value: '',
    type: null
  },
})

const selectedTabState = atom({
  key: `selectedTab-${uuid()}`,
  default: null,
})

const collapsibleContentState = atom({
  key: `collapsibleContent-${uuid()}`,
  default: [],
})

function Table(props) {
  const [collapsibleContent, setCollapsibleContent] = useRecoilState(collapsibleContentState)
  const [defaultPageSize, setDefaultPageSize] = useLocalStorage('table.pageSize', { value: '15', label: '15' })
  const [searchTerm, setSearchTerm] = useRecoilState(searchTermState)
  const [selectedTab, setSelectedTab] = useRecoilState(selectedTabState)
  const firstLoad = useRef(true)
  const { columns, data, searchTypes, getTableData } = props

  const {
    canNextPage,
    canPreviousPage,
    getTableBodyProps,
    getTableProps,
    gotoPage,
    headerGroups,
    nextPage,
    pageCount,
    pageOptions,
    prepareRow,
    previousPage,
    rows,
    setPageSize,
    state: {
      pageIndex,
      pageSize
    },
  } = useTable(
    {
      columns,
      data: data.data,
      manualPagination: true,
      pageCount: data.last_page,
      initialState: {
        pageIndex: 0,
        pageSize: defaultPageSize.value
      }
    },
    usePagination
  )

  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false

      if (searchTypes) {
        setSearchTerm({
          ...searchTerm,
          type: head(searchTypes).value
        })
      }
    }

    getTableData({
      pageIndex: pageIndex,
      pageSize: pageSize,
      filters: {
        search: searchTerm,
        tab: selectedTab
      }
    })
  }, [
    getTableData,
    pageIndex,
    pageSize,
    searchTerm,
    selectedTab,
    searchTypes,
    setSearchTerm
  ])

  const tabChange = useCallback(value => {
    setSelectedTab(value)
  }, [setSelectedTab])

  const searchBox = (
    <div className="filter-search">
      {
        props.searchTypes &&
        <div className="search">
          <Select
            isSearchable={false}
            trailingInput
            display="inline-flex"
            width="auto"
            defaultValue={head(props.searchTypes)}
            options={props.searchTypes}
            onChange={option => {
              setSearchTerm({
                ...searchTerm,
                type: option.value
              })
            }}
          />
        </div>
      }

      <Input
        placeholder="Search"
        leadingSelect={props.searchTypes ? true : false}
        searchIcon
        value={searchTerm.value}
        onChange={event => {
          setSearchTerm({
            ...searchTerm,
            value: event.target.value
          })
        }}
      />
    </div>
  )

  return (
    <TableWrapper>
      <TableHeader>
        <div className="row">
          <div className="col-12 col-lg-6 align-self-center">
            <div className="header">
              {props.header}
            </div>

            {
              props.headerPills &&
              <PillWrapper>
                {map(props.headerPills, (pill, index) => {
                  return (
                    <Pill color={pill.color} key={index}>
                      {pill.title}
                    </Pill>
                  )
                })}
              </PillWrapper>
            }
          </div>

          <div className="col-12 col-lg-6 d-flex justify-content-lg-end">
            {props.topSearch ? searchBox : ''}
          </div>
        </div>
      </TableHeader>

      {
        (props.filterTabs || props.filterSearch) &&
        <TableFilters>
          <div className="row">
            <div className="col-12 col-lg-6">
              <Tabs
                tabs={props.filterTabs}
                onChange={tabChange}
              />
            </div>

            <div className="col-12 col-lg-6 d-flex justify-content-lg-end">
              {props.filterSearch ? searchBox : ''}
            </div>
          </div>
        </TableFilters>
      }

      <ResponsiveWrapper>
        <StyledTable {...getTableProps()}>
          <TableHead>
            {map(headerGroups, headerGroup => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
              >
                {map(headerGroup.headers, (column, columnIndex) => (
                  <React.Fragment key={columnIndex}>
                    {props.collapsibleContent && columnIndex === 0 && <th style={{ width: '50px' }}></th>}

                    <th
                      {...column.getHeaderProps()}
                      style={{
                        width: column.width || 'initial'
                      }}
                    >
                      {column.render('Header')}
                    </th>
                  </React.Fragment>
                ))}
              </tr>
            ))}
          </TableHead>

          <TableBody
            {...getTableBodyProps()}
            compact={props.compact}
          >
            {
              rows.length ?
              map(rows, (row, rowIndex) => {
                prepareRow(row)

                return (
                  <React.Fragment key={rowIndex}>
                    <tr
                      {...row.getRowProps()}
                      className={classNames({
                        highlight: includes(collapsibleContent, rowIndex)
                      })}
                    >
                      {
                        props.collapsibleContent &&
                        <DropdownIcon
                          onClick={() => {
                            let newCollapsibleContent

                            if (includes(collapsibleContent, rowIndex)) {
                              newCollapsibleContent = without(collapsibleContent, rowIndex)
                            } else {
                              newCollapsibleContent = [...collapsibleContent, rowIndex]
                            }

                            setCollapsibleContent(newCollapsibleContent)
                          }}
                        >
                          <i
                            className={classNames('fa-regular fa-chevron-down icon', {
                              flipped: includes(collapsibleContent, rowIndex)
                            })}
                          ></i>
                        </DropdownIcon>
                      }

                      {map(row.cells, cell => {
                          return (
                            <td
                              {...cell.getCellProps([{
                                style: cell.column.style,
                              }])}
                            >
                              {props.loading || firstLoad.current ? <Skeleton /> : cell.render('Cell')}
                            </td>
                          )
                      })}
                    </tr>

                    {
                      (props.loading && includes(collapsibleContent, rowIndex)) ?
                      <tr>
                        <td colSpan={props.collapsibleContent ? (columns.length + 1) : columns.length}>
                          <Skeleton />
                        </td>
                      </tr> :
                      <CollapsibleContent
                        className={classNames({
                          visible: includes(collapsibleContent, rowIndex)
                        })}
                      >
                        <td
                          className='collapsible-content'
                          colSpan={props.collapsibleContent ? (columns.length + 1) : columns.length}
                        >
                          {row.original.collapsibleContent}
                        </td>
                      </CollapsibleContent>
                    }
                  </React.Fragment>
                )
              }) :
              (
                <tr>
                  <td colSpan={props.collapsibleContent ? (columns.length + 1) : columns.length} className="center">
                    {props.loading || firstLoad.current ? <Skeleton /> : 'No data to display.'}
                  </td>
                </tr>
              )
            }
          </TableBody>
        </StyledTable>
      </ResponsiveWrapper>

      <Pagination>
        <div className="left">
          <div className="label">
            Rows per page
          </div>

          <Select
            isSearchable={false}
            small
            display="inline-block"
            width="auto"
            defaultValue={[
              defaultPageSize
            ]}
            options={[
              { value: '15', label: '15' },
              { value: '25', label: '25' },
              { value: '50', label: '50' },
              { value: '100', label: '100' },
            ]}
            onChange={option => {
              setDefaultPageSize(option)
              setPageSize(option.value)
            }}
          />
        </div>

        <div className="right">
          <PageNumber>
            Page {pageIndex + 1} of {pageOptions.length}
          </PageNumber>

          <NavigationButton onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            <i className="far fa-chevron-double-left"></i>
          </NavigationButton>

          <NavigationButton onClick={() => previousPage()} disabled={!canPreviousPage}>
            <i className="far fa-chevron-left"></i>
          </NavigationButton>

          <NavigationButton onClick={() => nextPage()} disabled={!canNextPage}>
            <i className="far fa-chevron-right"></i>
          </NavigationButton>

          <NavigationButton onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            <i className="far fa-chevron-double-right"></i>
          </NavigationButton>
        </div>
      </Pagination>
    </TableWrapper>
  )
}

export default Table
