import * as yup from 'yup'
import GoogleLogin from 'react-google-login'
import styled from 'styled-components'
import { atom, useRecoilState } from 'recoil'
import { darken } from 'polished'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'

import AuthAlert from '@components/AuthAlert'
import Input from '@components/form/Input'
import InputError from '@components/form/InputError'
import { Button } from '@components/form/Buttons'
import { ReactComponent as GoogleIcon } from '@icons/google.svg'
import { useAuth } from '@utilities/auth'

const Title = styled.h1`
  font-size: 30px;
  font-weight: 700;
`

const Form = styled.form`
  label {
    margin-top: 20px;
  }

  .buttons {
    margin-top: 40px;
  }
`

const SubText = styled.div`
  color: #667085;
  font-size: 16px;
  font-weight: 300;
  margin: 12px 0;
`

const GoogleButton = styled.a`
  align-items: center;
  background-color: white;
  border-radius: 8px;
  border: 1px solid var(--border-color);
  color: var(--text-dark);
  cursor: pointer;
  display: flex;
  font-size: 16px;
  justify-content: center;
  line-height: 42px;
  margin-top: 24px;
  padding: 0 20px;
  text-decoration: none;
  transition: all 0.2s ease;
  width: 100%;

  &:hover {
    background-color: ${darken(0.2, '#ffffff')};
  }

  svg {
    margin-right: 12px;
  }
`

const ForgotPassword = styled.a`
  color: var(--primary);
  display: flex;
  font-size: 14px;
  font-weight: 300;
  justify-content: center;
  margin: 24px 0;
  text-align: center;
  text-decoration: none;
`

const authAlertState = atom({
  key: 'authAlert',
  default: {}
})

const schema = yup.object({
  email: yup.string().label('Email').email().required(),
  password: yup.string().label('Password').required(),
})

function Login() {
  const [alert, setAlert] = useRecoilState(authAlertState)
  const auth = useAuth()
  const navigate = useNavigate()
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    if (auth.loggedIn) {
      navigate('/dashboard', { replace: true })
    }
  }, [auth, navigate])

  const signIn = data => {
    setAlert(null)

    auth.signIn(data, (success, hasProgram) => {
      if (success) {
        if (hasProgram) {
          navigate('/overview')
        } else {
          navigate('/configuration')
        }
      } else {
        setAlert({ message: 'The provided credentials do not match our records.' })
      }
    })
  }

  const googleSignIn = response => {
    auth.googleSignIn(response.accessToken, (success, hasProgram) => {
      if (success) {
        if (hasProgram) {
          navigate('/overview')
        } else {
          navigate('/configuration')
        }
      } else {
        setAlert({ message: 'The provided credentials do not match our records.' })
      }
    })
  }

  const responseGoogle = response => {
    if (response?.error === 'idpiframe_initialization_failed') {
      return false
    }

    setAlert({ message: 'We were unable to log you in with Google.' })
  }

  return (
    <div className="d-flex flex-column justify-content-center">
      <Form onSubmit={handleSubmit(signIn)} autoComplete="off">
        <Title>Log in</Title>
        <SubText>Welcome Back! Please enter your details.</SubText>

        <AuthAlert class={alert ? 'd-flex' : 'd-none'} type={alert?.type ? alert.type : null} message={alert?.message} />

        <Input
          label="Email"
          type="email"
          placeholder="Enter your email"
          autoComplete="username"
          className={errors.email && 'error'}
          {...register('email')}
        />
        {errors.email && <InputError message={errors.email.message} />}

        <Input
          label="Password"
          type="password"
          placeholder="Enter your password"
          autoComplete="current-password"
          className={errors.password && 'error'}
          {...register('password')}
        />
        {errors.password && <InputError message={errors.password.message} />}

        <ForgotPassword href="forgot-password">
          Forgot Password
        </ForgotPassword>

        <Button
          style={{
            width: '100%'
          }}
        >
          Sign in
        </Button>

        <GoogleLogin
          clientId={ process.env.REACT_APP_GOOGLE_CLIENT_ID }
          render={renderProps => (
            <GoogleButton onClick={renderProps.onClick} disabled={renderProps.disabled}>
              <GoogleIcon /> Sign in with Google
            </GoogleButton>
          )}
          onSuccess={googleSignIn}
          onFailure={responseGoogle}
          cookiePolicy={'single_host_origin'}
        >
          Login
        </GoogleLogin>
      </Form>
    </div>
  )
}

export default Login
