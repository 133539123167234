import { createGlobalStyle } from 'styled-components'
import 'react-loading-skeleton/dist/skeleton.css'

const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;

    --heading-color: #101828;
    --text-dark: #344054;
    --text-light: #667085;
    --error-text: #d92d20;

    --primary: #1570ef;

    --border-color: #D0D5DD;

    --modal-icon-background-color: #f5fbff;
    --modal-icon-background-color-error: #fee4e2;
    --modal-icon-color: #1570ef;
  }

  *,
  *::before,
  *::after {
      box-sizing: inherit;
  }

  html, body, #root {
    color: var(--heading-color);
    font-family: 'Inter', sans-serif;
    box-sizing: border-box;
  }

  h1, h2, h3, h4, h5, h6 {
      color: var(--heading-color);
  }
`
export default GlobalStyles