import AlarmsAll from '@components/tables/AlarmsAll'
import PageContainer from '@components/layout/helpers/PageContainer'
import PageTitle from '@components/layout/helpers/PageTitle'

function AlarmIndex() {
  return (
    <>
      <PageContainer contained>
        <PageTitle>
          All alarms
        </PageTitle>

        <AlarmsAll />
      </PageContainer>
    </>
  )
}

export default AlarmIndex
