import Bugsnag from '@bugsnag/js'
import styled from 'styled-components'
import { atom, useRecoilState, useSetRecoilState } from 'recoil'
import { darken } from 'polished'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import axios from '@utilities/axios'
import { mediaBreakpointUp } from '@utilities/breakpoint'

const Configuration = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  min-height: calc(100vh - 113px);
  width: 100%;
`

const HeadingContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  ${mediaBreakpointUp('lg', `
    margin-top: 0px;
  `)}
`

const Heading = styled.h1`
  text-align: center;
`

const SubHeading = styled.div`
  color: var(--text-light);
  text-align: center;
`

const ConfigurationBlockContainer = styled.div`
  margin-top: 40px;

  ${mediaBreakpointUp('lg', `
    margin-top: 88px;
  `)}
`

const ConfigurationBlock = styled.div`
  background-color: #f2f4f7;
  border-radius: 16px;
  color: var(--text-light);
  margin-bottom: 20px;
  min-height: 231px;
  padding: 56px 0 0;
  text-align: center;

  .name {
    margin-top: 20px;
  }

  svg {
    height: 64px;
    width: 64px;
  }

  .map-icon {
    svg {
      stroke: var(--text-light);
    }
  }

  .rtu-icon {
    svg {
      fill: var(--text-light);
    }
  }

  .program-icon {
    svg {
      fill: var(--text-light);
    }
  }

  &:hover {
    .name {
      color: var(--heading-color);
    }

    .map-icon {
    svg {
      stroke: var(--heading-color);
    }
  }

  .rtu-icon {
    svg {
      fill: var(--heading-color);
    }
  }

  .program-icon {
    svg {
      fill: var(--heading-color);
    }
  }
}
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 56px;
  border-top: 1px solid #ffffff;

  > {
    &:first-child {
      border-radius: 0 0 0 12px;
      border-right: 1px solid #ffffff;
    }

    &:nth-child(2) {
      border-radius: 0 0 12px 0;
    }
  }
`

const Button = styled.a`
  align-items: center;
  background-color: #f2f4f7;
  border: none;
  color: var(--text-light);
  cursor: pointer;
  display: flex;
  display: inline-block;
  flex-basis: 50%;
  font-size: 16px;
  justify-content: center;
  line-height: 44px;
  padding: 0 20px;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease;

  &.disabled {
    cursor: not-allowed;
  }

  &:hover {
    background-color: ${darken(0.1, '#f2f4f7')};
  }
`

const LocationState = atom({
  key: 'locations',
  default: {}
})

const SiteState = atom({
  key: 'sites',
  default: {}
})

const alertState = atom({
  key: 'alert',
  default: {}
})

const modalState = atom({
  key: 'modal',
  default: null,
})

function ConfigurationPage () {
  const [locations, setLocations] = useRecoilState(LocationState)
  const [sites, setSites] = useRecoilState(SiteState)
  const setModal = useSetRecoilState(modalState)
  const setAlert = useSetRecoilState(alertState)
  const navigate = useNavigate()

  useEffect(() => {
    const getSites = async () => {
      try {
        const  { data } = await axios.get(`/sites`)

        setSites(data.sites)
      } catch (error) {
        Bugsnag.notify(error)
      }
    }

    const getLocations = async () => {
      try {
        const { data } = await axios.get(`/locations`)

        if (data && data.success && data.locations.length) {
          setLocations(data.locations)
        }
      } catch (error) {
        Bugsnag.notify(error)
      }
    }

    getLocations()
    getSites()
  }, [setSites, setLocations])

  return (
    <Configuration>
      <HeadingContainer>
        <Heading>FutureOps Configuration</Heading>
        <SubHeading>Manage FutureOps Operations</SubHeading>
      </HeadingContainer>

      <div className="container">
        <ConfigurationBlockContainer className='row'>
          <div className='col-12 col-lg-4'>
            <ConfigurationBlock>
              <div>
                <div className="map-icon"><i className="fa-light fa-location-dot fa-4x"></i></div>
                <div className="name">Location</div>
              </div>

              <ButtonGroup className="button-group">
                <Button
                  onClick={() => {
                    setModal({
                      name: 'location'
                    })
                  }}
                >
                  Add
                </Button>
                <Button as="span" onClick={() => navigate('/locations')}>
                  Manage
                </Button>
              </ButtonGroup>
            </ConfigurationBlock>
          </div>

          <div className='col-12 col-lg-4'>
            <ConfigurationBlock>
              <div>
                <div className="rtu-icon"><i className="fak fa-rtu fa-4x"></i></div>
                <div className="name">Site</div>
              </div>

              <ButtonGroup className="button-group">
                <Button className={locations.length ? '' : 'disabled'} onClick={
                  () => {
                    if (locations.length) {
                      setModal({
                        name: 'site'
                      })
                    } else {
                      setAlert({ type: 'info', content: 'You need to create a location before trying to create a site.' })
                    }
                  }
                }>
                  Add
                </Button>

                <Button as="span" onClick={() => navigate('/sites')}>
                  Manage
                </Button>
              </ButtonGroup>
            </ConfigurationBlock>
          </div>

          <div className='col-12 col-lg-4'>
            <ConfigurationBlock>
              <div>
                <div className="program-icon"><i className="fa-light fa-sliders-up fa-4x"></i></div>
                <div className="name">Program</div>
              </div>

              <ButtonGroup className="button-group">
                <Button className={sites.length ? '' : 'disabled'} onClick={
                  () => {
                    if (sites.length) {
                      setModal({
                        name: 'program'
                      })
                    } else {
                      setAlert({ type: 'info', content: 'You need to create a location and a site before trying to create a program.' })
                    }
                  }
                }>
                  Add
                </Button>

                <Button as="span" onClick={() => navigate('/programs')}>
                  Manage
                </Button>
              </ButtonGroup>
            </ConfigurationBlock>
          </div>
        </ConfigurationBlockContainer>
      </div>
    </Configuration>
  )
}

export default ConfigurationPage
