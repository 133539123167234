import Bugsnag from '@bugsnag/js'
import styled from 'styled-components'
import { atom, useRecoilState, useSetRecoilState } from 'recoil'
import { debounce, map } from 'lodash'
import { useEffect, useMemo, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import ActionIcon from '@components/ActionIcon'
import CircleList from '@components/CircleList'
import DropdownList from '@components/DropdownList'
import PageContainer from '@components/layout/helpers/PageContainer'
import PageTitle from '@components/layout/helpers/PageTitle'
import Pill from '@components/pill/Pill'
import PillWrapper from '@components/pill/PillWrapper'
import Table from '@components/Table'
import axios from '@utilities/axios'
import { Anchor } from '@components/form/Buttons'

const ButtonContainer = styled.div`
  text-align: right;
  margin-top: 15px;
`

const tableDataState = atom({
  key: 'locationIndexData',
  default: null,
})

const tableDataLoadingState = atom({
  key: 'locationIndexTableLoading',
  default: true,
})

const modalState = atom({
  key: 'modal',
  default: null,
})

function LocationIndex() {
  const [tableData, setTableData] = useRecoilState(tableDataState)
  const [tableDataLoading, setTableDataLoading] = useRecoilState(tableDataLoadingState)
  const setModal = useSetRecoilState(modalState)
  const navigate = useNavigate()

  const tableColumns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        width: '15%',
      },
      {
        Header: 'Description',
        accessor: 'description',
        width: '25%',
      },
      {
        Header: 'Sites',
        accessor: 'sites',
        width: '35%',
      },
      {
        Header: 'Authorized members',
        accessor: 'authorised',
        width: '20%',
      },
      {
        Header: '',
        accessor: 'action',
        width: '30px',
        style: {
          textAlign: 'right'
        },
      },
    ], []
  )

  const getTableData = useMemo(() => debounce(async ({ pageIndex, pageSize, filters }) => {
    const query = new URLSearchParams([
      ['page', pageIndex + 1],
      ['pageSize', pageSize],
      ['search', filters?.search?.value || ''],
      ['with[]', 'sites'],
    ])

    try {
      const { data } = await axios.get(`/location/query?${query}`)

      let locationData = map(data.locations.data, location => {
        return {
          name: location.name,
          address: location.address,
          description: location.description,
          authorised: (
            <CircleList
              tooltips
              compact
              circles={map(location.users, user => {
                return {
                  text: user.name
                }
              })}
            />
          ),
          sites: (
            location.sites &&
            <PillWrapper>
              {map(location.sites, (site, index) => {
                return (
                  <Pill color="#344054" key={index}>
                    {site.name}
                  </Pill>
                )
              })}
            </PillWrapper>
          ),
          action: (
            <DropdownList
              icon={<ActionIcon />}
              positionProgrammatically
              wrapperStyle={{
                position: 'static'
              }}
              style={{
                minWidth: '200px',
                transform: 'translate(calc(-100% + 25px), 25px)',
                textAlign: 'left'
              }}
              options={[
                {
                  label: 'View location',
                  onClick: () => {
                    navigate(`/location/view/${location.id}`)
                  }
                },
                {
                  label: 'Manage location',
                  onClick: () => {
                    navigate(`/location/manage/${location.id}`)
                  }
                },
                {
                  label: 'Edit location',
                  onClick: () => {
                    setModal({
                      name: 'location',
                      data: location
                    })
                  }
                },
                {
                  label: 'Add site',
                  onClick: () => {
                    setModal({
                      name: 'site',
                      data: {
                        locationId: location.id
                      }
                    })
                  }
                }
              ]}
            />
          ),
        }
      })

      let tableData = {
        ...data.locations,
        data: locationData
      }

      setTableData(tableData)
      setTableDataLoading(false)
    } catch (error) {
      Bugsnag.notify(error)
    }
  }, 1000), [setTableData, setTableDataLoading, navigate, setModal])

  const getTableDataStart = useCallback(params => {
    setTableDataLoading(true)
    getTableData(params)
  }, [getTableData, setTableDataLoading])

  useEffect(() => {
    getTableData({
      pageSize: 15,
      pageIndex: 0
    })
  }, [getTableData])

  return (
    <>
      <PageContainer contained>
        <PageTitle>
          All locations
        </PageTitle>

        {
          tableData &&
          <Table
            columns={tableColumns}
            data={tableData}
            getTableData={getTableDataStart}
            header="Locations"
            loading={tableDataLoading}
            topSearch
            headerPills={[
              {
                title: `${tableData.total} locations`,
                color: '#175CD3'
              }
            ]}
          />
        }

        <ButtonContainer>
          <Anchor
            style={{
              minWidth: '200px'
            }}
            onClick={() => {
              setModal({ name: 'location' })
            }}
          >
            Add location
          </Anchor>
        </ButtonContainer>
      </PageContainer>
    </>
  )
}

export default LocationIndex
