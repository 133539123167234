import { GoogleMap, Marker } from '@react-google-maps/api'
import { map, isFunction, forEach, isEmpty, truncate } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'

import sleep from '@utilities/sleep'

const MapWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const MapLoader = styled.div`
  align-items: center;
  background: rgba(247, 247, 247, 1);
  border-radius: ${props => props.square ? 0 : '8px'};
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10000;
`

function Map(props) {
  const [googleMapMarkers, setGoogleMapMarkers] = useState([])
  const [googleMap, setGoogleMap] = useState(null)
  const [mapLoading, setMapLoading] = useState(true)

  const mapLoaded = useCallback(map => {
    setGoogleMap(map)
  }, [setGoogleMap])

  const mapUnmount = useCallback(map => {
    setGoogleMap(null)
  }, [setGoogleMap])

  const markerLoaded = useCallback(marker => {
    setGoogleMapMarkers(googleMapMarkers => [...googleMapMarkers, marker])
  }, [setGoogleMapMarkers])

  useEffect(() => {
    (async () => {
      if (googleMap && googleMapMarkers.length) {
        const bounds = new window.google.maps.LatLngBounds()

        forEach(googleMapMarkers, marker => {
          bounds.extend(marker.getPosition())
        })

        googleMap.fitBounds(bounds)

        if (googleMapMarkers.length === 1) {
          googleMap.setZoom(16)
        }
      }

      await sleep(500)

      setMapLoading(false)
    })()
  }, [googleMapMarkers, googleMap, setMapLoading])

  const containerStyle = {
    borderRadius: props.square ? 0 : '8px',
    width: '100%',
    height: '100%'
  }

  const mapCenter = !isEmpty(props.center) ? props.center : null

  return (
    <MapWrapper>
      {
        mapLoading &&
        <MapLoader square={props.square}>
          <div className="primary-loader"></div>
        </MapLoader>
      }

      <GoogleMap
        mapContainerStyle={containerStyle}
        zoom={props.zoom || 8}
        center={mapCenter}
        onLoad={mapLoaded}
        onUnmount={mapUnmount}
        onClick={event => {
          if (isFunction(props.onClick)) {
            props.onClick(event)
          }
        }}
        options={{
          mapTypeId: "satellite",
          fullscreenControl: false,
          streetViewControl: false,
          mapTypeControl: false,
          minZoom: 2,
          restriction: {
            latLngBounds: {
              east: 179.9999,
              north: 85,
              south: -85,
              west: -179.9999
            },
            strictBounds: true
          }
        }}
      >
        { map(props.markers, marker => {
          if (marker) {
            return <Marker
              clickable={isFunction(marker.clickHandler)}
              key={marker.id}
              position={marker.position}
              title={marker.title}
              onLoad={markerLoaded}
              onClick={event => {
                if (isFunction(marker.clickHandler)) {
                  marker.clickHandler(event, marker.id)
                }
              }}
              draggable={marker.draggable || false}
              onDragEnd={event => {
                if (isFunction(marker.dragEnd)) {
                  marker.dragEnd(event, marker.id)
                }
              }}
              label={{
                color: '#ffffff',
                fontWeight: 'bold',
                text: truncate(marker.title, {
                  length: 16,
                  omission: '...',
                  separator: /,? +/
                }),
              }}
              icon={{
                anchor: new window.google.maps.Point(265, 200),
                fillColor: marker.fillColor || '#EB2127',
                fillOpacity: 1.0,
                path: 'M26.77,5h467a21.74,21.74,0,0,1,21.74,21.74V140.91a21.74,21.74,0,0,1-21.74,21.74h-467A21.74,21.74,0,0,1,5,140.91V26.77A21.74,21.74,0,0,1,26.77,5Z',
                rotation: 0,
                scale: 0.25,
                strokeColor: '#ffffff',
                strokeWeight: 0,
                labelOrigin: new window.google.maps.Point(265, 86),
                boxShadow: '1px 1px 4px 2px rgb(0 0 0 / 60%)'
              }}
            />
          }
        }) }
      </GoogleMap>
    </MapWrapper>
  )
}

export default Map
