import styled from 'styled-components'
import { useEffect, useMemo, useCallback } from 'react'
import { atom, useRecoilState, useSetRecoilState } from 'recoil'
import { camelCase, chain, debounce, forEach, map, mapKeys, reverse, sortBy, toLower, upperFirst } from 'lodash'
import { useNavigate } from 'react-router-dom'

import ActionIcon from '@components/ActionIcon'
import Bugsnag from '@bugsnag/js'
import DropdownList from '@components/DropdownList'
import Table from '@components/Table'
import Pill from '@components/pill/Pill'
import axios from '@utilities/axios'
import { Anchor } from '@components/form/Buttons'

const siteStatsState = atom({
  key: 'siteStatsData',
  default: null,
})

const tableDataState = atom({
  key: 'siteIndexData',
  default: null,
})

const tableDataLoadingState = atom({
  key: 'siteIndexTableLoading',
  default: true,
})

const modalState = atom({
  key: 'modal',
  default: null,
})

const ButtonContainer = styled.div`
  text-align: right;
  margin-top: 15px;
`

function SitesAll() {
  const [tableData, setTableData] = useRecoilState(tableDataState)
  const [siteStats, setSiteStats] = useRecoilState(siteStatsState)
  const [tableDataLoading, setTableDataLoading] = useRecoilState(tableDataLoadingState)
  const setModal = useSetRecoilState(modalState)
  const navigate = useNavigate()

  const getTableData = useMemo(() => debounce(async ({ pageIndex, pageSize, filters }) => {
    const query = new URLSearchParams([
      ['page', pageIndex + 1],
      ['pageSize', pageSize],
      ['searchType', filters?.search?.type || ''],
      ['search', filters?.search?.value || ''],
      ['status', filters?.tab || ''],
      ['with[]', 'programs'],
      ['with[]', 'location'],
      ['with[]', 'programs.operationalStatuses'],
    ])

    try {
      const { data } = await axios.get(`/site/query?${query}`)

      let siteData = map(data.sites.data, site => {
        const statuses = chain(site.programs).map('operational_statuses').flatten().uniqBy('key').value()

        return {
          id: site.id,
          rtuId: site.rtu_id,
          name: site.name,
          status: (
            map(statuses, (pill, index) => {
              return (
                <Pill color={pill.color} hasDot={true} key={index}>
                  {pill.title}
                </Pill>
              )
            })
          ),
          description: site.description,
          location: site.location.name,
          communicationType: upperFirst(toLower(site.link_type)),
          lineLinkId: site.link_type === 'LINE' ? site.line_link_id : 'N/A',
          radioLinkId: site.link_type === 'RADIO' ? site.radio_link_id : 'N/A',
          radioZone: site.link_type === 'RADIO' ? site.radio_zone : 'N/A',
          programCount: (
            <Pill color="#1570ef">
              {site.programs.length}
            </Pill>
          ),
          action: (
            <DropdownList
              icon={<ActionIcon />}
              positionProgrammatically
              wrapperStyle={{
                position: 'static'
              }}
              style={{
                minWidth: '200px',
                transform: 'translate(calc(-100% + 25px), 25px)',
                textAlign: 'left'
              }}
              options={[
                {
                  label: 'View site',
                  onClick: () => {
                    navigate(`/dashboard/view/${site.id}`)
                  }
                },
                {
                  label: 'Manage site',
                  onClick: () => {
                    navigate(`/site/manage/${site.id}`)
                  }
                },
                {
                  label: 'Edit site',
                  onClick: () => {
                    setModal({
                      name: 'site',
                      data: { site: mapKeys(site, (value, key) => camelCase(key)) }
                    })
                  }
                },
                {
                  label: 'Add program',
                  onClick: () => {
                    setModal({
                      name: 'program',
                      data: {
                        siteId: site.id
                      }
                    })
                  }
                }
              ]}
            />
          )
        }
      })

      let tableData = {
        ...data.sites,
        data: siteData
      }

      setTableData(tableData)
      setTableDataLoading(false)
    } catch (error) {
      Bugsnag.notify(error)
    }
  }, 1000), [setTableData, setTableDataLoading, navigate, setModal])

  useEffect(() => {
    getTableData({
      pageSize: 15,
      pageIndex: 0
    })
  }, [getTableData])

  const getTableDataStart = useCallback(params => {
    setTableDataLoading(true)
    getTableData(params)
  }, [getTableData, setTableDataLoading])

  const tableColumns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        width: '25%',
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: '15%',
      },
      {
        Header: 'Description',
        accessor: 'description',
        width: '25%',
      },
      {
        Header: 'Location',
        accessor: 'location',
        width: '25%',
      },
      {
        Header: 'Site ID',
        accessor: 'rtuId',
        width: '25%',
      },
      {
        Header: 'Communication Type',
        accessor: 'communicationType',
        width: '25%',
      },
      {
        Header: 'Radio Link ID',
        accessor: 'radioLinkId',
        width: '25%',
      },
      {
        Header: 'Radio Zone',
        accessor: 'radioZone',
        width: '25%',
      },
      {
        Header: 'Line Link ID',
        accessor: 'lineLinkId',
        width: '25%',
      },
      {
        Header: 'Programs',
        accessor: 'programCount',
        width: '25%',
      },
      {
        Header: '',
        accessor: 'action',
        width: '5%',
      },
    ], []
  )

  const searchTypes = useMemo(() => {
    return [
      {
        value: 'site',
        label: 'Site',
      },
      {
        value: 'location',
        label: 'Location',
      }
    ]
  }, [])

  const filterTabs = useMemo(() => {
    return [
      {
        title: 'View all',
        key: null,
      },
      {
        title: 'Alarms',
        key: 'alarms',
      },
      {
        title: 'Stopped',
        key: 'stopped',
      },
      {
        title: 'Running',
        key: 'running',
      },
    ]
  }, [])

  const getHeaderPills = useMemo(() => {
    let pills = []

    if (tableData && siteStats) {
      pills.push({
        title: `${tableData.total} Total`,
        color: '#175CD3'
      })

      forEach(siteStats, siteStat => {
        pills.push({
          title: `${siteStat.count} ${siteStat.title}`,
          color: siteStat.color
        })
      })
    }

    return pills
  }, [tableData, siteStats])

  const getSiteStats = useCallback(async () => {
    try {
      let { data: siteStats } = await axios.get(`/site/stats`)

      return reverse(sortBy(siteStats, 'count'))
    } catch (error) {
      Bugsnag.notify(error)
    }
  }, [])

  useEffect(() => {
    (async () => {
      const siteStats = await getSiteStats()

      setSiteStats(siteStats)
    })()
  }, [setSiteStats, getSiteStats])

  return (
    <>
      {
        tableData &&
        <Table
          columns={tableColumns}
          data={tableData}
          filterSearch
          filterTabs={filterTabs}
          getTableData={getTableDataStart}
          header="Sites"
          loading={tableDataLoading}
          searchTypes={searchTypes}
          headerPills={getHeaderPills}
        />
      }

      <ButtonContainer>
        <Anchor
          style={{
            minWidth: '200px'
          }}
          onClick={() => {
            setModal({
              name: 'site'
            })
          }}
        >
          Add site
        </Anchor>
      </ButtonContainer>
    </>
  )
}

export default SitesAll
