import 'tippy.js/themes/light.css'
import Tippy from '@tippyjs/react'
import styled from 'styled-components'

import { rem } from 'polished'

const Bar = styled.div`
  background-color: ${props => props.trailColour ? props.trailColour : '#F0F9FF' };
  border-radius: ${props => props.borderRadius ? props.borderRadius : '8px' };
  height: ${props => props.strokeWidth ? `${props.strokeWidth}px` : '10px' };
  margin: ${props => props.margin ? props.margin : '0' };
  position: relative;
  width: 100%;
`

const Fill = styled.div`
  background-color: ${props => props.pathColour ? props.pathColour : 'var(--primary)' };
  border-radius: ${props => props.borderRadius ? props.borderRadius : '8px' };
  height: ${props => props.strokeWidth ? `${props.strokeWidth}px` : '10px' };
  overflow: hidden;
  position: relative;
  width: ${props => props.value && props.value };
`

const Label = styled.div`
  font-size: ${rem(13)};
`

function ProgressBar(props) {
  return (
    <div className="row">
      <div className={props.label ? 'col-9 d-flex align-items-center' : 'col-12 d-flex align-items-center'}>
        <Tippy content={props.tooltipValue} theme="light">
          <Bar {...props}>
            <Fill {...props} />
          </Bar>
        </Tippy>
      </div>

      {props.label && (
        <Label className="col-3">
          {props.label}
        </Label>
      )}
    </div>
  )
}

export default ProgressBar