import Bugsnag from '@bugsnag/js'
import styled from 'styled-components'
import { atom, useRecoilState, useSetRecoilState } from 'recoil'
import { map, isEmpty } from 'lodash'
import { useEffect, useCallback } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'

import ActionIcon from '@components/ActionIcon'
import CircleList from '@components/CircleList'
import DropdownList from '@components/DropdownList'
import Map from '@components/Map'
import axios from '@utilities/axios'
import { Anchor } from '@components/form/Buttons'
import { rem } from 'polished'

const PageContainer = styled.div`
  padding: 0 26px;
  width: 100%;
`

const PageTitle = styled.div`
  color: var(--heading-color);
  font-size: ${rem(30)};
  margin-bottom: 20px;
`

const DetailContainer = styled.div`
  .button-container {
    display: flex;
    justify-content: flex-end;
    margin: 20px 0;
  }
`

const Card = styled.div`
  border: 1px solid #E4E7EC;
  border-radius: 8px;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
  padding-bottom: 30px;
  width: 100%;
`

const CardTitle = styled.div`
  align-items: center;
  border-bottom: 1px solid #E4E7EC;
  color: var(--heading-color);
  display: flex;
  justify-content: space-between;
  font-size: ${rem(18)};
  padding: 20px 24px;

  .more-actions {
    align-items: center;
    display: flex;
    position: relative;

    svg {
      &:hover {
        cursor: pointer;
      }
    }
  }
`

const CardRow = styled.div`
  display: flex;
  font-size: ${rem(14)};
  justify-content: space-between;
  padding: 13px 24px;
`

const CardRowTitle = styled.div`
  align-items: center;
  color: var(--heading-color);
  display: flex;
  font-size: ${rem(14)};
  flex-basis: 50%;
`

const CardRowContent = styled.div`
  color: var(--text-light);
  display: flex;
  font-size: ${rem(14)};
  flex-basis: 50%;
`

const CircleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-basis: 50%;
`

const LocationName = styled.div`
  text-transform: capitalize;
`

const MapContainer = styled.div`
  display: flex;
  justify-content: center;
  height: calc(100vh - 169px);
`

const locationState = atom({
  key: 'locationData',
  default: {}
})

const locationCoordinatesState = atom({
  key: 'locationCoordinates',
  default: {}
})

const alertState = atom({
  key: 'alert',
  default: {}
})

const modalState = atom({
  key: 'modal',
  default: null,
})

function LocationManage() {
  const [location, setLocation] = useRecoilState(locationState)
  const [locationCoordinates, setLocationCoordinates] = useRecoilState(locationCoordinatesState)
  const setModal = useSetRecoilState(modalState)
  const navigate = useNavigate()
  const routerLocation = useLocation()
  const setAlert = useSetRecoilState(alertState)
  const urlParams = useParams()

  const getLocation = useCallback(async () => {
    try {
      let { data } = await axios.get(`/location/view/${urlParams.id}`)

      if (data && data.success) {
        setLocation(data.location)
      }
    } catch (error) {
      navigate('/dashboard')
      Bugsnag.notify(error)
    }
  }, [setLocation, urlParams, navigate])

  const getCoordinates = useCallback(async () => {
    setLocationCoordinates({})

    let { data } = await axios.get(`/google/maps/search/text?query=${location.address}`)

    if (data.success) {
      setLocationCoordinates(data.result.geometry.location)
    }
  }, [location, setLocationCoordinates])

  useEffect(() => {
    getLocation()
  }, [getLocation])

  useEffect(() => {
    getCoordinates()
  }, [location, getCoordinates])

  useEffect(() => {
    if (routerLocation.state && routerLocation.state.showAlert) {
      setAlert({ type: 'success', content: routerLocation.state.message })
    }
  }, [setAlert, routerLocation])

  return (
    <PageContainer>
      <div className="row">
        <PageTitle>
          Manage location
        </PageTitle>
      </div>

      <div className='row'>
        <DetailContainer className="col-12 col-md-4">
          <Card>
            <CardTitle>
              <LocationName>
                {location.name}
              </LocationName>

              <div className='more-actions'>
                <DropdownList
                  icon={<ActionIcon />}
                  options={[
                    {
                      label: 'Edit Location',
                      onClick: () => {
                        setModal({
                          name: 'location',
                          data: location
                        })
                      }
                    },
                    {
                      label: 'Delete Location',
                      onClick: () => {
                        setModal({
                          name: 'warning',
                          data: {
                            endpoint: `/location/delete/${location.id}`,
                            title: 'Delete location',
                            content: `Are you sure you want to delete ${location.name}? This action cannot be undone.`,
                            successFlashMessage: `${location.name} has been successfully removed.`,
                            redirect: 'overview'
                          }
                        })
                      }
                    }
                  ]}
                />
              </div>
            </CardTitle>

            <CardRow>
              <CardRowTitle>Description</CardRowTitle>
              <CardRowContent>{location.description}</CardRowContent>
            </CardRow>

            <CardRow>
              <CardRowTitle>Address</CardRowTitle>
              <CardRowContent>{location.address}</CardRowContent>
            </CardRow>

            <CardRow>
              <CardRowTitle>Authorized users</CardRowTitle>

              <CircleContainer>
                <CircleList
                  tooltips
                  circles={map(location.users, user => {
                    return {
                      text: user.name
                    }
                  })}
                />
              </CircleContainer>
            </CardRow>
          </Card>

          <div className='button-container'>
            <Anchor
              style={{
                width: 'calc(50% - 5px)'
              }}
              onClick={() => {
                setModal({
                  name: 'site',
                  data: { locationId: location.id }
                })
              }}
            >
              Add Site
            </Anchor>
          </div>
        </DetailContainer>

        <MapContainer className="col-12 col-md-8">
          {!isEmpty(locationCoordinates) && <Map center={locationCoordinates} />}
        </MapContainer>
      </div>
    </PageContainer>
  )
}

export default LocationManage
