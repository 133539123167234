import styled from 'styled-components'
import { Outlet } from 'react-router-dom'
import { useEffect } from 'react'

import axios from '@utilities/axios'

const Layout = styled.div`
  padding: 42px 26px;
  max-height: 100vh;
  min-height: 100vh;
`

const BrandBlockWrapper = styled.div`
  padding-left: 38px;
  max-height: calc(100vh - 84px);
  min-height: calc(100vh - 84px);
`

const BrandBlock = styled.div`
  align-items: center;
  background-color: #293056;
  display: flex;
  height: 100%;
  width: 100%;
`

const Name = styled.div`
  color: #ffffff;
  font-weight: 800;
  font-size: 45px;
  text-align: center;
  width: 100%;
`

const Logo = styled.img`
  align-self: center;
  display: flex;
  margin-left: 0;
  margin-bottom: 50px;
  position: relative;
  width: 250px;

  @media only screen and (min-width: 992px) {
    margin-left: 36px;
    align-self: start;
    margin-bottom: 0;
  }
`

const FormContainer = styled.div`
  margin: auto 0;
  width: 360px;
  max-width: 100%;
`

function AuthPage() {
  useEffect(() => {
    axios.get('/sanctum/csrf-cookie')
  }, [])

  return (
    <Layout className='container-fluid'>
      <div className="row">
        <div className="d-flex flex-column align-items-center col-12 col-md-6">
            <Logo src="/images/futureops-logo.svg" alt="FutureOps Logo" />

            <FormContainer>
              <Outlet className="main-container" />
            </FormContainer>
        </div>

        <BrandBlockWrapper className="d-flex justify-content-center d-none d-md-flex col-md-6">
          <BrandBlock>
            <Name>FutureOps</Name>
          </BrandBlock>
        </BrandBlockWrapper>
      </div>
    </Layout>
  )
}

export default AuthPage;
