import Bugsnag from '@bugsnag/js'
import styled from 'styled-components'
import { React, useEffect, useMemo, useCallback } from 'react'
import { atom, useRecoilState, useSetRecoilState } from 'recoil'
import { chain, debounce, map } from 'lodash'
import { useNavigate, useParams } from 'react-router-dom'

import ActionIcon from '@components/ActionIcon'
import Card from '@components/Card'
import CircleList from '@components/CircleList'
import DropdownList from '@components/DropdownList'
import PageContainer from '@components/layout/helpers/PageContainer'
import PageTitle from '@components/layout/helpers/PageTitle'
import Pill from '@components/pill/Pill'
import Table from '@components/Table'
import axios from '@utilities/axios'
import { Anchor } from '@components/form/Buttons'
import { rem } from 'polished'

const tableDataState = atom({
  key: 'locationIndexData',
  default: null,
})

const tableDataLoadingState = atom({
  key: 'locationIndexTableLoading',
  default: true,
})

const singleLocationState = atom({
  key: 'singleLocation',
  default: {},
})

const modalState = atom({
  key: 'modal',
  default: null,
})

const ButtonContainer = styled.div`
  text-align: right;
  margin-top: 15px;
`

const CardContainer = styled.div`
  margin-bottom: 20px;
`

const CardRow = styled.div`
  display: flex;
  font-size: ${rem(14)};
  justify-content: space-between;
  padding: 13px 24px;
`

const CardRowTitle = styled.div`
  align-items: center;
  color: var(--heading-color);
  display: flex;
  font-size: ${rem(14)};
  flex-basis: 50%;
`

const CardRowContent = styled.div`
  color: var(--text-light);
  display: flex;
  font-size: ${rem(14)};
  flex-basis: 50%;
`

function LocationView() {
  const [location, setLocation] = useRecoilState(singleLocationState)
  const [tableData, setTableData] = useRecoilState(tableDataState)
  const [tableDataLoading, setTableDataLoading] = useRecoilState(tableDataLoadingState)
  const setModal = useSetRecoilState(modalState)
  const navigate = useNavigate()
  const urlParams = useParams()

  const getLocation = useCallback(async () => {
    try {
      const { data } = await axios.get(`/location/view/${urlParams.id}`)

      if (data && data.success) {
        setLocation(data.location)
      }
    } catch (error) {
      Bugsnag.notify(error)
    }
  }, [setLocation, urlParams])

  useEffect(
    () => {
      getLocation()
    },
    [getLocation]
  )

  const getTableData = useMemo(() => debounce(async ({ pageIndex, pageSize, filters }) => {
    const query = new URLSearchParams([
      ['page', pageIndex + 1],
      ['pageSize', pageSize],
      ['search', filters?.search?.value || ''],
      ['location_id', urlParams.id],
      ['with[]', 'programs'],
      ['with[]', 'programs.operationalStatuses'],
    ])

    const { data } = await axios.get(`/site/query?${query}`)

    let siteData = map(data.sites.data, site => {
      let communicationType

      if (site.lineLinkId && site.radioZone && site.radioLinkId) {
        communicationType = 'Radio and Line'
      } else if (site.radioZone && site.radioLinkId) {
        communicationType = 'Radio'
      } else {
        communicationType = 'Line'
      }
      const statuses = chain(site.programs).map('operational_statuses').flatten().uniqBy('key').value()

      return {
        id: site.id,
        name: site.name,
        status: map(statuses, (status) => {
          return (
            <Pill color={status.color} hasDot={true} key={status.title}>{status.title}</Pill>
          )
        }),
        description: site.description,
        communicationType: communicationType,
        radioLinkId: site.radio_link_id || 'N/A',
        radioZone: site.radio_zone || 'N/A',
        lineLinkId: site.line_link_id || 'N/A',
        programCount: site.programs.length || null,
        action: (
          <DropdownList
            icon={<ActionIcon />}
            positionProgrammatically
            wrapperStyle={{
              position: 'static'
            }}
            style={{
              minWidth: '200px',
              transform: 'translate(calc(-100% + 25px), 25px)',
              textAlign: 'left'
            }}
            options={[
              {
                label: 'View site',
                onClick: () => {
                  navigate(`/dashboard/view/${site.id}`)
                }
              },
              {
                label: 'Manage site',
                onClick: () => {
                  navigate(`/site/manage/${site.id}`)
                }
              },
              {
                label: 'Edit site',
                onClick: () => {
                  setModal({
                    name: 'site',
                    data: { site: site }
                  })
                }
              },
              {
                label: 'Delete site',
                onClick: () => {
                  setModal({
                    name: 'warning',
                    data: {
                      endpoint: `/site/delete/${site.id}`,
                      title: 'Delete site',
                      content: site.programs.length ? `${site.name} has ${site.programs.length} associated program${site.programs.length > 1 ? 's' : ''}. Please delete the associated program${site.programs.length > 1 ? 's' : ''} before deleting the site.` : `Are you sure you want to delete ${site.name}? This action cannot be undone.`,
                      successFlashMessage: `${site.name} has been successfully removed.`,
                      redirect: 'overview',
                      disableConfirmButton: site.programs.length ? true : false
                    }
                  })
                }
              }
            ]}
          />
        )
      }
    })

    let tableData = {
      ...data.sites,
      data: siteData
    }

    setTableData(tableData)
    setTableDataLoading(false)
  }, 1000), [setTableData, setTableDataLoading, navigate, urlParams, setModal])

  useEffect(() => {
    getTableData({
      pageSize: 15,
      pageIndex: 0
    })
  }, [getTableData])

  const getTableDataStart = useCallback(params => {
    setTableDataLoading(true)
    getTableData(params)
  }, [getTableData, setTableDataLoading])

  const tableColumns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        width: '10%',
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: '10%',
      },
      {
        Header: 'Description',
        accessor: 'description',
        width: '25%',
      },
      {
        Header: 'Site ID',
        accessor: 'id',
        width: '10%',
      },
      {
        Header: 'Communication Type',
        accessor: 'communicationType',
        width: '10%',
      },
      {
        Header: 'Radio Link ID',
        accessor: 'radioLinkId',
        width: '10%',
      },
      {
        Header: 'Radio Zone',
        accessor: 'radioZone',
        width: '10%',
      },
      {
        Header: 'Line Link ID',
        accessor: 'lineLinkId',
        width: '10%',
      },
      // {
      //   Header: 'SiteCoordinates',
      //   accessor: 'lineLinkId',
      //   width: '25%',
      // },
      {
        Header: 'Programs',
        accessor: 'programCount',
        width: '10%',
      },
      {
        Header: '',
        accessor: 'action',
        width: '5%',
      },
    ], []
  )

  return (
    <>
      <PageContainer contained>
        <PageTitle>
          Location: { location.name }
        </PageTitle>


        <CardContainer className="col-12 col-md-4">
          <Card title="General details">
            <CardRow>
              <CardRowTitle>Description</CardRowTitle>
              <CardRowContent>{location.description}</CardRowContent>
            </CardRow>

            <CardRow>
              <CardRowTitle>Address</CardRowTitle>
              <CardRowContent>{location.address}</CardRowContent>
            </CardRow>

            <CardRow>
              <CardRowTitle>Authorized members</CardRowTitle>
              <CardRowContent>
                <CircleList
                  circles={map(location.users, user => {
                    return {
                      text: user.name
                    }
                  })}
                />
              </CardRowContent>
            </CardRow>
          </Card>
        </CardContainer>

        {
          tableData &&
          <Table
            header="Sites"
            columns={tableColumns}
            data={tableData}
            loading={tableDataLoading}
            getTableData={getTableDataStart}
            topSearch
            headerPills={[
              {
                title: `${tableData.total} total`,
                color: '#175CD3'
              }
            ]}
          />
        }

        <ButtonContainer>
          <Anchor
            style={{
              minWidth: '200px'
            }}
            onClick={() => {
              setModal({
                name: 'site',
                data: { locationId: location.id }
              })
            }}
          >
            Add site
          </Anchor>
        </ButtonContainer>
      </PageContainer>
    </>
  )
}

export default LocationView
