import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { darken } from 'polished'

const ButtonStyles = (props) => {
  return `
    background-color: var(--primary);
    border-radius: 8px;
    border: none;
    color: #ffffff;
    cursor: ${props.disabled ? 'not-allowed' : 'pointer'};
    display: inline-block;
    font-size: 16px;
    line-height: 44px;
    padding: 0 20px;
    text-align: center;
    text-decoration: none;
    transition: all 0.2s ease;
    width: auto;

    &:disabled {
      cursor: not-allowed;
    }

    &:hover {
      background-color: ${darken(0.2, '#1570ef')};
    }

    &.transparent {
      background-color: transparent;
      border: 1px solid #d0d5dd;
      color: #344054;

      &:hover {
        background-color: #d0d5dd;
      }
    }

    &.warning {
      background-color: var(--error-text);
      border: 1px solid #d0d5dd;

      &:hover {
        background-color: ${darken(0.2, '#d92d20')};
      }
    }

    &.success {
      background-color: #12B76A;

      &:hover {
        background-color: ${darken(0.2, '#12B76A')};
      }
    }
  `
}

const StyledRouterButton = styled(Link)`
  ${props => ButtonStyles(props)}
`

const StyledButton = styled.button`
  ${props => ButtonStyles(props)}
`

const StyledAnchor = styled.a`
  ${props => ButtonStyles(props)}
`

function RouterButton(props) {
  return (
    <StyledRouterButton to={props.href} {...props}>
      {props.children}
    </StyledRouterButton>
  )
}

function Button(props) {
  return (
    <StyledButton {...props}>
      {props.children}
    </StyledButton>
  )
}

function Anchor(props) {
  return (
    <StyledAnchor
      {...props}
      className={props.className}
    >
      {props.children}
    </StyledAnchor>
  )
}

export {
  Anchor,
  Button,
  RouterButton
}
