import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import CircleList from '@components/CircleList'
import DropdownList from '@components/DropdownList'
import { useAuth } from '@utilities/auth'

const Actions = styled.div`
  align-items: center;
  display: flex;
  margin-right: 56px;

  @media only screen and (min-width: 992px) {
    margin-right: 0;
  }

  * {
    cursor: pointer;
  }

  svg {
    color: var(--text-dark);
    margin: 6px;

    @media only screen and (min-width: 992px) {
      margin: 0 13px;
    }
  }
`

const UserIcon = name => {
  return <CircleList
    compact
    circles={[
      {
        text: name || null
      }
    ]}
  />
}

function HeaderActions () {
  const navigate = useNavigate()
  const auth = useAuth()

  return (
    <>
      <Actions>
        <DropdownList
          clickable
          icon={UserIcon(auth?.user?.name)}
          style={{
            zIndex: 10010
          }}
          options={[
            {
              label: 'Logout',
              onClick: () => {
                navigate('/user/logout')
              }
            }
          ]}
        />
      </Actions>
    </>
  )
}

export default HeaderActions