import classNames from 'classnames'
import { cloneDeep, get, isFunction, isNull, map, set, toNumber } from 'lodash'
import moment from 'moment'
import { rem } from 'polished'
import { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import { mediaBreakpointDown } from '@utilities/breakpoint'

const Label = styled.label`
  color: var(--text-dark);
  display: flex;
  font-size: 14px;
  margin-bottom: 6px;
`

const DaySelect = styled.div`
  border-radius: 5px;
  border: ${props => props.noBorder ? 'none': '1px solid #D0D5DD' };
  padding: 24px;

  ${mediaBreakpointDown('md', `
    border: none;
    padding: 0;
  `)}
`

const Row = styled.div`
  display: flex;
`

const RowIndex = styled.div`
  align-items: center;
  background: ${props => props.active ? '#EFF8FF' : 'transparent'};
  border-radius: 50%;
  color: ${props => props.active ? 'var(--primary)' : '#344054'};
  display: flex;
  height: 40px;
  justify-content: center;
  margin-bottom: 2px;
  margin-right: 5px;
  width: 40px;

  ${mediaBreakpointDown('md', `
    width: 15px;
  `)}
`

const Day = styled.div`
  align-items: center;
  border-radius: 100%;
  color: var(--text-light);
  display: flex;
  font-size: ${rem(14)};
  height: 40px;
  justify-content: center;
  width: 40px;

  &:hover {
    background-color: ${props => props.readOnly ? 'inherit' : '#f2f4f7'};
    cursor: ${props => props.readOnly ? 'default' : 'pointer'};
  }

  &.selected {
    background-color: var(--primary);
    color: #ffffff;
  }
`

function DayPicker(props) {
  const { externalRef } = props

  const [schedule, setSchedule] = useState(null)
  const [weekCount] = useState(toNumber(props.indexCount) || 4)
  const [firstLoad, setFirstLoad] = useState(true)

  const currentWeek = useCallback(() => {
    const isoWeek = moment().isoWeek()

    return (isoWeek % weekCount) === 0 ? weekCount : (isoWeek % weekCount)
  }, [weekCount])

  const scheduleDays = useMemo(() => {
    return {
      monday: 'Mo',
      tuesday: 'Tu',
      wednesday: 'We',
      thursday: 'Th',
      friday: 'Fr',
      saturday: 'Sa',
      sunday: 'Su',
    }
  }, [])

  const scheduleSchema = useMemo(() => {
    return {
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
    }
  }, [])

  const isJsonString = useCallback((schedule) => {
    try {
      JSON.parse(schedule)
    } catch (e) {
      return false
    }

    return true
  }, [])

  useEffect(() => {
    if (isNull(externalRef)) {
      setDefaultSchedule()
    }
  }, [externalRef])

  useEffect(() => {
    if (firstLoad) {
      setDefaultSchedule()
      setFirstLoad(false)
    }
  }, [props.initialSchedule])

  const setDefaultSchedule = useCallback(() => {
    let defaultSchedule = {}

    if (!props.initialSchedule) {
      for (let index = 1; index <= props.indexCount; index++) {
        defaultSchedule[index] = cloneDeep(scheduleSchema)
      }
    }

    if (props.initialSchedule) {
      isJsonString(props.initialSchedule) ? setSchedule(JSON.parse(props.initialSchedule)) : setSchedule(props.initialSchedule)
    } else {
      setSchedule(defaultSchedule)

      if (isFunction(props.onChange)) {
        props.onChange(defaultSchedule)
      }
    }
  }, [props.initialSchedule])

  const toggleDay = useCallback((week, day) => {
    if (isFunction(props.onChange)) {
      let newSchedule = cloneDeep(schedule)

      const currentValue = get(newSchedule, [week, day])
      set(newSchedule, [week, day], !currentValue)

      props.onChange(newSchedule, true)

      setSchedule(newSchedule)
    }
  }, [schedule])

  return (
    <>
      {props.label && (
        <Label htmlFor={props.name}>{props.label}</Label>
      )}

      <DaySelect noBorder={props.noBorder}>
        {map([...Array(weekCount)], (value, index) => {
          return <Row key={`week-${index + 1}`}>
            <RowIndex
              active={currentWeek() === index + 1}
            >
              {index + 1}
            </RowIndex>

            {map(scheduleDays, (value, key) => {
              return <Day
                className={classNames({ selected: schedule && schedule[index + 1] && schedule[index + 1][key] })}
                onClick={() => {toggleDay(index + 1, key)}}
                key={`week-${index + 1}-${key}`}
                readOnly={isFunction(props.onChange) ? false : true}
              >
                {value}
              </Day>
            })}
          </Row>
        })}
      </DaySelect>
    </>
  )
}

export default DayPicker
