import ActionIcon from '@components/ActionIcon'
import Bugsnag from '@bugsnag/js'
import Card from '@components/Card'
import DayPicker from '@components/form/DayPicker'
import Dropdown from '@components/Dropdown'
import DropdownList from '@components/DropdownList'
import Map from '@components/Map'
import Pill from '@components/pill/Pill'
import PillWrapper from '@components/pill/PillWrapper'
import axios from '@utilities/axios'
import moment from 'moment'
import styled from 'styled-components'
import { Anchor } from '@components/form/Buttons'
import { atom, useRecoilState, useSetRecoilState } from 'recoil'
import { chain, map, isEmpty, isArray, isObject, upperFirst, find, isBoolean, join, head } from 'lodash'
import { darken, rem } from 'polished'
import { statusColor } from '@utilities/statusColor'
import { useEffect, useMemo } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'

const PageContainer = styled.div`
  padding: 0 26px;
  width: 100%;
`

const PageTitle = styled.div`
  color: var(--heading-color);
  font-size: ${rem(30)};
  margin-bottom: 20px;
`

const DetailContainer = styled.div`
  .button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    gap: 10px;
    margin-bottom: 20px;

    .sync-button {
      --primary: red;
    }
  }
`

const CardRow = styled.div`
  display: flex;
  font-size: ${rem(14)};
  justify-content: space-between;
  padding: 13px 24px;
`

const CardRowTitle = styled.div`
  align-items: center;
  color: var(--heading-color);
  display: flex;
  flex-basis: 50%;
  font-size: ${rem(14)};
  padding-right: 15px;
`

const CardRowContent = styled.div`
  color: var(--text-light);
  display: flex;
  font-size: ${rem(14)};
  flex-basis: 50%;
`

const DayPickerTitle = styled.div`
  border-bottom: 1px solid #e4e7ec;
  color: var(--text-dark);
  display: flex;
  font-size: ${rem(16)};
  padding: 20px 24px;
`

const Link = styled.button`
  border: none;
  background-color: transparent;
  color: var(--primary);
  position: relative;

  &:hover {
    color: ${darken(0.2, '#1570ef')};
    cursor: pointer;
  }
`

const MapContainer = styled.div`
  display: flex;
  height: calc(100vh - 169px);
  justify-content: center;
  position: sticky;
  top: 24px;
`

const programState = atom({
  key: 'programData',
  default: {}
})

const programSiteState = atom({
  key: 'programSite',
  default: {}
})

const siteLocationState = atom({
  key: 'siteLocation',
  default: {}
})

const alertState = atom({
  key: 'alert',
  default: {}
})

const modalState = atom({
  key: 'modal',
  default: null,
})

function ProgramManage() {
  const [program, setProgram] = useRecoilState(programState)
  const [programSite, setProgramSite] = useRecoilState(programSiteState)
  const [siteLocation, setSiteLocation] = useRecoilState(siteLocationState)
  const navigate = useNavigate()
  const routerLocation = useLocation()
  const setAlert = useSetRecoilState(alertState)
  const setModal = useSetRecoilState(modalState)

  const urlParams = useParams()

  const markers = useMemo(() => {
    if (!isEmpty(programSite)) {
      return [
        {
          id: `siteId#${programSite.id}`,
          position: {
            lat: parseFloat(programSite.lat),
            lng: parseFloat(programSite.long)
          },
          title: programSite.name,
          fillColor: statusColor(chain(program).map('operational_statuses').flatten().value()).pathColor,
          draggable: false,
          clickHandler: (event, id) => {

          }
        }
      ]
    }
  }, [programSite, program])

  useEffect(() => {
    getProgram()

    if (routerLocation.state && routerLocation.state.showAlert) {
      setAlert({ type: 'success', content: routerLocation.state.message })
    }
  }, [setProgram, urlParams, routerLocation, navigate, setAlert])

  useEffect(() => {
    if (!isEmpty(program)) {
      const getSite = async () => {
        try {
          let { data } = await axios.get(`/site/view/${program.siteId}?with[]=location`)

          if (data && data.success) {
            setProgramSite(data.site)
          }
        } catch (error) {
          Bugsnag.notify(error)
        }
      }

      getSite()
    }
  }, [program, setProgramSite])

  useEffect(() => {
    if (!isEmpty(programSite) && (!programSite.lat || !programSite.long)) {
      const getCoordinates = async () => {
        try {
          let { data } = await axios.get(`/google/maps/search/text?query=${programSite.location.address}`)

          if (data.success) {
            setSiteLocation(data.result.geometry.location)
          }
        } catch (error) {
          Bugsnag.notify(error)
        }
      }

      getCoordinates()
    }
  }, [markers, programSite, setSiteLocation])

  const getProgram = async () => {
    try {
      let { data } = await axios.get(`/program/view/${urlParams.id}`)

      if (data && data.success) {
        const programData = data.program

        setProgram({
          id: programData.id,
          siteId: programData.site_id,
          name: programData.name,
          description: programData.description,
          rtuProgramNumber: programData.rtu_program_number,
          programFunctionId: programData.program_function_id,
          operationalDays: programData.operational_days,
          operationalStartTime: programData.operational_start_time,
          flowMeterMin: programData.flow_meter_min,
          flowMeterMax: programData.flow_meter_max,
          flowMeterResolution: programData.flow_meter_resolution,
          function: programData.program_function,
          functionData: programData.function_data,
          synchronised: programData.synchronised,
        })
      }
    } catch (error) {
      navigate('/404')
      Bugsnag.notify(error)
    }
  }

  const formatFieldValue = (data, layout) => {
    if (layout === 'timeField') {
      let duration = moment.duration(data, 'minutes')
      let output = []
      let days = duration.days()
      let hours = duration.hours()
      let minutes = duration.minutes()

      if (days) {
        output.push(`${days}d`)
      }

      if (hours) {
        output.push(`${hours}h`)
      }

      if (minutes) {
        output.push(`${minutes}m`)
      }

      return join(output, ' ')
    }

    if (isArray(data) || isObject(data)) {
      if (head(data)?.color) {
        return (
          <PillWrapper>
            {
              map(data, (pill, index) => {
                if (index < 4) {
                  return (
                    <Pill color={pill.color} key={index}>
                      {pill.label}
                    </Pill>
                  )
                } else if (index + 1 === data.length) {
                  return (
                    <Pill color={'grey'} key={index}>
                      {`+${data.length - 4}`}
                    </Pill>
                  )
                }
              })
            }
          </PillWrapper>
        )
      }

      return data.label
    } else if (isBoolean(data)) {
      return upperFirst(data.toString())
    }

    return data
  }

  const getFunctionByKey = (pump) => {
    const fertFunction = find(program.function.options, { 'key': pump.data.internal.function_key })

    return fertFunction
  }

  return (
    <PageContainer>
      <div className="row">
        <PageTitle>
          Manage Program
        </PageTitle>
      </div>

      <div className='row'>
        <DetailContainer className="col-12 col-md-5 col-lg-4">
          <Card
            title={program.name}
            actions={
              <>
                <DropdownList
                  icon={<ActionIcon />}
                  options={[
                    {
                      label: 'Edit program',
                      onClick: () => {
                        setModal({
                          name: 'program',
                          data: { program: program }
                        })
                      }
                    },
                    {
                      label: 'Delete program',
                      onClick: () => {
                        setModal({
                          name: 'warning',
                          data: {
                            title: "Delete program",
                            content: `Are you sure you want to delete ${program.name}? This will delete all hardware and alarms attached to it. This may also affect reporting. This action cannot be undone.`,
                            endpoint: `/program/delete/${urlParams.id}`,
                            successFlashMessage: `${program.name} deleted successfully.`,
                            redirect: `/overview`
                          }
                        })
                      }
                    },
                    {
                      label: 'Stop program',
                      onClick: () => {
                        setModal({
                          name: 'warning',
                          data: {
                            endpoint: `/program/state/stop/${program.id}`,
                            title: 'Stop program',
                            content: `Do you want to stop the program? The remaining water and fertilizer will be removed entirely.`,
                            successFlashMessage: `${program.name} queued to stop successfully.`,
                            onComplete: () => {
                              setModal(null)
                            },
                            close: () => {
                              setModal(null)
                            },
                            onFailure: () => {
                              setModal(null)
                              setAlert({ type: 'error', content: 'We were unable to process your request. Please try again.' })
                            }
                          }
                        })
                      }
                    },
                  ]}
                />
              </>
            }
          >
            <CardRow>
              <CardRowTitle>Description</CardRowTitle>
              <CardRowContent>{program.description}</CardRowContent>
            </CardRow>

            <CardRow>
              <CardRowTitle>Program Number</CardRowTitle>
              <CardRowContent>{program.rtuProgramNumber}</CardRowContent>
            </CardRow>

            {program.operationalDays && (
              <CardRow>
                <CardRowTitle>Schedule</CardRowTitle>
                <CardRowContent>
                    <Dropdown
                      icon={
                        <Link>
                          View Schedule
                        </Link>
                      }
                      positionProgrammatically="right"
                      wrapperStyle={{
                        position: 'static'
                      }}
                    >
                      <DayPickerTitle>Fertigation days per four week cycle</DayPickerTitle>
                      <DayPicker initialSchedule={program.operationalDays} noBorder={true} />
                    </Dropdown>
                </CardRowContent>
              </CardRow>
            )}

            {program.operationalStartTime && (
              <CardRow>
                <CardRowTitle>Start time</CardRowTitle>
                <CardRowContent>{program.operationalStartTime}</CardRowContent>
              </CardRow>
            )}
          </Card>

          {!isEmpty(program.functionData) && (
            map(program.functionData, pump => {
              return <Card
                key={pump.id}
                title={getFunctionByKey(pump).attributes.name}
                actions={
                  <DropdownList
                    icon={<ActionIcon />}
                    options={[
                      {
                        label: 'Edit Pump',
                        onClick: () => {
                          setModal({
                            name: 'hardwareSettings',
                            data: {
                              function: getFunctionByKey(pump),
                              functionData: pump,
                              programData: program
                            }
                          })
                        }
                      },
                      {
                        label: 'Delete Pump',
                        onClick: () => {
                          setModal({
                            name: 'warning',
                            data: {
                              endpoint: `/function-data/delete/${pump.id}`,
                              title: 'Delete pump',
                              content: `Are you sure you want to delete ${getFunctionByKey(pump).attributes.name}? This action cannot be undone.`,
                              successFlashMessage: `${getFunctionByKey(pump).attributes.name} deleted successfully.`,
                              redirect: `/program/manage/${program.id}`
                            }
                          })
                        }
                      },
                    ]}
                  />
                }
              >
                {
                  map(getFunctionByKey(pump).attributes.fields, (field, key) => {
                    return <CardRow key={key}>
                      <CardRowTitle>
                        {field.attributes.name}
                      </CardRowTitle>

                      <CardRowContent>
                        {formatFieldValue(pump.data.values[field.attributes.key], field.layout)}
                      </CardRowContent>
                    </CardRow>
                  })
                }
              </Card>
            })
          )}

          <div className="button-container">
            {
              program.synchronised === false && (
                <div className="sync-button">
                  <Anchor
                    className="success"
                    onClick={async () => {
                      await axios.post(`/program/sync/${program.id}`)
                      getProgram()
                      setAlert({ type: 'info', content: 'Synchronization started.' })
                    }}
                  >
                    Sync Now
                  </Anchor>
                </div>
              )
            }

            {!isEmpty(program.function) && (
              <DropdownList
                icon={
                  <Anchor
                    style={{
                      width: '100px'
                    }}
                  >
                    Add
                  </Anchor>
                }
                options={
                  map(program.function.options, option => {
                    return {
                      label: option.attributes.name,
                      onClick: () => {
                        setModal({
                          name: 'hardwareSettings',
                          data: {
                            function: option,
                            programData: program
                          }
                        })
                      }
                    }
                  })
                }
                style={{
                  right: 0,
                  bottom: !isEmpty(program.functionData) ? 'calc(100% + 10px)' : 'initial',
                  top: isEmpty(program.functionData) ? 'calc(100% + 10px)' : 'initial',
                }}
                list={true}
              />
            )}
          </div>
        </DetailContainer>

        <MapContainer className="col-12 col-md-7 col-lg-8">
          <Map center={siteLocation} markers={markers} />
        </MapContainer>
      </div>
    </PageContainer>
  )
}

export default ProgramManage
