import { useForm } from 'react-hook-form'

import InputField from '@components/form/Input'

function FormValidationPoc() {
  const { register, handleSubmit, formState: { errors } } = useForm()

  const onSubmit = data => {
    console.log(data)
  }

  console.log('errors', errors)

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <InputField
          label="Name"
          placeholder="Enter your name"
          type="text"
          className={errors.name && 'error'}
          {...register('name', {required: true, minLength: 10, maxLength: 80})}
        />
        {errors.name && <span className="error-message">Name is required.</span>}
      </form>
    </>
  )
}

export default FormValidationPoc
