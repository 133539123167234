import Bugsnag from '@bugsnag/js'
import { useEffect, useContext, createContext, useRef } from 'react'
import { atom, useRecoilState } from 'recoil'
import { useLocation, Navigate } from 'react-router-dom'

import axios from '@utilities/axios'

const userState = atom({
  key: 'userState',
  default: false
})

const AuthContext = createContext()

export function AuthProvider({ children }) {
  const [user, setUser] = useRecoilState(userState)
  const firstLoad = useRef(true)

  useEffect(() => {
    (async () => {
      let { data } = await axios.get('/user')

      if (data.success) {
        setUser(data.user)
      } else {
        setUser(null)
      }

      if (firstLoad.current) {
        firstLoad.current = false
      }
    })()
  }, [setUser])

  const signIn = async (credentials, callback) => {
    try {
      let { data } = await axios.post('/user/login', credentials)

      if (data.success) {
        setUser(data.user)

        callback(true, data.hasProgram)
      } else {
        callback(false)
      }
    } catch (error) {
      Bugsnag.notify(error)
      callback(false)
    }
  }

  const googleSignIn = async (accessToken, callback) => {
    try {
      let { data } = await axios.post('/user/login/google', {
        token: accessToken
      })

      if (data.success) {
        setUser(data.user)

        callback(true, data.hasProgram)
      } else {
        callback(false)
      }
    } catch (error) {
      Bugsnag.notify(error)
      callback(false)
    }
  }

  const signOut = async callback => {
    const { data } = await axios.post('/user/logout')

    if (data.success) {
      setUser(null)

      callback(true)
    }
  }

  return (
    <AuthContext.Provider
      value={{
        loggedIn: !!user,
        user,
        signIn,
        googleSignIn,
        signOut
      }}
    >
      {!firstLoad.current && children}
    </AuthContext.Provider>
  )
}

export function RequireAuth({ children }) {
  const auth = useAuth()
  const location = useLocation()

  if (!auth.loggedIn) {
    return <Navigate to="/user/login" state={{ from: location }} replace />
  }

  return children
}

export const useAuth = () => {
  return useContext(AuthContext)
}
