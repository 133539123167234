import { useEffect, useState } from 'react'
import styled from 'styled-components'
import axios from '@utilities/axios'
import { get, isEmpty, map } from 'lodash'

const CenterContent = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
`

const Content = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  margin: -20px 0;

  iframe {
    border: 0;
    border-radius: 8px;
    width: 100%;
    height: calc(100vh - 93px);
  }
`

function ReportingExtra() {
  const [mqttLogs, setMqttLogs] = useState([])

  useEffect(() => {
    (async () => {
      let { data } = await axios.get(`/mqtt/dose-info`)

      const mqttLogs = get(data, 'mqttLogs', [])

      setMqttLogs(mqttLogs)
    })()
  }, [])

  return (
    <CenterContent>
      <Content>
        <table
          style={{
            textAlign: 'center',
            margin: '50px auto',
          }}
         >
          <thead>
            <tr>
              <th style={{ width: '100px', paddingBottom: '20px' }}>ID</th>
              <th style={{ width: '250px', paddingBottom: '20px' }}>Timestamp</th>
              <th style={{ width: '100px', paddingBottom: '20px' }}>Status</th>
              <th style={{ width: '100px', paddingBottom: '20px' }}>Flow</th>
              <th style={{ width: '200px', paddingBottom: '20px' }}>Program Name</th>
              <th style={{ width: '100px', paddingBottom: '20px' }}>Type</th>
            </tr>
          </thead>

          <tbody>
            {isEmpty(mqttLogs) && (
              <tr>
                <td colSpan="6">Loading last 5000 records...</td>
              </tr>
            )}

            {map(mqttLogs, (log, index) => {
              return (
                <tr key={index}>
                  <td style={{ paddingBottom: '5px' }}>
                    {log.id}
                  </td>

                  <td style={{ paddingBottom: '5px' }}>
                    {log?.message?.FEPTime}
                  </td>

                  <td style={{ paddingBottom: '5px' }}>
                    {log?.message?.status}
                  </td>

                  <td style={{ paddingBottom: '5px' }}>
                    {log?.message?.flow}
                  </td>

                  <td style={{ paddingBottom: '5px' }}>
                    {log?.program?.name}
                  </td>

                  <td style={{ paddingBottom: '5px' }}>
                    Dose Info
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </Content>
    </CenterContent>
  )
}

export default ReportingExtra
