import "react-circular-progressbar/dist/styles.css"
import ActionIcon from '@components/ActionIcon'
import Card from '@components/Card'
import DayPicker from '@components/form/DayPicker'
import DropdownList from '@components/DropdownList'
import Pill from '@components/pill/Pill'
import classNames from 'classnames'
import moment from 'moment'
import styled from 'styled-components'
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar'
import { atom, useSetRecoilState } from 'recoil'
import { camelCase, floor, isFunction, map, mapKeys, maxBy, padStart, split, startsWith, trimStart } from 'lodash'
import { rem, lighten } from 'polished'
import { useEffect, useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const ProgressContainer = styled.div`
  width: 150px;
`

const SmallText = styled.div`
  color: var(--text-light);
  font-size: ${rem(12)};
`

const ProgressValue = styled.div`
  font-size: ${rem(24)};
  font-weight: 500;
`

const Time = styled.div`
  font-size: ${rem(14)};
  margin: 10px 0;
`

const TimeText = styled.div`
  color: var(--text-light);
  margin-right: 8px;
`

const PillContainer = styled.div`
  margin-top: 25px;
`

const Spacer = styled.div`
  margin-top: 25px;
`

const ClickablePill = styled.div`
  &.clickable {
    cursor: pointer;
  }
`

const DaysLater = styled.div`
  color: var(--text-light);
  font-size: ${rem(11)};
`

const modalState = atom({
  key: 'modal',
  default: null,
})

const pageAlertState = atom({
  key: 'alert',
  default: null
})

function DashboardOverview(props) {
  const [stopTime, setStopTime] = useState()
  const navigate = useNavigate()
  const setAlert = useSetRecoilState(pageAlertState)
  const setModal = useSetRecoilState(modalState)

  const materials = useMemo(() => {
    let materials = []

    map(props.program.function_data, functionData => {
      map(functionData.data.values.fertilizerMaterial, material => {
        materials.push(material)
      })
    })

    return materials
  }, [props])

  useEffect(() => {
    const functionData = maxBy(props.program.function_data, (functionData) => {
      const pumpTime = functionData.data.values.pumpTime || 0
      const delayPump = functionData.data.values.delayPump || 0

      return pumpTime + delayPump
    })

    const longestPumpTime = (functionData?.data?.values?.delayPump || 0) + (functionData?.data?.values?.pumpTime || 0)
    const stopTime = moment(props.program.operational_start_time, 'HH:mm').add(longestPumpTime, 'minutes')

    setStopTime(stopTime)
  }, [props.program.operational_start_time])

  const flowRate = useCallback(() => {
    if (props.program.stats?.main?.flow) {
      return props.program.stats?.main?.flow
    }

    return null
  }, [props.program])

  const flowRatePercent = useCallback(() => {
    let max = 1000

    return flowRate() / max * 100
  }, [flowRate])

  const progressColour = useCallback((shouldLighten) => {
    let colour

    if (flowRate()) {
      colour = '#12B76A';
    } else {
      colour = '#647087'
    }

    if (shouldLighten) {
      colour = lighten(0.5, colour)
    }

    return colour
  }, [flowRate])

  const timeRemainingPercent = useCallback(() => {
    if (!stopTime) {
      return false
    }

    let startTime = moment(props.program.operational_start_time, 'HH:mm')
    let currentTime = startTime

    if (props.time) {
      currentTime = moment(split(props.time, ' ')[1], 'HH:mm:ss')
    }

    let percentage = 100.0 * currentTime.diff(startTime) / stopTime.diff(startTime)

    return Math.min(Math.max(percentage, 0), 100)
  }, [props, stopTime])

  const timeRemaining = useCallback(() => {
    if (!stopTime) {
      return false
    }

    let startTime = moment(props.program.operational_start_time, 'HH:mm')

    if (props.time) {
      startTime = moment(split(props.time, ' ')[1], 'HH:mm:ss')
    }

    let diffMinutes = stopTime.diff(startTime, 'minutes')
    let isNegative = startsWith(diffMinutes, '-')
    diffMinutes = trimStart(diffMinutes, '-')

    let hours = padStart(floor(diffMinutes / 60), 2, 0)
    let minutes = padStart(diffMinutes % 60, 2, 0)

    return `${isNegative ? '-' : ''}${hours}:${minutes}`
  }, [props, stopTime])

  const clickablePills = useMemo(() => {
    return {
      alarms: () => {
        props.setDashboardSelectedTab('alarms')
      }
    }
  })

  const daysLater = useCallback(() => {
    if (stopTime) {
      const startTime = moment(props.program.operational_start_time, 'HH:mm')
      const days = stopTime.diff(startTime, 'days')

      if (days) {
        return days
      }
    }

    return null
  }, [stopTime])

  const pillClick = useCallback((key) => {
    if (isFunction(clickablePills[key])) {
      clickablePills[key]()
    }
  }, [clickablePills])

  return (
    <>
      <div className='row'>
        <div className="col-12 col-md-6 col-xl-4 d-flex">
          <Card
            title={(
              <>
                <span className="title">
                  {props.program?.name}
                </span>

                {props.program?.operational_statuses && (
                  map(props.program?.operational_statuses, (status) => {
                    return (
                      <ClickablePill
                        onClick={() => pillClick(status.key)}
                        className={classNames({
                          clickable: !!clickablePills[status.key]
                        })}
                        key={status.key}
                      >
                        <Pill color={status.color} hasDot={true} key={status.title}>{status.title}</Pill>
                      </ClickablePill>
                    )
                  })
                )}
              </>
            )}
            actions={
              <>
                <DropdownList
                  icon={<ActionIcon />}
                  options={[
                    {
                      label: 'Manage program',
                      onClick: () => {
                        navigate(`/program/manage/${props.program.id}`)
                      }
                    },
                    {
                      label: 'Edit program',
                      onClick: () => {
                        setModal({
                          name: 'program',
                          data: {
                            program: mapKeys(props.program, (value, key) => camelCase(key)),
                            onSave: () => {
                              props.updateDashboard()
                            }
                          }
                        })
                      }
                    },
                    {
                      label: 'Stop program',
                      onClick: () => {
                        setModal({
                          name: 'warning',
                          data: {
                            endpoint: `/program/state/stop/${props.program.id}`,
                            title: 'Stop program',
                            content: `Do you want to stop the program? The remaining water and fertilizer will be removed entirely.`,
                            successFlashMessage: `${props.program.name} queued to stop successfully.`,
                            onComplete: () => {
                              setModal(null)
                            },
                            close: () => {
                              setModal(null)
                            },
                            onFailure: () => {
                              setModal(null)
                              setAlert({ type: 'error', content: 'We were unable to process your request. Please try again.' })
                            }
                          }
                        })
                      }
                    },
                  ]}
                />
              </>
            }
          >
            {
              process.env.REACT_APP_TENANT !== 'twe' ?
              (
                <div className="row">
                  <Time className="col-6 d-flex justify-content-center"><TimeText>Start time: </TimeText>{props.program.operational_start_time.substring(0, 5)}</Time>
                  <Time className="col-6 d-flex justify-content-center">
                    <TimeText>Stop time: </TimeText>
                    {stopTime ? (
                      <div>
                        {stopTime.format('HH:mm')}
                        {daysLater() && (
                          <DaysLater>{daysLater()}d later</DaysLater>
                        )}
                      </div>
                    ) : '-'}
                  </Time>
                </div>
              ) : (
                <Spacer />
              )
            }

            <div className="row">
              <div className="col-12 d-flex justify-content-center">
                <ProgressContainer>
                  <CircularProgressbarWithChildren
                    value={process.env.REACT_APP_TENANT === 'twe' ? flowRatePercent() : timeRemainingPercent()}
                    strokeWidth='10'
                    styles={buildStyles({
                      textColor: 'red',
                      pathColor: process.env.REACT_APP_TENANT === 'twe' ? progressColour(false) : props.statusColor.pathColor,
                      trailColor: process.env.REACT_APP_TENANT === 'twe' ? progressColour(true) : props.statusColor.trailColor,
                    })}
                  >
                    {
                      process.env.REACT_APP_TENANT === 'twe' ? (
                        <>
                          <SmallText>Flow Rate</SmallText>
                          <ProgressValue>
                              {flowRate() || '-'}
                          </ProgressValue>
                          <SmallText>gpm</SmallText>
                        </>
                      ) : (
                        <>
                          <SmallText>Remaining</SmallText>
                          <ProgressValue>
                              {timeRemaining() || '-'}
                          </ProgressValue>
                        </>
                      )
                    }
                  </CircularProgressbarWithChildren>
                </ProgressContainer>
              </div>
            </div>

            {
              process.env.REACT_APP_TENANT !== 'twe' ? (
                <div className="row">
                  <PillContainer className="col-12 d-flex justify-content-center flex-wrap">
                    {
                      map(materials, (material, index, arr) => {
                        if (index < 4) {
                          return <Pill color={material.color} key={`${material.label}-${index}`}>{material.label}</Pill>
                        } else if (index + 1 === arr.length) {
                          return <Pill color={'grey'} key={index}>{`+${arr.length - 4}`}</Pill>
                        }
                      })
                    }
                  </PillContainer>
                </div>
              ) : (
                <Spacer />
              )
            }
          </Card>
        </div>

        {
          process.env.REACT_APP_TENANT !== 'twe' && (
            <div className="col-12 col-md-6 col-xl-4 d-flex">
              <Card
                title="Schedule"
              >
                <div className="row">
                  <div className="col-12 d-flex justify-content-center">
                    <DayPicker initialSchedule={props.program?.operational_days} noBorder={true} />
                  </div>
                </div>
              </Card>
            </div>
          )
        }
      </div>
    </>
  )
}

export default DashboardOverview