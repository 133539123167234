import Tippy from '@tippyjs/react'
import styled from 'styled-components'
import { hexFromText, normaliseContrast } from '@utilities/colors'
import { initials } from '@utilities/string'
import { map } from 'lodash'

const CircleWrapper = styled.div`
  display: flex;
  margin: ${props => props.compact ? '-6px 0' : '0'};
`

const Circle = styled.div`
  align-items: center;
  background-color: ${props => props.backgroundColor};
  border-radius: 100%;
  border: 2px solid #ffffff;
  color: #ffffff;
  cursor: ${props => props.clickable ? 'pointer' : 'default'};
  display: flex;
  font-weight: bold;
  height: 36px;
  justify-content: center;
  margin-left: -12px;
  overflow: hidden;
  user-select: none;
  width: 36px;

  &:first-of-type {
    margin-left: 0;
  }
`

const Item = (props, circle, index) => {
  return (
    <Circle
      backgroundColor={props.bland ? 'var(--text-light)' : normaliseContrast(hexFromText(circle.text), '#fff')}
      key={index}
    >
      {initials(circle.text)}
    </Circle>
  )
}

function CircleList (props) {
  return (
    <CircleWrapper compact={props.compact}>
      {map(props.circles, (circle, index) => {
        return (
          props.tooltips ? (
            <Tippy content={circle.text} key={index}>
              {Item(props, circle, index)}
            </Tippy>
          ) : (
            Item(props, circle, index)
          )
        )
      })}
    </CircleWrapper>
  )
}

export default CircleList