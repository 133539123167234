import * as yup from 'yup'
import Bugsnag from '@bugsnag/js'
import styled from 'styled-components'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { atom, useRecoilState } from 'recoil'
import { useForm } from 'react-hook-form'
import { useEffect } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'

import AuthAlert from '@components/AuthAlert'
import Input from '@components/form/Input'
import InputError from '@components/form/InputError'
// import PhoneNumberInput from '@components/form/PhoneNumberInput'
import axios from '@utilities/axios'
import { Button } from '@components/form/Buttons'

const Title = styled.h1`
  font-size: 30px;
  font-weight: 700;
`

const SubText = styled.div`
  color: #667085;
  font-size: 16px;
  font-weight: 300;
  margin-top: 12px;
`
const Form = styled.form`
  label {
    margin-top: 20px;
  }

  .buttons {
    margin-top: 40px;
  }
`

const SignUpText = styled.div`
  color: #667085;
  font-size: 14px;
  font-weight: 300;
  margin-top: 32px;

  a {
    color: var(--primary);
    text-decoration: none;
  }
`

const companyDetailsState = atom({
  key: 'companyDetails',
  default: {},
})

const authAlertState = atom({
  key: 'authAlert',
  default: {}
})

const schema = yup.object({
  name: yup.string().label('Name').required(),
  email: yup.string().label('Email').email().required(),
  number: yup.string().label('Mobile number').nullable().required(),
  password: yup.string().label('Password').required().min(6).max(100),
})

function Register() {
  const [companyDetails] = useRecoilState(companyDetailsState)
  const [alert, setAlert] = useRecoilState(authAlertState)
  const { setValue, register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  })

  const params = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    (async () => {
      let { data } = await axios.post('/invite/user', { hash: params.hash })

      setValue('name', data.user?.name)
      setValue('email', data.user?.email)
      setValue('number', data.user?.number)
    })()
  }, [setValue, params.hash])

  const handleRegister = async data => {
    try {
      setAlert(null)

      let { data: responseData } = await axios.post('/user/register', {
        hash: params.hash,
        company: companyDetails,
        ...data
      })

      if (responseData.success) {
        navigate('/dashboard')
      } else {
        setAlert({ message: responseData.message })
      }

    } catch (error) {
      Bugsnag.notify(error)
      setAlert({ message: 'An error occured.'})
    }
  }

  return (
    <Form onSubmit={handleSubmit(handleRegister)} autoComplete="off">
      <div className="d-flex flex-column justify-content-center">
        <Title>Sign up</Title>
        <SubText >Sign up in less than 2 minutes.</SubText>

        <AuthAlert class={alert ? 'd-flex' : 'd-none'} message={alert?.message} />

        <Input
          placeholder="Enter your name"
          label="Name*"
          type="text"
          className={errors.name && 'error'}
          {...register('name')}
        />
        {errors.name && <InputError message={errors.name.message} />}

        <Input
          placeholder="Enter your email"
          label="Email*"
          type="email"
          autoComplete="username"
          readOnly={true}
          className={errors.email && 'error'}
          {...register('email')}
        />
        {errors.email && <InputError message={errors.email.message} />}

        {/* <PhoneNumberInput
          placeholder="Enter your mobile number"
          label="Mobile number*"
          type="tel"
          className={errors.email && 'error'}
          {...register('number')}
        /> */}

        <Input
          placeholder="Enter your mobile number"
          label="Mobile number*"
          type="text"
          className={errors.number && 'error'}
          {...register('number')}
        />
        {errors.number && <InputError message={errors.number.message} />}

        <Input
          placeholder="Enter your password"
          label="Password*"
          type="password"
          autoComplete="new-password"
          className={errors.password && 'error'}
          {...register('password')}
        />
        {errors.password && <InputError message={errors.password.message} />}

        <div className="buttons">
          <Button
            style={{
              width: '100%'
            }}
          >
            Sign up
          </Button>
        </div>

        <div className="d-flex justify-content-center">
          <SignUpText >Already have an account? <Link to="/login">Sign In</Link></SignUpText>
        </div>
      </div>
    </Form>
  )
}

export default Register
