import axios from 'axios'
import Echo from 'laravel-echo'
import Pusher from 'pusher-js'

window.Pusher = Pusher

const echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.REACT_APP_PUSHER_APP_KEY,
    cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
    forceTLS: false,
    authEndpoint: process.env.REACT_APP_API_URL + '/api/broadcasting/auth',
    authorizer: (channel, options) => {
      return {
        authorize: (socketId, callback) => {
          axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/api/broadcasting/auth',
            withCredentials: true,
            data: {
              socket_id: socketId,
              channel_name: channel.name,
            },
          })
            .then((response) => {
              callback(false, response.data)
            })
            .catch((error) => {
              callback(true, error)
            })
        },
      }
    },
})

export default echo