import styled from 'styled-components'
import { useEffect } from 'react'
import { atom, useRecoilState } from 'recoil'

import { ReactComponent as CheckCircle } from '@icons/check-circle.svg'
import { ReactComponent as ExclamationIcon } from '@icons/exclamation.svg'


const Message = styled.div`
  background-color: ${props => props.type === 'success' ? '#f6fef9' : '#FFFBFA'};
  border: ${props => props.type === 'success' ? '1px solid #6ce9a6' : '1px solid #FDA29B'};
  border-radius: 8px;
  color: ${props => props.type === 'success' ? '#027a48' : 'var(--error-text)'};
  display: flex;
  font-size: 14px;
  font-weight: 400;
  justify-content: center;
  margin-top: 26px;
  padding: 7px 16px;
  text-align: left;

  svg {
    margin-right: 13px;
    stroke: ${props => props.type === 'success' ? '#027a48' : 'var(--error-text)'};
  }

  div {
    align-items: center;
    display: flex;
  }
`

const authAlertState = atom({
  key: 'authAlert',
  default: {}
})

function AuthAlert(props) {
  const [alertData, setAlertData] = useRecoilState(authAlertState)

  useEffect(() => {
    let alertTimeout = setTimeout(() => {
      setAlertData(null)
    }, 7000)

    return () => {
      clearTimeout(alertTimeout);
    }
  }, [alertData, setAlertData])

  return (
    <>
      {alertData?.message && (
        <Message className={props.class} type={props.type}>
          <div>{props.type === 'success' ? <CheckCircle /> : <ExclamationIcon />}</div>
          <div>{props.message}</div>
        </Message>
      )}
    </>
  )
}

export default AuthAlert
