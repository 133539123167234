import { filter } from 'lodash'

export const statusColor = (status) => {
  if (filter(status, ['key', 'alarms']).length) {
    return {
      pathColor: '#F04438',
      trailColor:'#FEF3F2'
    }
  } else if (filter(status, ['key', 'failure']).length) {
    return {
      pathColor: '#F04438',
      trailColor:'#FEF3F2'
    }
  } else if (filter(status, ['key', 'paused']).length) {
    return {
      pathColor: '#F79009',
      trailColor:'#FFFAEB'
    }
  } else if (filter(status, ['key', 'running']).length) {
    return {
      pathColor: '#12B76A',
      trailColor: '#ECFDF3'
    }
  } else {
    return {
      pathColor: '#647087',
      trailColor:'#f2f4f7'
    }
  }
}