import * as yup from 'yup'
import Bugsnag from '@bugsnag/js'
import styled from 'styled-components'
import { React, useEffect, useCallback, useMemo } from 'react'
import { atom, useRecoilState, useSetRecoilState } from 'recoil'
import { camelCase, filter, head, isEmpty, isFunction, isNull, map, mapKeys } from 'lodash'
import { useForm, Controller } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { useRef } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'

import GooglePlacesAutocomplete from '@components/form/GooglePlacesAutocomplete'
import '@utilities/yup'
import Input from '@components/form/Input'
import InputError from '@components/form/InputError'
import Modal from '@components/Modal'
import Select from '@components/form/Select'
import axios from '@utilities/axios'
import { Anchor, Button } from '@components/form/Buttons'

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 28px;
`

const SiteForm = styled.form`
  label {
    margin-top: 20px;
  }

  .buttons {
    margin-top: 40px;
  }
`

const locationState = atom({
  key: 'locations',
  default: {}
})

const pageAlertState = atom({
  key: 'alert',
  default: {}
})

const fepState = atom({
  key: 'feps',
  default: ''
})

const rtuState = atom({
  key: 'rtuIds',
  default: []
})

const isLoadingState = atom({
  key: 'isLoading',
  default: false
})

const schema = (props) => {
  let schemaObject = {
    name: yup.string().label('Name').required().min(3).max(50),
    description: yup.string().label('Description').required().min(3).max(250),
    address: yup.string().label('Address').required().min(3).max(250),
    rtuId: yup.mixed().label('Site ID').populatedObject(),
    location: yup.mixed().label('Location').populatedObject(),
    communicationType: yup.mixed().label('Communication type').populatedObject(),
    fepId: yup.mixed().label('FEP').populatedObject(),
    radioZone: yup.mixed().when('communicationType', {
      is: object => object?.value === 1,
      then: yup.mixed().label('Radio zone').populatedObject()
    }),
    radioLinkId: yup.mixed().when('communicationType', {
      is: object => object?.value === 1,
      then: yup.mixed().label('Radio link ID').populatedObject()
    }),
    lineLinkId: yup.mixed().when('communicationType', {
      is: object => object?.value === 0,
      then: yup.mixed().label('Line link ID').populatedObject()
    }),
  }

  if (props.data?.site?.id) {
    delete schemaObject.address
  }

  return yup.object(schemaObject)
}

function SiteModal(props) {
  const [fepOptions, setFepOptions] = useRecoilState(fepState)
  const [rtuOptions, setRtuOptions] = useRecoilState(rtuState)
  const [locations, setLocations] = useRecoilState(locationState)
  const [isLoading, setIsLoading] = useRecoilState(isLoadingState)
  const fepFirstLoad = useRef(true)
  const rtuFirstLoad = useRef(true)
  const navigate = useNavigate()
  const setPageAlert = useSetRecoilState(pageAlertState)
  const urlParams = useParams()
  const { reset, watch, setValue, control, register, handleSubmit, formState: { errors, isSubmitted } } = useForm({
    resolver: yupResolver(schema(props))
  })
  const communicationType = watch('communicationType')
  const fepId = watch('fepId')
  const address = watch('address')

  // Manually register the address field
  // onChange is manually handled
  useEffect(() => {
    register('address', {
      value: props.data?.address,
    })
  }, [register, props])

  const addressChange = useCallback((field, value) => {
    setValue(field, value, { shouldValidate: isSubmitted })
  }, [isSubmitted, setValue])

  const coordinatesChange = useCallback((field, coordinates) => {
    setValue('lat', coordinates.lat, { shouldValidate: false })
    setValue('lng', coordinates.lng, { shouldValidate: false })
  }, [isSubmitted, setValue])

  const communicationTypeOptions = useMemo(() => {
    return [
      { value: 0, label: 'Line' },
      { value: 1, label: 'Radio' },
      // TODO: Look at re-introducing this when needed
      // { value: 2, label: 'Radio and Line' },
    ]
  }, [])

  const radioZoneOptions = useMemo(() => {
    let radioOptions = []

    for (let index = 0; index <= 9; index++) {
      radioOptions.push({ value: index, label: `${index}` })
    }

    return radioOptions
  }, [])

  const radioLinkOptions = useMemo(() => {
    let radioOptions = []

    for (let index = 1; index <= 10; index++) {
      radioOptions.push({ value: index, label: `${index}` })
    }

    return radioOptions
  }, [])

  const lineOptions = useMemo(() => {
    let lineLinkOptions = []

    for (let index = 1; index <= 29; index++) {
      lineLinkOptions.push({ value: index, label: `${index}` })
    }

    return lineLinkOptions
  }, [])

  const updateLocations = useCallback(async () => {
    try {
      const { data } = await axios.get(`/locations`)

      if (data && data.success && data.locations.length) {
        setLocations(data.locations)

        if (urlParams.id) {
          let location = filter(data.locations, ['id', urlParams.id])
          setValue('location', { value: location.id, label: location.name })
        }
      }
    } catch (error) {
      Bugsnag.notify(error)
    }
  }, [setLocations, setValue, urlParams.id])

  useEffect(() => {
    updateLocations()
  }, [updateLocations])

  useEffect(() => {
    if (isEmpty(fepOptions) && fepFirstLoad.current) {
      (async () => {
        fepFirstLoad.current = false

        try {
          const  { data } = await axios.get(`/feps`)

          if (data?.success) {
            const options = map(data.feps, fep => {
              return { label: fep.name, value: fep.id }
            })
            setFepOptions(options)
          }
        } catch (error) {
          Bugsnag.notify(error)
        }
      })()
    }
  }, [fepOptions, setFepOptions])

  useEffect(() => {
    if (rtuFirstLoad.current) {
      (async () => {
        rtuFirstLoad.current = false

        try {
          const  { data } = await axios.get(`/site/available-rtu-ids`)

          if (data?.success) {
            const options = map(data.rtuIds, rtu => {
              return { label: rtu, value: rtu }
            })

            setRtuOptions(options)
          }
        } catch (error) {
          Bugsnag.notify(error)
        }
      })()
    }
  }, [rtuOptions, setRtuOptions])

  useEffect(() => {
    if (communicationType?.value === 0) {
      setValue('radioZone', '')
      setValue('radioLinkId', '')
    }

    if (communicationType?.value === 1) {
      setValue('lineLinkId', '')
    }
  }, [communicationType, setValue])

  const locationOptions = useMemo(() => {
    return map(locations, location => {
      return { value: location.id, label: location.name }
    })
  }, [locations])

  useEffect(() => {
    if (props.data?.locationId) {
      setValue('location', filter(locationOptions, ['value', props.data?.locationId]))
      setValue('locationId', props.data?.locationId)
    } else if (props.data?.site?.locationId) {
      setValue('location', filter(locationOptions, ['value', props.data?.site?.locationId]))
      setValue('locationId', props.data?.site?.locationId)
    }

    if (props.data?.site) {
      let site = mapKeys(props.data.site, (value, key) => camelCase(key))

      let communicationType = communicationTypeOptions[0]

      if (!isNull(site.radioZone) && site.radioLinkId) {
        communicationType = communicationTypeOptions[1]
      }

      setValue('communicationType', communicationType)
    }
  }, [
    props,
    communicationTypeOptions,
    fepOptions,
    lineOptions,
    locationOptions,
    radioLinkOptions,
    radioZoneOptions,
    rtuOptions,
    reset,
    setPageAlert,
    setValue,
    updateLocations
  ])

  const onSubmit = async (data) => {
    delete data['communicationType']

    try {
      setPageAlert(null)
      setIsLoading(true)

      let { data: responseData } = await axios.post(`${props.data?.site ? `/site/update/${props.data?.site?.id}` : '/site/create'}`, {
        name: data.name,
        description: data.description,
        lat: data.lat,
        long: data.lng,
        rtu_id: data.rtuId?.value,
        line_link_id: communicationType.value === 0 ? data.lineLinkId?.value : null,
        radio_zone: communicationType.value === 1 ? data.radioZone?.value : null,
        radio_link_id: communicationType.value === 1 ? data.radioLinkId?.value : null,
        fep_id: data.fepId?.value,
        location_id: props.data?.locationId || data.location?.value
      })

      if (responseData.success) {
        setPageAlert({ type: 'success', content: `${responseData.site.name} has been successfully ${props.data?.site ? 'updated' : 'created'}.` })

        navigate(`/site/manage/${responseData.site.id}`)

        if (isFunction(props.close)) {
          props.close()
        }
      }
    } catch (error) {
      Bugsnag.notify(error)
      setPageAlert({ type: 'error', content: 'An error has occured and we were not able to save this site. Please try again.' })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      icon={<i className="fak fa-rtu fa-2xl"></i>}
      iconFill="true"
      title={props.data?.site?.location?.length ? `${props.data?.site ? 'Edit' : 'Create' } site for ${props.data?.site?.location?.label || props.data?.site?.location[0]?.label}` : 'Create site'}
      close={props.close}
      closeOnOutsideClick={props.closeOnOutsideClick}
    >
      <SiteForm onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Input
          name="name"
          label="Name"
          className={errors.name && 'error'}
          {...register('name', { value: props.data?.site?.name })}
        />
        {errors.name && <InputError message={errors.name.message} />}

        <Input
          name="description"
          label="Description"
          className={errors.description && 'error'}
          {...register('description', { value: props.data?.site?.description })}
        />
        {errors.description && <InputError message={errors.description.message} />}

        {
          !props.data?.site?.id &&
          <GooglePlacesAutocomplete
            label="Address"
            name="address"
            value={address || ''}
            onChange={addressChange}
            coordinatesChange={coordinatesChange}
            className={errors.address && 'error'}
          />
        }
        {errors.address && <InputError message={errors.address.message} />}

        {
          !props.data?.locationId &&
          <>
            <Controller
              control={control}
              defaultValue={filter(locationOptions, ['value', props.data?.site?.locationId])}
              name="location"
              render={({ field }) => (
                <Select
                  {...field}
                  isMulti={false}
                  isSearchable={true}
                  label="Location"
                  options={locationOptions}
                  hasError={!!errors.location}
                />
              )}
            />
            {errors.location && <InputError message={errors.location.message} />}
          </>
        }

        {fepOptions &&
          <div className="row">
            <div className="col-12">
              <Controller
                control={control}
                defaultValue={head(filter(fepOptions, ['value', props.data?.site?.fepId])) || []}
                name="fepId"
                render={({ field }) => (
                  <Select
                    {...field}
                    isMulti={false}
                    isSearchable={true}
                    label="FEP"
                    options={fepOptions}
                    hasError={!!errors.fepId}
                  />
                )}
              />
              {errors.fepId && <InputError message={errors.fepId.message} />}
            </div>
          </div>
        }

        {fepId && (
          <>
            <Controller
              control={control}
              defaultValue={{ label: props.data?.site?.rtuId, value: props.data?.site?.rtuId }}
              name="rtuId"
              render={({ field }) => (
                <Select
                  {...field}
                  isMulti={false}
                  isSearchable={true}
                  label="Site ID"
                  options={rtuOptions}
                  hasError={!!errors.rtuId}
                />
              )}
            />
            {errors.rtuId && <InputError message={errors.rtuId.message} />}
          </>
        )}

        <Controller
          control={control}
          defaultValue={''}
          name="communicationType"
          render={({ field }) => (
            <Select
              {...field}
              isMulti={false}
              isSearchable={true}
              label="Communication type"
              options={communicationTypeOptions}
              hasError={!!errors.communicationType}
            />
          )}
        />
        {errors.communicationType && <InputError message={errors.communicationType.message} />}

        {communicationType && communicationType.value === 1 && (
          <div className="row">
            <div className="col-6">
              <Controller
                control={control}
                defaultValue={head(filter(radioZoneOptions, ['value', props.data?.site?.radioZone]))}
                name="radioZone"
                render={({ field }) => (
                  <Select
                    {...field}
                    isMulti={false}
                    isSearchable={true}
                    label="Radio zone"
                    options={radioZoneOptions}
                    hasError={!!errors.radioZone}
                  />
                )}
              />
              {errors.radioZone && <InputError message={errors.radioZone.message} />}
            </div>

            <div className="col-6">
              <Controller
                control={control}
                defaultValue={head(filter(radioLinkOptions, ['value', props.data?.site?.radioLinkId]))}
                name="radioLinkId"
                render={({ field }) => (
                  <Select
                    {...field}
                    isMulti={false}
                    isSearchable={true}
                    label="Radio link ID"
                    options={radioLinkOptions}
                    hasError={!!errors.radioLinkId}
                  />
                )}
              />
              {errors.radioLinkId && <InputError message={errors.radioLinkId.message} />}
            </div>
          </div>
        )}

        {communicationType && communicationType.value === 0 && (
          <>
            <Controller
              control={control}
              defaultValue={head(filter(lineOptions, ['value', props.data?.site?.lineLinkId]))}
              name="lineLinkId"
              render={({ field }) => (
                <Select
                  {...field}
                  isMulti={false}
                  isSearchable={true}
                  label="Line link ID"
                  options={lineOptions}
                  hasError={!!errors.lineLinkId}
                />
              )}
            />
            {errors.lineLinkId && <InputError message={errors.lineLinkId.message} />}
          </>
        )}

        <ButtonGroup className='buttons'>
          <Anchor
            style={{
              width: 'calc(50% - 5px)'
            }}
            className='transparent'
            onClick={() => {
              setPageAlert(null)
              reset()

              props.close()
            }}
          >
            Close
          </Anchor>

          <Button
            style={{
              width: 'calc(50% - 5px)'
            }}
            disabled={isLoading ? true : false}
          >
            {isLoading ? <div className="primary-loader light"></div> : 'Save'}
          </Button>
        </ButtonGroup>
      </SiteForm>
    </Modal>
  )
}

export default SiteModal
