import "react-circular-progressbar/dist/styles.css"
import styled from 'styled-components'
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar'
import { atom, useSetRecoilState } from 'recoil'
import { camelCase, filter, find, isEmpty, map, mapKeys, round } from 'lodash'
import { rem } from 'polished'
import { useCallback } from "react"
import { useParams } from 'react-router-dom'

import ActionIcon from '@components/ActionIcon'
import Card from '@components/Card'
import DropdownList from '@components/DropdownList'
import Pill from '@components/pill/Pill'
import ProgressBar from '@components/ProgressBar'

const ProgressContainer = styled.div`
  padding: 10px;
  width: 200px;
`

const SmallText = styled.div`
  color: var(--text-light);
  font-size: ${rem(12)};
`

const ProgressValue = styled.div`
  font-size: ${rem(24)};
  font-weight: 500;
`

const PumpStats = styled.div`
  margin-top: -25px;
  text-align: center;
`

const PillContainer = styled.div`
  margin-top: 25px;
`

const modalState = atom({
  key: 'modal',
  default: null,
})

const pageAlertState = atom({
  key: 'alert',
  default: null
})

function Pumps(props) {
  const setModal = useSetRecoilState(modalState)
  const urlParams = useParams()

  const statusColor = useCallback((pump) => {
    if (pump.alarm_types?.length) {
      return {
        pathColor: '#F04438',
        trailColor:'#FEF3F2'
      }
    } else if (filter(pump.operational_statuses, ['key', 'paused']).length) {
      return {
        pathColor: '#F79009',
        trailColor:'#FFFAEB'
      }
    } else if (filter(pump.operational_statuses, ['key', 'running']).length) {
      return {
        pathColor: '#12B76A',
        trailColor: '#ECFDF3'
      }
    } else {
      return {
        pathColor: '#4E5BA6',
        trailColor:'#F8F9FC'
      }
    }
  }, [])

  const getFunctionByKey = useCallback((programFunction, functionData) => {
    if (programFunction && functionData) {
      return find(programFunction, { 'key': functionData.data.internal.function_key })
    }
  }, [])

  const pumpProgress = useCallback(programFunction => {
    let percentage = round(((programFunction.stats?.pid?.total || 0) / programFunction.data?.values?.totalFert) * 100)

    return Math.min(Math.max(percentage, 0), 100)
  }, [])

  return (
    <div className="row">
      <div className="col-12 col-md-6 col-lg-4 d-flex">
        <Card
          title={(
            <>
              Mainline
            </>
          )}
        >
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <ProgressContainer>
                <CircularProgressbarWithChildren
                  value={props.program.stats?.main?.flow ? (props.program.stats?.main?.flow / props.program.flow_meter_max * 100) : 0}
                  strokeWidth='10'
                  circleRatio='0.5'
                  styles={buildStyles({
                    rotation: -0.25,
                    pathColor: props.statusColor.pathColor,
                    trailColor: props.statusColor.trailColor,
                  })}
                >
                  <PumpStats>
                    <SmallText>Flow</SmallText>
                    <ProgressValue>
                      {props.program.stats?.main?.flow ? round(props.program.stats?.main?.flow) : '-'} gpm
                    </ProgressValue>
                  </PumpStats>
                </CircularProgressbarWithChildren>
              </ProgressContainer>
            </div>
          </div>
        </Card>
      </div>

      {props.program.function_data && (
        map(props.program.function_data, functionData => {
          const programFunctionInfo = getFunctionByKey(props.program.program_function.options, functionData)

          return <div className="col-12 col-md-6 col-lg-4 d-flex" key={`function-${functionData.id}`}>
            <Card
              title={(
                <>
                <div className="d-flex">
                  <div className="d-flex align-items-center">
                    <span className="title">
                      {functionData.data.values.name}
                    </span>
                  </div>

                  <div>
                    {functionData.operational_statuses && (
                      map(functionData.operational_statuses, status => {
                        return <Pill color={status.color} hasDot={true} key={status.title}>{status.title}</Pill>
                      })
                    )}
                    {functionData.alarm_types && (
                      map(functionData.alarm_types, alarm => {
                        return <Pill color={alarm.color} hasDot={true} key={alarm.title}>{alarm.title}</Pill>
                      })
                    )}
                  </div>
                </div>
                </>
              )}
              actions={
                <>
                  <DropdownList
                    icon={<ActionIcon />}
                    options={[
                      {
                        label: 'Edit Pump',
                        onClick: () => {
                          setModal({
                            name: 'hardwareSettings',
                            data: {
                              function: programFunctionInfo,
                              functionData: functionData,
                              programData: props.program,
                              onSave: () => {
                                props.updateDashboard()
                              }
                            }
                          })
                        }
                      },
                      {
                        label: 'Delete Pump',
                        onClick: () => {
                          setModal({
                            name: 'warning',
                            data: {
                              endpoint: `/function-data/delete/${functionData.id}`,
                              title: 'Delete pump',
                              content: `Are you sure you want to delete ${functionData.data.values.name}? This action cannot be undone.`,
                              successFlashMessage: 'Pump status changed to paused.',
                              onComplete: () => {
                                props.updateDashboard()
                              }
                            }
                          })
                        }
                      },
                    ]}
                  />
                </>
              }
            >
              {programFunctionInfo.attributes.name === 'Fertigation pump' && (
                <div className="row">
                  <div className="col-12 d-flex justify-content-center">
                    <ProgressContainer>
                      <CircularProgressbarWithChildren
                        value={functionData.stats?.pid?.flow ? (functionData.stats?.pid.flow / functionData.data.values.flowScale * 100) : 0}
                        strokeWidth="10"
                        circleRatio="0.5"
                        styles={buildStyles({
                          rotation: -0.25,
                          textColor: "red",
                          pathColor: statusColor(functionData).pathColor,
                          trailColor: statusColor(functionData).trailColor,
                        })}
                      >
                        <PumpStats>
                          <SmallText>Flow</SmallText>
                          <ProgressValue>
                            {functionData.stats?.pid?.flow || '-'} gpm
                          </ProgressValue>
                        </PumpStats>
                      </CircularProgressbarWithChildren>
                    </ProgressContainer>
                  </div>
                </div>
              )}

              {programFunctionInfo.attributes.name === 'Microdosing pump' && (
                <div className="row">
                  <div className="col-12 d-flex justify-content-center">
                    <ProgressContainer>
                      <CircularProgressbarWithChildren
                        value={functionData.stats?.dose.flow ? (functionData.stats?.dose.flow / functionData.data.values.dosingScale * 100) : 0}
                        strokeWidth="10"
                        circleRatio="0.5"
                        styles={buildStyles({
                          rotation: -0.25,
                          textColor: "red",
                          pathColor: statusColor(functionData).pathColor,
                          trailColor: statusColor(functionData).trailColor,
                        })}
                      >
                        <PumpStats>
                          <SmallText>Flow</SmallText>
                          <ProgressValue>
                            {functionData.stats?.dose.flow} gph
                          </ProgressValue>
                        </PumpStats>
                      </CircularProgressbarWithChildren>
                    </ProgressContainer>
                  </div>
                </div>
              )}

              {programFunctionInfo.attributes.name !== 'Microdosing pump' && (
                <div className="row">
                  <div className="col-12">
                    <ProgressBar
                      margin="0 15px"
                      pathColour={statusColor(functionData).pathColor}
                      trailColour={statusColor(functionData).trailColor}
                      label={`${functionData.data.values.totalFert} ${functionData.data.values.fertilizerUnitOfMeasure.label}`}
                      value={`${pumpProgress(functionData)}%`}
                      tooltipValue={`${functionData.stats?.pid?.total} ${functionData.data.values.fertilizerUnitOfMeasure.label}`}
                    />
                  </div>
                </div>
              )}

              {!isEmpty(functionData.data.values.fertilizerMaterial) && (
                <div className="row">
                  <PillContainer className="col-12 d-flex justify-content-center">
                    {map(functionData.data.values.fertilizerMaterial, (material, index, arr) => {
                      if (index < 4) {
                        return (
                          <Pill color={material.color} key={index}>
                            {material.label}
                          </Pill>
                        )
                      } else if (index + 1 === arr.length) {
                        return <Pill color={'grey'} key={index}>
                          {`+${arr.length - 4}`}
                       </Pill>
                      }
                    })}
                  </PillContainer>
                </div>
              )}
            </Card>
          </div>
        })
      )}
    </div>
  )
}

export default Pumps