import * as yup from 'yup'
import Bugsnag from '@bugsnag/js'
import styled from 'styled-components'
import { atom, useRecoilState, useSetRecoilState } from 'recoil'
import { isFunction } from 'lodash'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import InputError from '@components/form/InputError'
import Modal from '@components/Modal'
import Textarea from '@components/form/Textarea'
import axios from '@utilities/axios'
import { Anchor, Button } from '@components/form/Buttons'

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 28px;
`

const Form = styled.form`
  label {
    margin-top: 20px;
  }

  .buttons {
    margin-top: 40px;
  }
`

const alertState = atom({
  key: 'alert',
  default: {}
})

const isLoadingState = atom({
  key: 'isLoading',
  default: false
})

const schema = yup.object({
  comment: yup.string().label('Comment').required().min(3).max(1500),
})

function CommentModal(props) {
  const setAlert = useSetRecoilState(alertState)
  const [isLoading, setIsLoading] = useRecoilState(isLoadingState)
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  })

  const onSubmit = async data => {
    try {
      setAlert(null)
      setIsLoading(true)

      let { data: responseData } = await axios.post(props?.data.comment ? `/comment/update/${props.data.comment.id}` : '/comment/store', {
        comment: data.comment,
        alarm_id: props.data.alarmId
      })

      if (responseData.success && isFunction(props.complete)) {
        props.complete()
      }
    } catch (error) {
      Bugsnag.notify(error)
      setAlert({ type: 'error', content: 'An error has occured and we were not able to save this site. Please try again.' })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      icon={<i className="fa-light fa-comment-plus"></i>}
      iconFill="true"
      title={props?.data.comment ? 'Edit comment' : 'Add comment'}
      close={props.close}
      closeOnOutsideClick={props.closeOnOutsideClick}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-12">
            <Textarea
              label="Comment"
              className={errors.comment && 'error'}
              {...register('comment', { value: props.data?.comment?.comment })}
            />
            {errors.comment && <InputError message={errors.comment.message} />}
          </div>
        </div>

        <ButtonGroup className='buttons'>
          <Anchor
            style={{
              width: 'calc(50% - 5px)'
            }}
            className='transparent'
            onClick={() => {
              setAlert(null)
              props.close()
            }}
          >
            Close
          </Anchor>

          <Button
            style={{
              width: 'calc(50% - 5px)'
            }}
            disabled={isLoading ? true : false}
          >
            {isLoading ? <div className="primary-loader light"></div> : 'Save'}
          </Button>
        </ButtonGroup>
      </Form>
    </Modal>
  )
}

export default CommentModal
