import { atom, useRecoilState } from 'recoil'

import ConfirmationPromptModal from '@components/modals/ConfirmationPromptModal'
import HardwareSettingsModal from '@components/modals/HardwareSettingsModal'
import LocationModal from '@components/modals/LocationModal'
import ProgramModal from '@components/modals/ProgramModal'
import SiteModal from '@components/modals/SiteModal'
import WarningPromptModal from '@components/modals/WarningPromptModal'

const modalState = atom({
  key: 'modal',
  default: null,
})

function Modals() {
  const [modal, setModal] = useRecoilState(modalState)

  return (
    <>
      {modal?.name === 'location' &&
        <LocationModal
          data={modal?.data}
          close={() => {
            setModal(null)
          }}
        />
      }

      {modal?.name === 'site' &&
        <SiteModal
          data={modal?.data}
          close={() => {
            setModal(null)
          }}
        />
      }

      {modal?.name === 'program' &&
        <ProgramModal
          data={modal?.data}
          close={() => {
            setModal(null)
          }}
        />
      }

      {modal?.name === 'hardwareSettings' &&
        <HardwareSettingsModal
          data={modal?.data}
          close={() => {
            setModal(null)
          }}
        />
      }

      {modal?.name === 'warning' &&
        <WarningPromptModal
          data={modal?.data}
          close={() => {
            setModal(null)
          }}
        />
      }

      {modal?.name === 'confirmation' &&
        <ConfirmationPromptModal
          data={modal?.data}
          close={() => {
            setModal(null)
          }}
        />
      }
    </>
  )
}

export default Modals
