import * as yup from 'yup'
import Bugsnag from '@bugsnag/js'
import styled from 'styled-components'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { atom, useRecoilState } from 'recoil'
import { rem } from 'polished'
import { useForm } from 'react-hook-form'
import { useEffect } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'

import AuthAlert from '@components/AuthAlert'
import InputError from '@components/form/InputError'
import InputField from '@components/form/Input'
import axios from '@utilities/axios'
import { Button } from '@components/form/Buttons'

const Icon = styled.div`
  align-items: center;
  background-color: #f5fbff;
  border-radius: 100%;
  color: var(--primary);
  display: flex;
  height: 56px;
  justify-content: center;
  width: 56px;
`

const ErrorContainter = styled.div`
  margin-bottom: 26px;
`

const Title = styled.h1`
  font-size: ${rem(30)};
  font-weight: 700;
  margin-top: 24px;
  text-align: center;
`

const SubText = styled.div`
  color: #667085;
  font-size: ${rem(16)};
  font-weight: 300;
  margin: 12px 0 32px;
  text-align: center;
`

const FormContainer = styled.form`
  margin: auto 0;
  width: 360px;
  max-width: 100%;

  label {
    margin-top: 20px;
  }

  button {
    margin-top: 24px;
  }
`

const BackText = styled.div`
  color: #667085;
  font-size: ${rem(14)};
  font-weight: 300;
  margin: 32px auto;
  text-align: center;

  a {
    text-decoration: none;
  }
`

const hashValidState = atom({
  key: 'hashValid',
  default: false
})

const authAlertState = atom({
  key: 'authAlert',
  default: {}
})

const schema = yup.object({
  password: yup.string().label('Password').required().min(6).max(100),
  passwordConfirm: yup.string().label('Confirm password').oneOf([yup.ref('password'), null], 'Confirm password does not match').required(),
})

function ResetPassword() {
  const [alert, setAlert] = useRecoilState(authAlertState)
  const [hashValid, setHashValid] = useRecoilState(hashValidState)
  const navigate = useNavigate()
  const urlParams = useParams()
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    (async() => {
      try {
        const { data } = await axios.post('/user/reset-password/validate', {
          hash: urlParams.hash
        })

        if (data?.success) {
          setHashValid(true)
        } else {
          navigate('/user/login')
        }

        return () => {
          setHashValid(false)
        }
      } catch (error) {
        Bugsnag.notify(error)
      }
    })()
  }, [urlParams, setHashValid, navigate])

  const sendReset = async data => {
    setAlert(null)

    try {
      let { data: responseData } = await axios.post('/user/reset-password/reset', {
        password: data.password,
        password_confirmation: data.passwordConfirm,
        hash: urlParams.hash
      })

      if (responseData?.success) {
        navigate('/user/login')
        setAlert({ type: 'success', message: 'Your password was reset successfully.' })
      }
    } catch (error) {
      setAlert({ message: 'Oops, something went wrong. Please try again.' })
      Bugsnag.notify(error)
    }
  }

  return (
    <div className='container-fluid'>
      <div className="row">
        <div className="d-flex flex-column align-items-center col-12">
          {!hashValid && (
            <>
              <div className="col-12 d-flex justify-content-center">
                <Icon>
                  <i className="fa-solid fa-key-skeleton fa-spin"></i>
                </Icon>
              </div>

              <SubText>Validating password reset request</SubText>
            </>
          )}

          {hashValid && (
            <FormContainer onSubmit={handleSubmit(sendReset)} autoComplete="off">
              <div className="col-12 d-flex justify-content-center">
                <Icon>
                  <i className="fa-solid fa-key-skeleton"></i>
                </Icon>
              </div>

              <Title>Forgot password?</Title>
              <SubText>No worries, we'll send you reset instructions.</SubText>

              <ErrorContainter>
                <AuthAlert class={alert ? 'd-flex' : 'd-none'} message={alert?.message} />
              </ErrorContainter>

              <InputField
                label="New password"
                placeholder="Password"
                type="password"
                autoComplete="new-password"
                className={errors.password && 'error'}
                {...register('password')}
              />
              {errors.password && <InputError message={errors.password.message} />}

              <InputField
                label="Confirm Password"
                placeholder="Confirm password"
                type="password"
                autoComplete="new-password"
                className={errors.passwordConfirm && 'error'}
                {...register('passwordConfirm')}
              />
              {errors.passwordConfirm && <InputError message={errors.passwordConfirm.message} />}

              <Button
                style={{
                  width: '100%'
                }}
              >
                Reset password
              </Button>

              <BackText>
                <Link to="/user/login"><i className="fa-solid fa-arrow-left"></i> Back to log in</Link>
              </BackText>
            </FormContainer>
          )}
        </div>
      </div>
    </div>
  )
}

export default ResetPassword