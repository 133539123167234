import styled from 'styled-components'
import { slide as Menu } from 'react-burger-menu'
import { useNavigate } from 'react-router-dom'

import '../vendor/react-burger-menu/styles.css'
import { atom, useRecoilState } from 'recoil'

const Logo = styled.div`
  margin-top: 0;
  margin-bottom: 16px;

  img {
    max-width: 100%;
  }
`

const Button = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin-left: ${props => props.subItem ? 20 : 0}px;
`

const menuOpenState = atom({
  key: 'menuOpen',
  default: false
})

function MobileMenu() {
  const [menuOpen, setMenuOpen] = useRecoilState(menuOpenState)
  const navigate = useNavigate()

  const click = url => {
    navigate(url)
    setMenuOpen(false)
  }

  return (
    <Menu
      isOpen={menuOpen}
      onStateChange={state => setMenuOpen(state.isOpen)}
    >
      <Logo className="logo">
        <img src="/images/futureops-logo.svg" alt="FutureOps Logo" />
      </Logo>

      <Button onClick={() => click('/overview')} className="menu-item">Overview</Button>

      <Button onClick={() => click('/alarms')} subItem className="menu-item">Alarms</Button>
      <Button onClick={() => click('/locations')} subItem className="menu-item">Locations</Button>
      <Button onClick={() => click('/sites')} subItem className="menu-item">Sites</Button>
      <Button onClick={() => click('/programs')} subItem className="menu-item">Programs</Button>

      <Button onClick={() => click('/dashboard')} className="menu-item">Dashboard</Button>
      <Button onClick={() => click('/configuration')} className="menu-item">Configuration</Button>
      <Button onClick={() => click('/reporting')} className="menu-item">Reporting</Button>
      <Button onClick={() => click('/reporting-extra')} className="menu-item">Reporting Extra</Button>
    </Menu>
  )
}

export default MobileMenu