import classNames from 'classnames'
import styled from 'styled-components'
import { map } from 'lodash'
import { mediaBreakpointUp } from '@utilities/breakpoint'
import { rem } from 'polished'
import { useEffect, useRef, useState } from 'react'

const TabWrapper = styled.div`
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #D0D5DD;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  user-select: none;

  ${mediaBreakpointUp('sm', `
    display: inline-flex;
    flex-wrap: nowrap;
  `)}
`

const Tab = styled.div`
  cursor: pointer;
  display: inline-block;
  flex-basis: 100%;
  font-size: ${rem(14)};
  padding: 13px 18px;
  white-space: nowrap;

  &:last-of-type {
    border: none;
  }

  &.active {
    background: #F9FAFB;
  }

  ${mediaBreakpointUp('sm', `
    border-right: 1px solid #D0D5DD;
    width: auto;
  `)}
`

function Tabs(props) {
  const [selectedTab, setSelectedTab] = useState(null)
  const firstLoad = useRef(true)
  const { onChange, currentlySelected } = props

  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false

      if (props.default) {
        setSelectedTab(props.default)
      }
    }
  }, [selectedTab, setSelectedTab, props])

  useEffect(() => {
    onChange(selectedTab)
  }, [selectedTab])

  useEffect(() => {
    if (currentlySelected && selectedTab && currentlySelected !== selectedTab) {
      setSelectedTab(currentlySelected)
    }
  }, [currentlySelected])

  return (
    props.tabs ?
    <TabWrapper color={props.color}>
      {map(props.tabs, (tab, index) => {
        return (
          <Tab
            key={index}
            className={classNames({
              active: selectedTab === tab.key
            })}
            onClick={() => {
              setSelectedTab(tab.key)
            }}
          >
            {tab.title}
          </Tab>
        )
      })}
    </TabWrapper> : ''
  )
}

export default Tabs
