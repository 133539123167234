import Bugsnag from '@bugsnag/js'
import OutsideClickHandler from 'react-outside-click-handler'
import styled from 'styled-components'
import { atom, useRecoilState, useSetRecoilState } from 'recoil'
import { chain, cloneDeep, filter, forEach, head, isEmpty, map } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Map from '@components/Map'
import SelectField from '@components/form/Select'
import axios from '@utilities/axios'
import echo from '@utilities/echo'
import { statusColor } from '@utilities/statusColor'

const DashboardMap = styled.div`
  display: block;
  width: 100%;
  height: ${props => props.fullHeight ? 'calc(100vh - 73px)' : '440px'};
  margin-bottom: ${props => props.fullHeight ? '-20px' : '20px'};
  margin-top: -20px;
  position: relative;
`

const SelectContainer = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 100;
`

const ShowSelect = styled.div`
  align-items: center;
  background: #ffffff;
  border-radius: 2px;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
  color: #5F5F5F;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  width: 40px;

  &:hover {
    color: #333333;
  }
`

const sitesState = atom({
  key: 'dashboardSites',
  default: {}
})

const pageAlertState = atom({
  key: 'alert',
  default: {}
})

function Dashboard() {
  const [showSelect, setShowSelect] = useState(false)
  const [sites, setSites] = useRecoilState(sitesState)
  const setPageAlert = useSetRecoilState(pageAlertState)
  const navigate = useNavigate()

  useEffect(() => {
    (async () => {
      try {
        let { data } = await axios.get(`/site/query?pageSize=1000&with[]=location&with[]=programs.operationalStatuses`)

        if (data && data.success) {
          const siteData = data.sites.data

          setSites(siteData)
        }

        if (!data?.sites.data.length) {
          navigate('/configuration')
          setPageAlert({ type: 'info', content: 'You need to configure your first site to start using the dashboard.' })
        }
      } catch (error) {
        Bugsnag.notify(error)
      }
    })()
  }, [setSites, setPageAlert, navigate])

  useEffect(() => {
    let sitesData = cloneDeep(sites)

    forEach(sitesData, (site) => {
      echo.private(`site.${site.id}`)
        .listen('SiteProgramUpdated', data => {
          console.log('SiteProgramUpdated', data)

          let updatedProgram = head(filter(site.programs, program => {
            return program.id === data.program.id
          }))

          updatedProgram.operational_statuses = data.program.operational_statuses

          setSites(sitesData)
        })
    })

    return () => {
      forEach(sites, (site) => {
        echo.private(`site.${site.id}`)
          .stopListening('SiteProgramUpdated')
      })
    }
  }, [sites, setSites])

  const markers = useMemo(() => {
    if (!isEmpty(sites)) {
      return map(sites, (site) => {
        if (parseFloat(site.lat) && parseFloat(site.long)) {
          return {
            id: `siteId#${site.id}`,
            position: {
              lat: parseFloat(site.lat),
              lng: parseFloat(site.long)
            },
            title: site.name,
            fillColor: statusColor(chain(site.programs).map('operational_statuses').flatten().value()).pathColor,
            draggable: false,
            clickHandler: (event, id) => {
              const siteId = id.split('#')[1]
              navigate(`/dashboard/view/${siteId}`)
            }
          }
        } else {
          return null
        }
      })
    }

    return null
  }, [sites, navigate])

  const siteOptions = useMemo(() => {
    return chain(sites)
    .groupBy(site => {
      return site.location.id
    })
    .map(sitesByLocation => {
      const siteOptions = map(sitesByLocation, site => {
        return { label: site.name, value: site.id }
      })
      return {
        label: head(sitesByLocation).location.name,
        options: siteOptions
      }
    })
    .value()
  }, [sites])

  if (isEmpty(sites)) {
    return <></>
  }

  return (
    <>
      <DashboardMap fullHeight={true}>
        {siteOptions && (
          <SelectContainer>
            {
              showSelect ? (
                <OutsideClickHandler onOutsideClick={() => { setShowSelect(false) }}>
                  <SelectField
                    isSearchable={true}
                    display="inline-block"
                    width="300px"
                    placeholder="Search"
                    options={siteOptions}
                    onChange={(option) => {
                      setShowSelect(false)
                      navigate(`/dashboard/view/${option.value}`)
                    }}
                  />
                </OutsideClickHandler>
              ) : (
                <ShowSelect onClick={() => { setShowSelect(true) }}>
                  <i className="fa-solid fa-magnifying-glass"></i>
                </ShowSelect>
              )
            }
          </SelectContainer>
        )}

        <Map markers={markers} square key="main" />
      </DashboardMap>
    </>
  )
}

export default Dashboard;
