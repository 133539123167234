import styled from 'styled-components'

const PageContainerElement = styled.div`
  padding: 0 26px;
  width: 100%;
`

function PageContainer(props) {
  return (
    <PageContainerElement {...props}>
        {props.children}
    </PageContainerElement>
  )
}

export default PageContainer
