import Select from 'react-select'
import styled from 'styled-components'
import { forwardRef } from 'react'
import { normaliseContrast } from '@utilities/colors'
import { rgba } from 'polished'
import { mediaBreakpointDown } from '@utilities/breakpoint'

const TrailingInputStyle = mediaBreakpointDown('sm', `
  width: 100%;

  .react-select {
    &__control {
      border-radius: 8px 8px 0 0;
    }
  }
`)

const Label = styled.label`
  color: var(--text-dark);
  display: flex;
  font-size: 14px;
  margin-bottom: 6px;
`

const InputWrapper = styled.div`
  color: var(--text-dark);
  display: ${props => props.display || 'initial'};
  max-width: ${props => props.maxWidth ? `${props.maxWidth}px` : 'initial'};
  width: ${props => props.width || '100%'};

  > div {
    width: 100%;
  }

  ${props => props.trailingInput && `
    ${TrailingInputStyle}
  `}
`

const selectStyles = props => {
  return {
    dropdownIndicator: (provided, state) => {
      if (props.small) {
        const padding = '4px 10px'

        return { ...provided, padding }
      }

      return { ...provided }
    },
    indicatorSeparator: (provided, state) => {
      const display = 'none'

      return { ...provided, display }
    },
    valueContainer: (provided, state) => {
      let padding = '7px 12px'

      if (props.small) {
        padding = '4px 10px'
      }

      return { ...provided, padding }
    },
    control: (provided, state) => {
      let borderRadius = '8px'
      let minHeight = '44px'
      let defaultBorderColor = '#D0D5DD'
      let errorBorderColor = '#FDA29B'
      let borderColor = defaultBorderColor
      let boxShadow = 'none'
      let outline = 'none'

      if (props.small) {
        minHeight = 'auto'
      }

      if (props.trailingInput) {
        borderRadius = '8px 0 0 8px'
      }

      // Menu open without error
      if (state.menuIsOpen) {
        outline = '2px solid #2684FF'
      }

      // Menu open with error
      if (props.hasError && state.menuIsOpen) {
        outline = '2px solid #FDA29B !important'
      }

      // Error regardless of menu open or closed
      if (props.hasError) {
        borderColor = errorBorderColor
      }

      return {
        ...provided,
        borderRadius,
        minHeight,
        borderColor,
        boxShadow,
        outline,
        '&:hover': {
          borderColor: props.hasError ? errorBorderColor : defaultBorderColor,
        },
      }
    },
    multiValue: (provided, state) => {
      let backgroundColor = '#f9fafb'
      let border = '1px solid #D0D5DD'
      let color = 'var(--text-light)'

      if (state.data && state.data.color) {
        color = `${state.data.color}`
        backgroundColor = rgba(state.data.color, 0.1)
        border = 'none'
      }

      const borderRadius = '14px'

      return { ...provided, borderRadius, border, backgroundColor, color }
    },
    multiValueLabel: (provided, state) => {
      let color = 'var(--text-light)'

      if (state.data && state.data.color) {
        color = normaliseContrast(state.data.color, '#fff')
      }

      return { ...provided, color };
    },
    singleValue: (provided, state) => {
      const color = 'var(--text-dark)'

      return { ...provided, color };
    },
    multiValueRemove: (provided, state) => {
      const borderRadius = '0 14px 14px 0'

      return { ...provided, borderRadius }
    }
  }
}

const SelectField = forwardRef((props, ref) => {
  return (
    <InputWrapper {...props}>
      {props.label && (
        <Label htmlFor={props.name}>{props.label}</Label>
      )}

      <Select
        {...props}
        classNamePrefix="react-select"
        isSearchable={props.isSearchable || false}
        menuPlacement={props.menuPlacement || 'auto'}
        options={props.options || []}
        ref={ref}
        styles={selectStyles(props)}
      />
    </InputWrapper>
  )
})

export default SelectField
