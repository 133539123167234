import Bugsnag from '@bugsnag/js'
import styled from 'styled-components'
import { atom, useRecoilState, useSetRecoilState } from 'recoil'
import { isEmpty, isFunction } from 'lodash'
import { useNavigate } from 'react-router-dom'

import Modal from '@components/Modal'
import axios from '@utilities/axios'
import { Anchor, Button } from '@components/form/Buttons'

const pageAlertState = atom({
  key: 'alert',
  default: {}
})

const isLoadingState = atom({
  key: 'isLoading',
  default: false
})

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 28px;
`

function WarningPromptModal(props) {
  const setPageAlert = useSetRecoilState(pageAlertState)
  const [isLoading, setIsLoading] = useRecoilState(isLoadingState)
  const navigate = useNavigate()

  const confirm = async () => {
    let postData = {}

    if (!isEmpty(props.data?.postData)) {
      postData = props.data?.postData
    }

    try {
      setIsLoading(true)
      let { data } = await axios.post(`${props.data.endpoint}`, postData)

      if (data && data.success) {
        if (props.data?.redirect) {
          navigate(props.data?.redirect)
        }

        if (isFunction(props.data?.onComplete)) {
          props.data.onComplete()
        }

        setPageAlert({ type: 'success', content: props.data.successFlashMessage })

        props.close()
      }
    } catch (error) {
      Bugsnag.notify(error)

      if (isFunction(props.data?.onFailure)) {
        props.data.onFailure()
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      icon={<i className="fa-regular fa-triangle-exclamation fa-xl"></i>}
      type="error-prompt"
      title={props.data?.title}
      close={props.close}
      closeOnOutsideClick={props.closeOnOutsideClick}
      modalWidth="544px"
    >
      {!isEmpty(props.data?.content) && props.data.content}

      <ButtonGroup>
        <Anchor
          style={{
            width: 'calc(50% - 5px)'
          }}
          className="transparent"
          onClick={() => {
            props.close()
          }}
        >
          Close
        </Anchor>

        <Button
          style={{
            width: 'calc(50% - 5px)'
          }}
          className="warning"
          onClick={() => { confirm() }}
          disabled={(isLoading ? true : false) || props.data.disableConfirmButton}
        >
          {isLoading ? <div className="primary-loader light"></div> : 'Confirm'}
        </Button>
      </ButtonGroup>
    </Modal>
  )
}

export default WarningPromptModal
