import { forwardRef } from 'react'
import styled from 'styled-components'

const Textarea = styled.textarea`
  background: ${props => props.background || 'transparent'};
  border-radius: 8px;
  border: 1px solid var(--border-color);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  color: var(--text-dark);
  font-size: 16px;
  line-height: 1;
  max-width: 100%;
  padding: ${props => props.searchIcon ? "12px 28px 12px 40px" : "12px 14px"};
  width: ${props => props.width || '100%'};

  ${props => props.leadingSelect && `
    border-radius: 0 8px 8px 0;
    border-left: none;
  `}

  &:focus {
    outline: 2px solid #2684FF;
  }

  ::placeholder {
    color: #667085;
    font-size: 16px;
    font-weight: 300;
  }

  &.error {
    border-color: #FDA29B;

    &:focus {
      outline-color: #FDA29B;
    }
  }
`

const Label = styled.label`
  color: var(--text-dark);
  display: flex;
  font-size: 14px;
  margin-bottom: 6px;
`

const TextareaWrapper = styled.div`
  position: relative;

  ${props => props.leadingSelect && `
    display: inline-block;
    top: -1px;
  `}
`

const TextareaField = forwardRef((props, ref) => {
  return (
    <TextareaWrapper {...props}>
      {props.label && (
        <Label htmlFor={props.name}>{props.label}</Label>
      )}

      <Textarea {...props} ref={ref} rows="5">
        {props.children}
      </Textarea>
    </TextareaWrapper>
  )
})

export default TextareaField
