import * as yup from 'yup'
import InputError from '@components/form/InputError'
import styled from 'styled-components'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { atom, useSetRecoilState } from 'recoil'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import Input from '@components/form/Input'
import { Button } from '@components/form/Buttons'

const Title = styled.h1`
  font-size: 30px;
  font-weight: 700;
`

const SubText = styled.div`
  color: #667085;
  font-size: 16px;
  font-weight: 300;
  margin-top: 12px;
`

const Form = styled.form`
  label {
    margin-top: 20px;
  }

  .buttons {
    margin-top: 40px;
  }
`

const SignUpText = styled.div`
  color: #667085;
  font-size: 14px;
  font-weight: 300;
  margin-top: 32px;

  a {
    color: var(--primary);
    text-decoration: none;
  }
`

const companyDetailsState = atom({
  key: 'companyDetails',
  default: {},
})

const schema = yup.object({
  name: yup.string().label('Company name').required(),
  address: yup.string().label('Company address').required(),
})

function RegisterForm() {
  const navigate = useNavigate()
  const setCompanyDetailsState = useSetRecoilState(companyDetailsState)
  const params = useParams()
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  })

  const handleRegister = async data => {
    setCompanyDetailsState(data)

    navigate(`/user/register/${params.hash}`)
  }

  return (
    <Form onSubmit={handleSubmit(handleRegister)} autoComplete="off">
      <div className="d-flex flex-column justify-content-center">
        <Title>Sign up</Title>
        <SubText>Sign up in less than 2 minutes.</SubText>

        <Input
          placeholder="Enter the company name"
          label="Company name*"
          type="text"
          className={errors.name && 'error'}
          {...register('name')}
        />
        {errors.name && <InputError message={errors.name.message} />}

        <Input
          placeholder="Enter the company address"
          label="Company address*"
          type="text"
          className={errors.name && 'error'}
          {...register('address')}
        />
        {errors.address && <InputError message={errors.address.message} />}

        <div className="d-flex justify-content-center">
          <SignUpText>
            By signing up, I agree to the <Link to="/terms-and-conditions">terms and conditions</Link>.
            I agree to receive emails and text messages from Branif Systems.
            I understand that I can withdraw consent to receive notifications at any time.
          </SignUpText>
        </div>

        <div className="buttons">
          <Button
            style={{
              width: '100%'
            }}
            href="/user/register"
          >
            Confirm
          </Button>
        </div>
      </div>
    </Form>
  )
}

export default RegisterForm
