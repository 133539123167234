import { useEffect, useState } from 'react'
import styled from 'styled-components'

import { rem } from 'polished'
import { forwardRef } from 'react'

const Label = styled.label`
  align-items: center;
  background: grey;
  border-radius: 100px;
  color: var(--text-dark);
  cursor: pointer;
  display: flex;
  font-size: ${rem(14)};
  height: 26px;
  position: relative;
  text-indent: 55px;
  width: 46px;
  white-space: nowrap;

&:after {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 22px;
  height: 22px;
  background: #fff;
  border-radius: 30px;
  transition: 0.3s;
}
`

const Input = styled.input`
  &[type=checkbox]{
    height: 0;
    width: 0;
    visibility: hidden;
  }

  &.checked + label {
    background: var(--primary);
  }

  &.checked + label:after {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }
`

const LightSwitch = forwardRef((props, ref) => {
  const [checked, setChecked] = useState(props.defaultState || false)
  const { name, onToggle } = props

  useEffect(() => {
    if (props.defaultValue) {
      setChecked(props.defaultValue)
    }
  }, [props.defaultValue])


  return (
    <>
      <Input
        type="hidden"
        id={props.name}
        className={checked === true ? 'checked' : ''}
        {...props}
        ref={ref}
      />

      <Label
        htmlFor={props.name}
        onClick={() => {
          if (checked === false) {
            setChecked(true)
            onToggle(name, true)
          } else {
            setChecked(false)
            onToggle(name, false)
          }
        }}
      >
        {props.label}
      </Label>
    </>
  )
})

export default LightSwitch
