export const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400
}

export const mediaBreakpointUp = (breakpoint, styles) => {
  return `
    @media (min-width: ${breakpoints[breakpoint] || breakpoint}px) {
      ${styles}
    }
  `
}

export const mediaBreakpointDown = (breakpoint, styles) => {
  return `
    @media (max-width: ${(breakpoints[breakpoint] || breakpoint) - 0.02}px) {
      ${styles}
    }
  `
}
