import ActionIcon from '@components/ActionIcon'
import Bugsnag from '@bugsnag/js'
import DayPicker from '@components/form/DayPicker'
import Dropdown from '@components/Dropdown'
import DropdownList from '@components/DropdownList'
import Table from '@components/Table'
import axios from '@utilities/axios'
import moment from 'moment'
import styled from 'styled-components'
import { Anchor } from '@components/form/Buttons'
import { atom, useRecoilState, useSetRecoilState } from 'recoil'
import { camelCase, debounce, forEach, map, mapKeys, maxBy, reverse, sortBy } from 'lodash'
import { darken, rem } from 'polished'
import { useEffect, useMemo, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

const programStatsState = atom({
  key: 'programStatsData',
  default: null,
})

const tableDataState = atom({
  key: 'programIndexData',
  default: null,
})

const tableDataLoadingState = atom({
  key: 'programIndexTableLoading',
  default: true,
})

const modalState = atom({
  key: 'modal',
  default: null,
})

const ButtonContainer = styled.div`
  text-align: right;
  margin-top: 15px;
`

const Link = styled.button`
  border: none;
  background-color: transparent;
  color: var(--primary);
  position: relative;

  &:hover {
    color: ${darken(0.2, '#1570ef')};
    cursor: pointer;
  }
`

const DaysLater = styled.div`
  color: var(--text-light);
  font-size: ${rem(11)};
`

const pageAlertState = atom({
  key: 'alert',
  default: null
})

function ProgramsAll() {
  const [programStats, setProgramStats] = useRecoilState(programStatsState)
  const [tableData, setTableData] = useRecoilState(tableDataState)
  const [tableDataLoading, setTableDataLoading] = useRecoilState(tableDataLoadingState)
  const navigate = useNavigate()
  const setAlert = useSetRecoilState(pageAlertState)
  const setModal = useSetRecoilState(modalState)

  const getTableData = useMemo(() => debounce(async ({ pageIndex, pageSize, filters }) => {
    const query = new URLSearchParams([
      ['page', pageIndex + 1],
      ['pageSize', pageSize],
      ['status', filters?.tab || ''],
      ['search', filters?.search?.value || ''],
      ['searchType', filters?.search?.type || ''],
    ])

    try {
      const { data } = await axios.get(`/program/query?${query}&with[]=site&with[]=site.location&with[]=programFunction&with[]=functionData`)

      let programData = map(data.programs.data, program => {
        let stopTime = null
        let displayStopTime = null
        let days

        if (program.function_data.length) {
          const startTime = moment(program.operational_start_time, 'HH:mm')
          const functionData = maxBy(program.function_data, (functionData) => {
            const pumpTime = functionData.data.values.pumpTime || 0
            const delayPump = functionData.data.values.delayPump || 0

            return pumpTime + delayPump
          })

          const pumpTime = functionData.data.values.pumpTime || 0
          const delayPump = functionData.data.values.delayPump || 0

          stopTime = moment(program.operational_start_time, 'HH:mm').add(pumpTime + delayPump, 'minutes')
          displayStopTime = stopTime.format('HH:mm:ss')

          days = stopTime.diff(startTime, 'days')
        }

        return {
          name: program.name,
          description: program.description,
          schedule: (
            <Dropdown
              icon={<Link>View Schedule</Link>}
              positionProgrammatically="right"
              wrapperStyle={{
                position: 'static'
              }}
            >
              <DayPicker initialSchedule={program.operational_days} noBorder={true} />
            </Dropdown>
          ),
          startTime: program.operational_start_time,
          stopTime: (
            <div>
              {displayStopTime}

              {days && <DaysLater>
                {days}d later
              </DaysLater>}
            </div>
          ),
          location: program.site.location.name,
          site: program.site.name,
          action: (
            <DropdownList
              icon={<ActionIcon />}
              positionProgrammatically
              wrapperStyle={{
                position: 'static'
              }}
              style={{
                minWidth: '200px',
                transform: 'translate(calc(-100% + 25px), 25px)',
                textAlign: 'left'
              }}
              options={[
                {
                  label: 'Manage program',
                  onClick: () => {
                    navigate(`/program/manage/${program.id}`)
                  }
                },
                {
                  label: 'Edit program',
                  onClick: () => {
                    setModal({
                      name: 'program',
                      data: { program: mapKeys(program, (value, key) => camelCase(key)) }
                    })
                  }
                },
                {
                  label: 'Stop program',
                  onClick: () => {
                    setModal({
                      name: 'warning',
                      data: {
                        endpoint: `/program/state/stop/${program.id}`,
                        title: 'Stop program',
                        content: `Do you want to stop the program? The remaining water and fertilizer will be removed entirely.`,
                        successFlashMessage: `${program.name} queued to stop successfully.`,
                        onComplete: () => {
                          setModal(null)
                        },
                        close: () => {
                          setModal(null)
                        },
                        onFailure: () => {
                          setModal(null)
                          setAlert({ type: 'error', content: 'We were unable to process your request. Please try again.' })
                        }
                      }
                    })
                  }
                },
                {
                  label: 'Add site',
                  onClick: () => {
                    setModal({
                      name: 'site'
                    })
                  }
                }
              ]}
            />
          )
        }
      })

      let tableData = {
        ...data.programs,
        data: programData
      }

      setTableData(tableData)
      setTableDataLoading(false)
    } catch (error) {
      console.log(error)
      Bugsnag.notify(error)
    }

  }, 1000), [setTableData, setTableDataLoading, navigate, setModal])

  useEffect(() => {
    getTableData({
      pageSize: 15,
      pageIndex: 0
    })
  }, [getTableData])

  const getTableDataStart = useCallback(params => {
    setTableDataLoading(true)
    getTableData(params)
  }, [getTableData, setTableDataLoading])

  const tableColumns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        width: '25%',
      },
      {
        Header: 'Description',
        accessor: 'description',
        width: '25%',
      },
      {
        Header: 'Schedule',
        accessor: 'schedule',
        width: '10%',
      },
      {
        Header: 'Start time',
        accessor: 'startTime',
        width: '10%',
      },
      {
        Header: 'Stop time',
        accessor: 'stopTime',
        width: '10%',
      },
      {
        Header: 'Location',
        accessor: 'location',
        width: '10%',
      },
      {
        Header: 'Site',
        accessor: 'site',
        width: '10%',
      },
      {
        Header: '',
        accessor: 'action',
        width: '5%',
      },
    ], []
  )

  const searchTypes = useMemo(() => {
    return [
      {
        value: 'program',
        label: 'Program',
      },
      {
        value: 'location',
        label: 'Location',
      },
      {
        value: 'site',
        label: 'Site',
      },
    ]
  }, [])

  const filterTabs = useMemo(() => {
    return [
      {
        title: 'View all',
        key: null,
      },
      {
        title: 'Alarms',
        key: 'alarms',
      },
      {
        title: 'Stopped',
        key: 'stopped',
      },
      {
        title: 'Running',
        key: 'running',
      },
    ]
  }, [])

  const getHeaderPills = useMemo(() => {
    let pills = []

    if (tableData && programStats) {
      pills.push({
        title: `${tableData.total} Total`,
        color: '#175CD3'
      })

      forEach(programStats, programStat => {
        pills.push({
          title: `${programStat.count} ${programStat.title}`,
          color: programStat.color
        })
      })
    }

    return pills
  }, [tableData, programStats])

  const getProgramStats = useCallback(async () => {
    try {
      let { data: programStats } = await axios.get(`/program/stats`)

      return reverse(sortBy(programStats, 'count'))
    } catch (error) {
      Bugsnag.notify(error)
    }
  }, [])

  useEffect(() => {
    (async () => {
      const programStats = await getProgramStats()

      setProgramStats(programStats)
    })()
  }, [setProgramStats, getProgramStats])

  return (
    <>
      {
        tableData &&
        <Table
          header="Programs"
          columns={tableColumns}
          data={tableData}
          loading={tableDataLoading}
          getTableData={getTableDataStart}
          filterSearch
          filterTabs={filterTabs}
          searchTypes={searchTypes}
          headerPills={getHeaderPills}
        />
      }

      <ButtonContainer>
        <Anchor
          style={{
            minWidth: '200px'
          }}
          onClick={() => {
            setModal({
              name: 'program'
            })
          }}
        >
          Add program
        </Anchor>
      </ButtonContainer>
    </>
  )
}

export default ProgramsAll
