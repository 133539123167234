import styled from 'styled-components'
import { NavLink, useNavigate } from 'react-router-dom'

import DropdownList from '@components/DropdownList'
import HeaderActions from '@components/HeaderActions'

const HeaderContainer = styled.header`
  display: flex;
  border-bottom: 1px solid #E4E7EC;
  height: 73px;
  justify-content: space-between;
  padding: 0 26px;
`

const Menu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    border-radius: 4px;
    color: var(--text-dark);
    display: inline-block;
    line-height: 40px;
    padding: 0 14px;
    text-decoration: none;
    transition: all 0.2s;

    &:hover {
      background-color: #F9FAFB;
      transition: all 0.2s;
    }

    &.is-active {
      background-color: rgb(21,112,239,0.08)
    }
  }
`

const Logo = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
`

const MobileLogo = styled.img`
  height: 32px;
`

const DesktopLogo = styled.img`
  height: 28px;
`

function Header () {
  const navigate = useNavigate()

  return (
    <HeaderContainer className="container-fluid">
      <NavLink to="/overview" className={({ isActive }) => (isActive ? 'is-active' : '')}>
        <Logo className="logo">
          <MobileLogo className="d-md-none" src="/images/future-ops-mobile-logo.svg" alt="FutureOps Logo" />
          <DesktopLogo className="d-none d-md-flex" src="/images/futureops-logo.svg" alt="FutureOps Logo" />
        </Logo>
      </NavLink>

      <Menu className="d-none d-lg-flex">
        <DropdownList
          hover
          noOpenOnClick
          icon={<NavLink to="/overview" className={({ isActive }) => (isActive ? 'is-active' : '')}>Overview</NavLink>}
          style={{
            right: '50%',
            transform: 'translateX(50%)',
            zIndex: 10010
          }}
          options={[
            {
              label: 'Summary',
              onClick: () => { navigate('/overview') },
              onMiddleClick: () => { window.open('/overview', '_blank') }
            },
            {
              label: 'Alarms',
              onClick: () => { navigate('/alarms') },
              onMiddleClick: () => { window.open('/alarms', '_blank') }
            },
            {
              label: 'Locations',
              onClick: () => { navigate('/locations') },
              onMiddleClick: () => { window.open('/locations', '_blank') }
            },
            {
              label: 'Sites',
              onClick: () => { navigate('/sites') },
              onMiddleClick: () => { window.open('/sites', '_blank') }
            },
            {
              label: 'Programs',
              onClick: () => { navigate('/programs') },
              onMiddleClick: () => { window.open('/programs', '_blank') }
            },
          ]}
        />
        <NavLink to="/dashboard" className={({ isActive }) => (isActive ? 'is-active' : '')}>Dashboard</NavLink>
        <NavLink to="/configuration" className={({ isActive }) => (isActive ? 'is-active' : '')}>Configuration</NavLink>
        <NavLink to="/reporting" className={({ isActive }) => (isActive ? 'is-active' : '')}>Reporting</NavLink>
        <NavLink to="/reporting-extra" className={({ isActive }) => (isActive ? 'is-active' : '')}>Reporting Extra</NavLink>
      </Menu>

      <HeaderActions />
    </HeaderContainer>
  )
}

export default Header
