import styled from 'styled-components'
import { rem } from 'polished'

const CardContainer = styled.div`
  border: 1px solid #E4E7EC;
  border-radius: 8px;
  box-shadow: ${props => props.active ? '0px 2px 5px rgba(16, 24, 40, 0.35), 0px 1px 2px rgba(16, 24, 40, 0.08)' : '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)'};
  margin-bottom: 20px;
  width: 100%;

  .title {
    margin-right: 10px;
  }
`

const TitleRow = styled.div`
  align-items: center;
  border-bottom: 1px solid #E4E7EC;
  color: var(--heading-color);
  display: flex;
  justify-content: ${props => props.hasPill ? 'flex-start' : 'space-between'};
  font-size: ${rem(18)};
  padding: 20px 24px;

  .more-actions {
    align-items: center;
    display: flex;
    position: relative;

    svg {
      &:hover {
        cursor: pointer;
      }
    }
  }
`
const Title = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  text-transform: capitalize;
  flex-basis: ${props => props.hasPill ? '50%' : 'auto'};
`

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
`

function Card(props) {
  return (
    <CardContainer active={props.active}>
      <TitleRow hasPill={props.pill}>
        <Title hasPill={props.pill}>
          {props.title}
        </Title>

        {props.pill &&
          <div>
            {props.pill}
          </div>
        }

        <div className='more-actions'>
          {props.actions}
        </div>
      </TitleRow>

      <CardContent>
        {props.children}
      </CardContent>
    </CardContainer>
  )
}

export default Card
