import * as yup from 'yup'
import Bugsnag from '@bugsnag/js'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { atom, useRecoilState } from 'recoil'
import { rem, darken } from 'polished'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import AuthAlert from '@components/AuthAlert'
import InputError from '@components/form/InputError'
import InputField from '@components/form/Input'
import axios from '@utilities/axios'
import { Button } from '@components/form/Buttons'

const Layout = styled.div`

`

const Icon = styled.div`
  align-items: center;
  background-color: #f5fbff;
  border-radius: 100%;
  color: var(--primary);
  display: flex;
  height: 56px;
  justify-content: center;
  width: 56px;
`

const ErrorContainter = styled.div`
  margin-bottom: 26px;
`

const Title = styled.h1`
  font-size: ${rem(30)};
  font-weight: 700;
  margin-top: 24px;
  text-align: center;
`

const SubText = styled.div`
  color: #667085;
  font-size: ${rem(16)};
  font-weight: 300;
  margin: 12px 0 32px;
  text-align: center;
`

const SmallerText = styled.div`
  color: #667085;
  font-size: ${rem(14)};
  font-weight: 300;
  text-align: center;

  button {
    background-color: transparent;
    border: none;
    color: var(--primary);

    &:hover {
      color: ${darken(0.2, '#1570ef')};
      cursor: pointer;
    }
  }
`

const Form = styled.form`
  margin: auto 0;
  width: 360px;
  max-width: 100%;

  button {
    margin-top: 24px;
  }
`

const BackText = styled.div`
  color: #667085;
  font-size: ${rem(14)};
  font-weight: 300;
  margin: 32px auto;
  text-align: center;

  a {
    text-decoration: none;
  }
`

const emailSentState = atom({
  key: 'emailSent',
  default: false
})

const schema = yup.object({
  email: yup.string().label('Email address').email().required(),
})

const authAlertState = atom({
  key: 'authAlert',
  default: {}
})

function ForgotPassword() {
  const [emailSent, setEmailSent] = useRecoilState(emailSentState)
  const [alert, setAlert] = useRecoilState(authAlertState)
  const { watch, register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  })
  const email = watch('email')

  const sendReset = async data => {
    setAlert(null)

    try {
      let { data: responseData } = await axios.post('/user/reset-password/send', {
        ...data
      })

      if (responseData?.success) {
        setEmailSent(true)
      }
    } catch (error) {
      setAlert({ message: 'Oops, something went wrong. Please try again.' })
      Bugsnag.notify(error)
    }
  }

  return (
    <Layout className='container-fluid'>
      <div className="row">
        <div className="d-flex flex-column align-items-center col-12">
          {!emailSent && (
            <Form onSubmit={handleSubmit(sendReset)} autoComplete="off">
              <div className="col-12 d-flex justify-content-center">
                <Icon>
                  <i className="fa-solid fa-key-skeleton"></i>
                </Icon>
              </div>

              <Title>Forgot password?</Title>
              <SubText>No worries, we'll send you reset instructions.</SubText>

              <ErrorContainter>
                <AuthAlert class={alert ? 'd-flex' : 'd-none'} message={alert?.message} />
              </ErrorContainter>

              <InputField
                label="Email"
                placeholder="Enter your email"
                type="email"
                className={errors.email && 'error'}
                {...register('email')}
              />
              {errors.email && <InputError message={errors.email.message} />}

              <Button
                style={{
                  width: '100%'
                }}
              >
                Reset password
              </Button>

              <BackText>
                <Link to="/user/login"><i className="fa-solid fa-arrow-left"></i> Back to log in</Link>
              </BackText>
            </Form>
          )}

          {emailSent && (
            <>
              <div className="col-12 d-flex justify-content-center">
                <Icon>
                  <i className="fa-solid fa-key-skeleton"></i>
                </Icon>
              </div>

              <Title>Check your email</Title>
              <SubText>We sent a password reset link to {email}</SubText>

              <SmallerText>Didn't receive the email? <button onClick={handleSubmit(sendReset)}>Click to resend</button></SmallerText>

              <BackText>
                <Link to="/user/login"><i className="fa-solid fa-arrow-left"></i> Back to log in</Link>
              </BackText>
            </>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default ForgotPassword