import styled from 'styled-components'
import { useCallback, useEffect } from 'react'
import { useRecoilState } from 'recoil'

import { rem } from 'polished'

const Content = styled.div`
  align-items: center;
  color: #ffffff;
  display: flex;
  font-size: ${rem(14)};
  padding: 16px;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1001;

  @media only screen and (min-width: 576px) {
    border-radius: 8px;
    max-width: 500px;
    right: 50px;
    top: 30px;
    transform: none;
  }

  svg {
    margin-right: 10px;
  }

  &.success {
    background-color: #12B76A;
  }

  &.error {
    background-color: var(--error-text);
  }

  &.info {
    background-color: var(--primary);
  }
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

function AlertBox(props) {
  const [alertData, setAlertData] = useRecoilState(props.alertState)

  useEffect(() => {
    let alertTimeout = setTimeout(() => {
      setAlertData(null)
    }, 7000)

    return () => {
      clearTimeout(alertTimeout)
    }
  }, [alertData, setAlertData])

  const iconClass = useCallback(() => {
    let icon = "fa-light fa-circle-check fa-2xl"

    switch (alertData.type) {
      case 'success':
        icon = "fa-light fa-circle-check fa-2xl"
        break;

      case 'error':
      case 'warning':
        icon = "fa-light fa-circle-exclamation fa-2xl"
        break;

      case 'info':
      default:
        icon = "fa-solid fa-circle-info fa-2xl"
        break;
    }

    return icon
  }, [alertData])

  if (alertData && alertData.type && alertData.content) {
    return (
      <div style={props.style}>
        <Content className={alertData.type}>
          <div className="d-flex flex-grow-1">
            <div className="col-2 d-flex align-items-center">
              <i className={iconClass()}></i>
            </div>

            <div className="col-9 d-flex align-items-center">
              { alertData.content }
            </div>

            <div className="col-1 d-flex align-items-center justify-content-end">
              <IconContainer onClick={() => { setAlertData(null) }}>
                <i className="fa-regular fa-xmark fa-xl"></i>
              </IconContainer>
            </div>
          </div>
        </Content>
      </div>
    )
  } else {
    return (
      <></>
    )
  }
}

export default AlertBox
