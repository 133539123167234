import axios from '@utilities/axios'
import styled from 'styled-components'
import { Outlet } from 'react-router-dom'
import { atom, useSetRecoilState } from 'recoil'
import { useEffect } from 'react'

import AlertBox from '@components/AlertBox'
import Header from '@components/layout/Header'
import MobileMenu from '@components/MobileMenu'
import Modals from '@components/modals/Modals'
import echo from '@utilities/echo'
import { useAuth } from '@utilities/auth'

const AppLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: space-between;
  position: relative;
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 73px);
  padding: 20px 0;
`

const alertState = atom({
  key: 'alert',
  default: {}
})

function App() {
  const auth = useAuth()
  const setAlert = useSetRecoilState(alertState)

  useEffect(async () => {
    await axios.get('/sanctum/csrf-cookie')

    if (auth?.user?.id) {
      echo.private(`user.${auth.user.id}`)
        .listen('MqttActionReply', data => {
          setAlert({ type: data.status || 'info', content: data.message })
        })
    }
  }, [auth])

  return (
    <AppLayout className="App">
      <MobileMenu />
      <Header />
      <AlertBox alertState={alertState} />

      <ContentContainer>
        <Outlet className="main-container" />
      </ContentContainer>

      <Modals />
    </AppLayout>
  )
}

export default App
