import * as yup from 'yup'
import Bugsnag from '@bugsnag/js'
import styled from 'styled-components'
import { useEffect, useCallback } from 'react'
import { atom, useRecoilState, useSetRecoilState } from 'recoil'
import { head, map } from 'lodash'
import { useForm, Controller } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'

import GooglePlacesAutocomplete from '@components/form/GooglePlacesAutocomplete'
import Input from '@components/form/Input'
import InputError from '@components/form/InputError'
import Modal from '@components/Modal'
import Select from '@components/form/Select'
import axios from '@utilities/axios'
import { Anchor, Button } from '@components/form/Buttons'
import { useAuth } from '@utilities/auth'

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 28px;
`

const LocationForm = styled.form`
  label {
    margin-top: 20px;
  }

  .buttons {
    margin-top: 40px;
  }
`
const allUsersState = atom({
  key: 'allUsers',
  default: []
})

const usersLoadingState = atom({
  key: 'usersLoading',
  default: true
})

const pageAlertState = atom({
  key: 'alert',
  default: {}
})

const isLoadingState = atom({
  key: 'isLoading',
  default: false
})

const schema = yup.object({
  name: yup.string().label('Name').required().min(3).max(50),
  description: yup.string().label('Description').required().min(3).max(250),
  address: yup.string().label('Address').required().min(3).max(250),
  // authorisedUserIds: yup.array().label('Authorised users').min(1, 'At least 1 user should be selected'),
})

function LocationModal(props) {
  const [allUsers, setAllUsers] = useRecoilState(allUsersState)
  const [isLoading, setIsLoading] = useRecoilState(isLoadingState)
  const [usersLoading, setUsersLoading] = useRecoilState(usersLoadingState)
  const navigate = useNavigate()
  const setAlert = useSetRecoilState(pageAlertState)
  const { reset, setValue, watch, control, register, handleSubmit, formState: { errors, isSubmitted } } = useForm({
    resolver: yupResolver(schema)
  })
  const auth = useAuth()
  const address = watch('address')

  // Manually register the address field
  // onChange is manually handled
  useEffect(() => {
    register('address', {
      value: props.data?.address,
    })
  }, [register, props])

  const getUsers = useCallback(async () => {
    try {
      let { data } = await axios.get('/users')

      if (data && data.success) {
        const users = map(data.users, (user) => {
          return { value: user.id, label: user.name }
        })

        setAllUsers(users)
      }
    } catch (error) {
      Bugsnag.notify(error)
      setAlert({ type: 'error', content: 'Failed to retrieve users.' })
    } finally {
      setUsersLoading(false)
    }
  }, [setAllUsers, setAlert, setUsersLoading])

  const fieldChange = useCallback((field, value) => {
    setValue(field, value, { shouldValidate: isSubmitted })
  }, [isSubmitted, setValue])

  useEffect(() => {
    getUsers()
  }, [getUsers])

  const onSubmit = async data => {
    setAlert(null)

    try {
      setIsLoading(true)

      const userIds = map(data.authorisedUserIds, user => {
        return user.value
      })

      delete data['authorisedUserIds']

      let endpoint
      if (props?.data) {
        endpoint = `/location/update/${props.data?.id}`
      } else {
        endpoint = '/location/create'
      }

      let { data: responseData } = await axios.post(`${endpoint}`, {
        ...data,
        authorised_user_ids: userIds,
        organisation_id: head(auth.user.organisations).id,
      })

      if (responseData.success) {
        setAlert({ type: 'success', content: `${responseData.location.name} has been successfully ${props?.data ? 'updated' : 'created'}.` })
        navigate(`/location/manage/${responseData.location.id}`)
        props.close()
      }
    } catch (error) {
      Bugsnag.notify(error)
      setAlert({ type: 'error', content: 'An error has occured and we were not able to save this location. Please try again.' })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      icon={<i className="fa-light fa-location-dot fa-2xl"></i>}
      title={props?.data ? `Edit Location ${props.data?.name}` : 'Define Location'}
      close={props.close}
      closeOnOutsideClick={props.closeOnOutsideClick}
    >
      <LocationForm onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Input
          name="name"
          label="Name"
          className={errors.name && 'error'}
          {...register('name', { value: props.data?.name })}
        />
        {errors.name && <InputError message={errors.name.message} />}

        <Input
          name="description"
          label="Description"
          className={errors.description && 'error'}
          {...register('description', { value: props.data?.description })}
        />
        {errors.description && <InputError message={errors.description.message} />}

        <GooglePlacesAutocomplete
          label="Address"
          name="address"
          value={address || ''}
          onChange={fieldChange}
          className={errors.address && 'error'}
        />
        {errors.address && <InputError message={errors.address.message} />}

        <Controller
          control={control}
          defaultValue={map(props.data?.users, user => {
            return { value: user.id, label: user.name }
          })}
          name="authorisedUserIds"
          render={({ field }) => (
            <Select
              {...field}
              isMulti={true}
              isSearchable={true}
              label="Authorized members"
              isLoading={usersLoading}
              menuPlacement="top"
              options={allUsers}
              hasError={!!errors.authorisedUserIds}
            />
          )}
        />
        {errors.authorisedUserIds && <InputError message={errors.authorisedUserIds.message} />}

        <ButtonGroup className='buttons'>
          <Anchor
            style={{
              width: 'calc(50% - 5px)'
            }}
            className='transparent'
            onClick={() => {
              setAlert(null)
              reset()
              props.close()
            }}
          >
            Close
          </Anchor>

          <Button
            style={{
              width: 'calc(50% - 5px)'
            }}
            disabled={isLoading ? true : false}
          >
            {isLoading ? <div className="primary-loader light"></div> : 'Save'}
          </Button>
        </ButtonGroup>
      </LocationForm>
    </Modal>
  )
}

export default LocationModal
