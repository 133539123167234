import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { RecoilRoot } from 'recoil'

import '@utilities/number-scroll'
import AlarmIndex from '@pages/alarm/AlarmsIndex'
import App from './App'
import Auth from './Auth'
import Configuration from '@pages/Configuration'
import DashboardAll from '@pages/DashboardAll'
import DashboardView from '@pages/DashboardView'
import ForgotPassword from '@pages/auth/ForgotPassword'
import FormValidationPoc from '@pages/FormValidationPoc'
import GlobalStyles from '@styles/global'
import KitchenSink from '@pages/KitchenSink'
import LoaderStyles from '@styles/loader'
import LocationIndex from '@pages/location/LocationIndex'
import LocationManage from '@pages/location/LocationManage'
import LocationView from '@pages/location/LocationView'
import Login from '@pages/auth/Login'
import Logout from '@pages/auth/Logout'
import NoMatch from '@pages/NoMatch'
import Overview from '@pages/Overview'
import Reporting from '@pages/Reporting'
import ReportingExtra from '@pages/ReportingExtra'
import ProgramIndex from '@pages/program/ProgramIndex'
import ProgramManage from '@pages/program/ProgramManage'
import Register from '@pages/auth/Register'
import RegisterCompany from '@pages/auth/RegisterCompany'
import ResetPassword from '@pages/auth/ResetPassword'
import SiteIndex from '@pages/site/SiteIndex'
import SiteManage from '@pages/site/SiteManage'
import { AuthProvider, RequireAuth } from '@utilities/auth'

import 'bootstrap/dist/css/bootstrap-grid.css'
import 'tippy.js/dist/tippy.css'

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
})

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <RecoilRoot>
        <GlobalStyles />
        <LoaderStyles />

        <AuthProvider>
          <BrowserRouter>
            <Routes>
              <Route
                exact
                path="/"
                element={<Navigate to="/configuration" />}
              />

              <Route path="/" element={<App />}>
                <Route
                  path="configuration"
                  element={
                    <RequireAuth>
                      <Configuration />
                    </RequireAuth>
                  }
                />

                <Route
                  path="overview"
                  element={
                    <RequireAuth>
                      <Overview />
                    </RequireAuth>
                  }
                />

                <Route
                  path="dashboard"
                  element={
                    <RequireAuth>
                      <DashboardAll />
                    </RequireAuth>
                  }
                />

                <Route
                  path="dashboard/view/:id"
                  element={
                    <RequireAuth>
                      <DashboardView />
                    </RequireAuth>
                  }
                />

                <Route
                  path="alarms"
                  element={
                    <RequireAuth>
                      <AlarmIndex />
                    </RequireAuth>
                  }
                />

                <Route
                  path="locations"
                  element={
                    <RequireAuth>
                      <LocationIndex />
                    </RequireAuth>
                  }
                />
                <Route
                  path="location/view/:id"
                  element={
                    <RequireAuth>
                      <LocationView />
                    </RequireAuth>
                  }
                />
                <Route
                  path="location/manage/:id"
                  element={
                    <RequireAuth>
                      <LocationManage />
                    </RequireAuth>
                  }
                />

                <Route
                  path="sites"
                  element={
                    <RequireAuth>
                      <SiteIndex />
                    </RequireAuth>
                  }
                />

                <Route
                  path="site/manage/:id"
                  element={
                    <RequireAuth>
                      <SiteManage />
                    </RequireAuth>
                  }
                />

                <Route
                  path="programs"
                  element={
                    <RequireAuth>
                      <ProgramIndex />
                    </RequireAuth>
                  }
                />
                <Route
                  path="program/manage/:id"
                  element={
                    <RequireAuth>
                      <ProgramManage />
                    </RequireAuth>
                  }
                />

                <Route
                  path="reporting"
                  element={
                    <RequireAuth>
                      <Reporting />
                    </RequireAuth>
                  }
                />

                <Route
                  path="reporting-extra"
                  element={
                    <RequireAuth>
                      <ReportingExtra />
                    </RequireAuth>
                  }
                />

                <Route path="*" element={<NoMatch />} />
              </Route>

              <Route path="/user/logout" element={<Logout />} />
              <Route path="/user" element={<Auth />}>
                <Route path="login" element={<Login />} />
                <Route path="register-company/:hash" element={<RegisterCompany />} />
                <Route path="register/:hash" element={<Register />} />
                <Route path="forgot-password" element={<ForgotPassword />} />
                <Route path="reset-password/:hash" element={<ResetPassword />} />
              </Route>

              <Route path="/branif" element={<App />} >
                <Route path="kitchen-sink" element={<KitchenSink />} />
                <Route path="form-validation-poc" element={<FormValidationPoc />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </AuthProvider>
      </RecoilRoot>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
)
