import styled from 'styled-components'
import { forwardRef } from 'react'
import { rem } from 'polished'

import { mediaBreakpointDown } from '@utilities/breakpoint'

const Input = styled.input`
  background: ${props => props.background || 'transparent'};
  border-radius: 8px;
  border: 1px solid var(--border-color);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  color: var(--text-dark);
  font-size: 16px;
  height: 44px;
  line-height: 1;
  max-width: 100%;
  padding: ${props => props.searchIcon ? "0 28px 0 40px" : "0 14px"};
  position: relative;
  width: ${props => props.width || '100%'};

  ${props => props.leadingSelect && `
    border-radius: 0 8px 8px 0;
    border-left: none;

    ${mediaBreakpointDown('sm', `
      border-left: 1px solid var(--border-color);
      border-radius: 0 0 8px 8px;
      border-top: none;
    `)}
  `}

  ${props => props.textCenter && `
    text-align: center;
  `}

  ${props => props.showArrows ? null : `
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type=number] {
      -moz-appearance: textfield;
    }
  `}

  &:focus {
    outline: 2px solid #2684FF;
  }

  ::placeholder {
    color: #667085;
    font-size: 16px;
    font-weight: 300;
  }

  &.error {
    border-color: #FDA29B;

    &:focus {
      outline-color: #FDA29B;
    }
  }
`

const Label = styled.label`
  color: var(--text-dark);
  display: flex;
  font-size: ${rem(14)};
  margin-bottom: 6px;
`

const InputWrapper = styled.div`
  position: relative;

  ${props => props.leadingSelect && `
    display: inline-flex;
  `}
`

const InputContainer = styled.div`
  position: relative;

  ${props => props.leadingSelect && `
    ${mediaBreakpointDown('sm', `
      width: 100%;
    `)}
  `}
`

const IconContainer = styled.span`
  align-items: center;
  top: 0;
  color: var(--text-light);
  justify-content: center;
  display: flex;
  height: 44px;
  position: absolute;
  width: 40px;
`

const InputField = forwardRef((props, ref) => {
  return (
    <InputWrapper {...props}>
      {props.label && (
        <Label htmlFor={props.name}>{props.label}</Label>
      )}

      <InputContainer {...props}>
        <Input {...props} ref={ref} />

        {props.searchIcon && (
          <IconContainer>
            <i className="fa-regular fa-magnifying-glass"></i>
          </IconContainer>
        )}
      </InputContainer>
    </InputWrapper>
  )
})

export default InputField
