import Bugsnag from '@bugsnag/js'
import OutsideClickHandler from 'react-outside-click-handler'
import styled from 'styled-components'
import { atom, useRecoilState } from 'recoil'
import { chain, cloneDeep, filter, findIndex, forEach, head, isEmpty, map } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import Alarms from '@components/dashboard/Alarms'
import Map from '@components/Map'
import Overview from '@components/dashboard/Overview'
import PageTitle from '@components/layout/helpers/PageTitle'
import Programs from '@components/dashboard/Programs'
import Pumps from '@components/dashboard/Pumps'
import SelectField from '@components/form/Select'
import Tabs from '@components/Tabs'
import axios from '@utilities/axios'
import echo from '@utilities/echo'
import { statusColor } from '@utilities/statusColor'

const DashboardMap = styled.div`
  display: block;
  height: ${props => props.fullHeight ? 'calc(100vh - 73px)' : '40vh'};
  margin-bottom: ${props => props.fullHeight ? '-20px' : '20px'};
  margin-top: -20px;
  position: relative;
  width: 100%;
`

const PageContainer = styled.div`
  display: block;
  max-height: 100vh;
  width: 100%;
`

const TabsContainer = styled.div`
  padding-bottom: 20px;
`

const SelectContainer = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 100;
`

const ShowSelect = styled.div`
  align-items: center;
  background: #ffffff;
  border-radius: 2px;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
  color: #5F5F5F;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  width: 40px;

  &:hover {
    color: #333333;
  }
`

const sitesState = atom({
  key: 'dashboardSites',
  default: {}
})

const siteState = atom({
  key: 'dashboardSite',
  default: {}
})

const selectedTabState = atom({
  key: `dashboardSelectedTab`,
  default: 'overview',
})

const siteLocationState = atom({
  key: 'siteLocation',
  default: {}
})

function Dashboard() {
  const [selectedTab, setSelectedTab] = useRecoilState(selectedTabState)
  const [showSelect, setShowSelect] = useState(false)
  const [site, setSite] = useRecoilState(siteState)
  const [siteLocation, setSiteLocation] = useRecoilState(siteLocationState)
  const [sites, setSites] = useRecoilState(sitesState)
  const navigate = useNavigate()
  const urlParams = useParams()

  const siteOptions = useMemo(() => {
    return chain(sites)
    .groupBy(site => {
      return site.location.id
    })
    .map(sitesByLocation => {
      const siteOptions = map(sitesByLocation, site => {
        return { label: site.name, value: site.id }
      })
      return {
        label: head(sitesByLocation).location.name,
        options: siteOptions
      }
    })
    .value()
  }, [sites])

  const getDisplayedProgram = useCallback(async (siteId) => {
    try {
      let { data } = await axios.get(`/program/current-or-previous?site_id=${siteId}`)

      if (data.success) {
        return data.program
      }
    } catch (error) {
      Bugsnag.notify(error)
    }

    return null
  }, [])

  useEffect(() => {
    (async () => {
      try {
        let { data } = await axios.get(`/site/query?pageSize=1000&with[]=location`)

        if (data && data.success) {
          const siteData = data.sites.data

          setSites(siteData)
        }
      } catch (error) {
        Bugsnag.notify(error)
      }
    })()
  }, [setSites])

  const markers = useMemo(() => {
    if (!isEmpty(site) && site.lat && site.long) {
      return [
        {
          id: `siteId#${site.id}`,
          position: {
            lat: parseFloat(site.lat),
            lng: parseFloat(site.long)
          },
          title: site.name,
          fillColor: site?.program_index ? statusColor(site.programs[site.program_index].operational_statuses).pathColor : statusColor(site.programs[0]?.operational_statuses).pathColor,
          draggable: false,
        }
      ]
    }

    return []
  }, [site])

  const getProgram = useCallback(async () => {
    const query = new URLSearchParams([
      ['with[]', 'location'],
      ['with[]', 'programs'],
      ['with[]', 'programs.programFunction'],
      ['with[]', 'programs.functionData'],
      ['with[]', 'programs.operationalStatuses'],
      ['with[]', 'programs.functionData.operationalStatuses'],
      ['with[]', 'programs.functionData.alarmTypes'],
    ])

    try {
      let { data } = await axios.get(`/site/view/${urlParams.id}?${query}`)

      if (data && data.success) {
        const displayedProgram = await getDisplayedProgram(data.site.id)

        const currentProgramIndex = displayedProgram ? findIndex(data.site.programs, program => {
          return program.id === displayedProgram.id
        }) : 0

        const siteData = {
          ...data.site,
          program_index: currentProgramIndex
        }

        setSite(siteData)

        if (!siteData.lat || !siteData.long) {
          const getCoordinates = async () => {
            let { data } = await axios.get(`/google/maps/search/text?query=${siteData.location.address}`)

            if (data.success) {
              setSiteLocation(data.result.geometry.location)
            }
          }
          getCoordinates()
        }
      }
    } catch (error) {
      Bugsnag.notify(error)
    }
  }, [urlParams.id, setSiteLocation, setSite, getDisplayedProgram])

  useEffect(() => {
    getProgram()
  }, [getProgram])

  const tabChange = useCallback(value => {
    setSelectedTab(value)
  }, [setSelectedTab])

  useEffect(() => {
    if (site.id) {
      echo.private(`site.${site.id}`)
        .listen('ProgramStart', data => {
          console.log('ProgramStart', data)

          const currentProgramIndex = data.program ? findIndex(site.programs, program => {
            return program.id === data.program.id
          }) : 0

          setSite({
            ...site,
            program_index: currentProgramIndex
          })
        })
    }

    forEach(site?.programs, program => {
      echo.private(`program.${program.id}`)
        .listen('ProgramStatsUpdated', data => {
          console.log('ProgramStatsUpdated', data)

          let siteData = cloneDeep(site)
          let updatedProgram = head(filter(siteData.programs, program => {
            return program.id === data.program.id
          }))

          map(updatedProgram.function_data, (functionData, index) => {
            functionData.stats = data.program.function_data[index].stats
            functionData.operational_statuses = data.program.function_data[index].operational_statuses
            functionData.alarm_types = data.program.function_data[index].alarm_types
          })
          updatedProgram.operational_statuses = data.program.operational_statuses
          updatedProgram.stats = data.program.stats

          setSite(siteData)
        })
    })

    return () => {
      if (site.id) {
        echo.private(`site.${site.id}`)
          .stopListening('ProgramStart')
      }

      forEach(site?.programs, program => {
        echo.private(`program.${program.id}`)
          .stopListening('ProgramStatsUpdated')
      })
    }
  }, [site, setSite])

  if (isEmpty(site)) {
    return <></>
  }

  return (
    <>
      <DashboardMap fullHeight={isEmpty(site.programs[site.program_index])}>
        {siteOptions && (
          <SelectContainer>
            {
              showSelect ? (
                <OutsideClickHandler onOutsideClick={() => { setShowSelect(false) }}>
                  <SelectField
                    isSearchable={true}
                    display="inline-block"
                    width="300px"
                    placeholder="Search"
                    options={siteOptions}
                    onChange={(option) => {
                      setShowSelect(false)
                      navigate(`/dashboard/view/${option.value}`)
                    }}
                  />
                </OutsideClickHandler>
              ) : (
                <ShowSelect onClick={() => { setShowSelect(true) }}>
                  <i className="fa-solid fa-magnifying-glass"></i>
                </ShowSelect>
              )
            }
          </SelectContainer>
        )}

        <Map center={siteLocation || []} markers={markers} square key={site.id} />
      </DashboardMap>
      {
        !isEmpty(site.programs[site.program_index]) && (
          <PageContainer className="container">
            <div className="row">
              <div className="col-12 col-lg-6">
                <PageTitle>
                  {`${site.name}: ${site.programs[site.program_index]?.name}`}
                </PageTitle>
              </div>

              <TabsContainer className="col-12 col-lg-6 d-flex align-items-center justify-content-end">
                <Tabs
                  currentlySelected={selectedTab}
                  default="overview"
                  tabs={[
                    {
                      title: 'Overview',
                      key: 'overview',
                    },
                    {
                      title: 'Pumps',
                      key: 'pumps',
                    },
                    {
                      title: 'Programs',
                      key: 'programs',
                    },
                    {
                      title: 'Alarms',
                      key: 'alarms',
                    },
                  ]}
                  onChange={tabChange}
                />
              </TabsContainer>
            </div>

            {selectedTab === 'overview' && (
              <Overview
                program={site?.program_index ? site.programs[site.program_index] : site.programs[0]}
                time={site?.program_index ? site.programs[site.program_index].stats?.fep_time : site.programs[0].stats?.fep_time}
                statusColor={site?.program_index ? statusColor(site.programs[site.program_index].operational_statuses) : statusColor(site.programs[0].operational_statuses)}
                setDashboardSelectedTab={setSelectedTab}
                updateDashboard={getProgram}
              />
            )}

            {selectedTab === 'pumps' && (
              <Pumps
                program={site?.program_index ? site.programs[site.program_index] : site.programs[0]}
                statusColor={site?.program_index ? statusColor(site.programs[site.program_index].operational_statuses) : statusColor(site.programs[0].operational_statuses)}
                updateDashboard={getProgram}
              />
            )}

            {selectedTab === 'programs' && (
              <Programs siteId={site.id} />
            )}

            {selectedTab === 'alarms' && (
              <Alarms programs={site.programs} />
            )}
          </PageContainer>
        )
      }
    </>
  )
}

export default Dashboard;
