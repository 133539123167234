import PageContainer from '@components/layout/helpers/PageContainer'
import PageTitle from '@components/layout/helpers/PageTitle'
import SitesAll from '@components/tables/SitesAll'

function SiteIndex() {
  return (
    <>
      <PageContainer contained>
        <PageTitle>
          All sites
        </PageTitle>

        <SitesAll />
      </PageContainer>
    </>
  )
}

export default SiteIndex
