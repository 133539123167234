import styled from 'styled-components'

const Styles = styled.span`
  padding: 5px 12px;
`

function ActionIcon() {
  return (
    <Styles>
      <i className="far fa-ellipsis-v"></i>
    </Styles>
  )
}

export default ActionIcon
