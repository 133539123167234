import Bugsnag from '@bugsnag/js'
import Skeleton from 'react-loading-skeleton'
import styled from 'styled-components'
import { atom, useRecoilState, useSetRecoilState } from 'recoil'
import { map, reverse, sortBy } from 'lodash'
import { rem } from 'polished'
import { useCallback, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { v4 as uuid } from 'uuid'

import AlarmsAll from '@components/tables/AlarmsAll'
import Card from '@components/Card'
import PageTitle from '@components/layout/helpers/PageTitle'
import Pill from '@components/pill/Pill'
import ProgramsAll from '@components/tables/ProgramsAll'
import SitesAll from '@components/tables/SitesAll'
import axios from '@utilities/axios'
import { useAuth } from '@utilities/auth'

const Container = styled.div`
  padding: 0 26px;
`

const Block = styled.div`
  display: flex;

  &:hover {
    cursor: pointer;
  }
`

const CardRow = styled.div`
  display: flex;
  font-size: ${rem(14)};
  justify-content: space-between;
  padding: 13px 24px;
`

const CardRowTitle = styled.div`
  align-items: center;
  color: var(--heading-color);
  display: flex;
  font-size: ${rem(14)};
  flex-basis: 50%;
`

const LoaderWrapper = styled.div`
  padding: 13px 24px;
  display: flex;

  .placeholder {
    flex-basis: 50%;
    padding: 4px 0;

    &:first-of-type, &:last-of-type {
      margin-right: 10px;
    }
  }
`

const CardRowContent = styled.div`
  flex-basis: 50%;
`

const TableContainer = styled.div`
  margin-top: 35px;
`

const alarmsState = atom({
  key: 'overviewAlarms',
  default: {}
})

const programsState = atom({
  key: 'overviewPrograms',
  default: {}
})

const sitesState = atom({
  key: 'overviewSites',
  default: {}
})

const tabState = atom({
  key: `selected-card-${uuid()}`,
  default: null
})

const pageAlertState = atom({
  key: 'alert',
  default: {}
})

function Overview() {
  const [overviewStats, setOverviewStats] = useState()
  const [selectedTab, setSelectedTab] = useRecoilState(tabState)
  const setAlarms = useSetRecoilState(alarmsState)
  const setPrograms = useSetRecoilState(programsState)
  const setSites = useSetRecoilState(sitesState)
  const setPageAlert = useSetRecoilState(pageAlertState)
  const firstLoad = useRef(true)
  const navigate = useNavigate()

  const auth = useAuth()

  const getProgramStats = useCallback(async () => {
    try {
      let { data: programStats } = await axios.get(`/program/stats`)

      return reverse(sortBy(programStats, 'count'))
    } catch (error) {
      Bugsnag.notify(error)
    }
  }, [])

  const getAlarmStats = useCallback(async () => {
    try {
      let { data: alarmStats } = await axios.get(`/alarm/stats`)

      return reverse(sortBy(alarmStats, 'alarms_count'))
    } catch (error) {
      Bugsnag.notify(error)
    }
  }, [])

  const getSiteStats = useCallback(async () => {
    try {
      let { data: siteStats } = await axios.get(`/site/stats`)

      return reverse(sortBy(siteStats, 'count'))
    } catch (error) {
      Bugsnag.notify(error)
    }
  }, [])

  const tabChange = useCallback(value => {
    setSelectedTab(value)
  }, [setSelectedTab])

  useEffect(() => {
    (async () => {
      const programStats = await getProgramStats()
      const alarmStats = await getAlarmStats()
      const siteStats = await getSiteStats()

      setOverviewStats({
        programStats,
        alarmStats,
        siteStats
      })
    })()
  }, [setOverviewStats, getProgramStats, getAlarmStats, getSiteStats])

  useEffect(() => {
    if (!firstLoad.current) {
      setSelectedTab(selectedTab)
    }

    if (firstLoad.current) {
      firstLoad.current = false
      setSelectedTab('programs')
    }
  }, [selectedTab, setSelectedTab])

  useEffect(() => {
    (async () => {
      try {
        let { data } = await axios.get(`/alarms`)

        if (data && data.success) {
          const alarmData = data.alarms

          setAlarms(alarmData)
        }
      } catch (error) {
        Bugsnag.notify(error)
      }
    })()
  }, [setAlarms])

  useEffect(() => {
    (async () => {
      try {
        let { data } = await axios.get(`/programs`)

        if (data && data.success) {
          const programData = data.programs

          setPrograms(programData)
        }
      } catch (error) {
        Bugsnag.notify(error)
      }
    })()
  }, [setPrograms])

  useEffect(() => {
    (async () => {
      try {
        let { data } = await axios.get(`/sites`)

        if (data && data.success) {
          const siteData = data.sites

          setSites(siteData)
        }

        if (!data?.sites.length) {
          navigate('/configuration')
          setPageAlert({ type: 'info', content: 'You need to configure your first site to see the overview page.' })
        }
      } catch (error) {
        Bugsnag.notify(error)
      }
    })()
  }, [setSites, setPageAlert, navigate])

  return (
    <Container className='container-fluid'>
      <div className="row">
        <div className="col-12">
          <PageTitle>
            Welcome {auth.user.name}
          </PageTitle>
        </div>
      </div>

      <div className="row">
        <Block className="col-12 col-lg-4" onClick={() => {tabChange('programs')}}>
          <Card
            title={`Programs`}
            active={selectedTab === 'programs'}
          >
            {
              !overviewStats?.programStats &&
              map(Array(3), (value, key) => {
                return <LoaderWrapper key={key}>
                  <div className="placeholder">
                    <Skeleton />
                  </div>

                  <div className="placeholder">
                    <Skeleton />
                  </div>
                </LoaderWrapper>
              })
            }

            {map(overviewStats?.programStats, (programStat, key) => {
              return (
                <CardRow key={key}>
                  <CardRowTitle>{programStat.title}</CardRowTitle>

                  <CardRowContent>
                    <Pill color={programStat.color}>{programStat.count}</Pill>
                  </CardRowContent>
                </CardRow>
              )
            })}
          </Card>
        </Block>

        <Block className="col-12 col-lg-4" onClick={() => {tabChange('alarms')}}>
          <Card
            title="Alarms"
            active={selectedTab === 'alarms'}
          >
            {
              !overviewStats?.alarmStats &&
              map(Array(8), (value, key) => {
                return <LoaderWrapper key={key}>
                  <div className="placeholder">
                    <Skeleton />
                  </div>

                  <div className="placeholder">
                    <Skeleton />
                  </div>
                </LoaderWrapper>
              })
            }

            {map(overviewStats?.alarmStats, (alarmStat, key) => {
              return (
                <CardRow key={key}>
                  <CardRowTitle>{alarmStat.title}</CardRowTitle>

                  <CardRowContent>
                    <Pill color={alarmStat.color}>{alarmStat.alarms_count}</Pill>
                  </CardRowContent>
                </CardRow>
              )
            })}
          </Card>
        </Block>

        <Block className="col-12 col-lg-4" onClick={() => {tabChange('sites')}}>
          <Card
            title="Sites"
            active={selectedTab === 'sites'}
          >
            {
              !overviewStats?.siteStats &&
              map(Array(3), (value, key) => {
                return <LoaderWrapper key={key}>
                  <div className="placeholder">
                    <Skeleton />
                  </div>

                  <div className="placeholder">
                    <Skeleton />
                  </div>
                </LoaderWrapper>
              })
            }

            {map(overviewStats?.siteStats, (siteStat, key) => {
              return (
                <CardRow key={key}>
                  <CardRowTitle>{siteStat.title}</CardRowTitle>

                  <CardRowContent>
                    <Pill color={siteStat.color}>{siteStat.count}</Pill>
                  </CardRowContent>
                </CardRow>
              )
            })}
          </Card>
        </Block>
      </div>

      <TableContainer>
        <div className="row">
          <div className="col-12">
            {selectedTab === 'programs' && (
              <ProgramsAll />
            )}

            {selectedTab === 'alarms' && (
              <AlarmsAll />
            )}

            {selectedTab === 'sites' && (
              <SitesAll />
            )}
          </div>
        </div>
      </TableContainer>
    </Container>
  )
}

export default Overview