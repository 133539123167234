import styled from 'styled-components'
import { rem } from 'polished'
import { map, orderBy } from 'lodash'

import { formattedDateTime } from '@utilities/dateTime'

const CommentsTable = styled.table`
  background: #EFF8FF;
  border-collapse: collapse;
  color: #667085;
  width: 100%;

  th {
    background: #D1E9FF;
    padding: 15px 20px;
    font-size: ${rem(14)};
    font-weight: 400;
    border-top: 2px solid #E4E7EC;
    border-bottom: 3px solid #E4E7EC;

    &.date, &.name {
      width: 20%;
    }

    &.comment {
      width: calc(60% - 80px);
    }

    &.action {
      width: 80px;
    }
  }
`

const EditComment = styled.span`
  color: #175CD3;
  font-weight: 500;
  cursor: pointer;
`

const AlarmCommentSection = (alarm, setModal, user) => {
  return (
    <CommentsTable>
      <thead>
        <tr>
          <th className='date'>
            Date
          </th>

          <th className='name'>
            Name
          </th>

          <th className='comment'>
            Comment
          </th>

          <th className='action'>
          </th>
        </tr>
      </thead>

      <tbody>
        {
          !alarm.comments.length &&
          <tr>
            <td colSpan={4}>
              No comments yet.
            </td>
          </tr>
        }

        {map(orderBy(alarm.comments, ['created_at'], ['desc']), comment => (
          <tr key={comment.id}>
            <td>
              {formattedDateTime(comment.created_at)}
            </td>

            <td>
              {comment.user.name}
            </td>

            <td>
              {comment.comment}
            </td>

            <td>
              {user && (
                comment.user.id === user.id &&
                (
                  <EditComment
                    onClick={() => {
                      setModal({
                        name: 'comment',
                        data: {
                          comment: comment,
                          alarmId: alarm.id
                        }
                      })
                    }}
                  >
                    Edit
                  </EditComment>
                )
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </CommentsTable>
  )
}

export default AlarmCommentSection;
