import moment from 'moment'
import styled from 'styled-components'
import { atom, useRecoilState } from 'recoil'
import { debounce, map } from 'lodash'
import { useEffect, useMemo, useCallback } from 'react'
import { v4 as uuid } from 'uuid'

import ActionIcon from '@components/ActionIcon'
import AlarmCommentSection from '@components/tables/AlarmsCommentSection'
import Bugsnag from '@bugsnag/js'
import CommentModal from '@components/modals/CommentModal'
import DropdownList from '@components/DropdownList'
import Pill from '@components/pill/Pill'
import Table from '@components/Table'
import axios from '@utilities/axios'
import { formattedDateTime } from '@utilities/dateTime'
import { useAuth } from '@utilities/auth'

const Dot = styled.div`
  background-color: ${props => props.color};
  border-radius: 100%;
  display: inline-block;
  margin-right: 5px;
  height: 6px;
  width: 6px;
`

const tableDataState = atom({
  key: 'alarmIndexData',
  default: null,
})

const tableDataLoadingState = atom({
  key: 'alarmIndexTableLoading',
  default: true,
})

const modalState = atom({
  key: 'modal',
  default: null,
})

const lastQueryState = atom({
  key: `lastQuery-${uuid()}`,
  default: {},
})

function AlarmsAll() {
  const [tableData, setTableData] = useRecoilState(tableDataState)
  const [tableDataLoading, setTableDataLoading] = useRecoilState(tableDataLoadingState)
  const [modal, setModal] = useRecoilState(modalState)
  const [lastQuery, setLastQuery] = useRecoilState(lastQueryState)
  const auth = useAuth()

  const getTableData = useMemo(() => debounce(async ({ pageIndex, pageSize, filters }) => {
    const currentQuery = { pageIndex, pageSize, filters }
    setLastQuery(currentQuery)

    const query = new URLSearchParams([
      ['page', pageIndex + 1],
      ['pageSize', pageSize],
      ['searchType', filters?.search?.type || ''],
      ['search', filters?.search?.value || ''],
      ['status', filters?.tab || ''],
      ['with[]', 'comments'],
      ['with[]', 'comments.user'],
      ['with[]', 'program'],
    ])

    try {
      const { data } = await axios.get(`/alarm/query?${query}`)

      let alarmData = map(data.alarms.data, alarm => {
        const status = () => {
          if (alarm.resolved_by) {
            return {
              title: 'Resolved',
              color: '#12B76A'
            }
          } else if (alarm.attended_by) {
            return {
              title: 'Attended',
              color: '#F79009'
            }
          } else {
            return {
              title: 'Unattended',
              color: '#F04438'
            }
          }
        }

        return {
          type: (
            <Pill color={(alarm.resolved_by) ? '#667085' : alarm.alarm_type.color}>
              <Dot color={(alarm.resolved_by) ? '#667085' : alarm.alarm_type.color} /> {alarm.alarm_type.title}
            </Pill>
          ),
          address: alarm.address || '',
          location: alarm.site.location.name || '',
          site: alarm.site.name || '',
          program: alarm.program?.name || '-',
          details: alarm.description,
          dateLogged: formattedDateTime(alarm.date_logged),
          attendedBy: (
          <>
            {alarm.attended_by ? alarm.attended_by.name : ''}
            <br/>
            {formattedDateTime(alarm.date_attended)}
          </>
          ),
          resolvedBy: (
            <>
              {alarm.resolved_by ? alarm.resolved_by.name : ''}
              <br/>
              {formattedDateTime(alarm.date_resolved)}
            </>
          ),
          status: (
            <Pill color={status().color}>
              {status().title}
            </Pill>
          ),
          action: (
            <DropdownList
              icon={<ActionIcon />}
              positionProgrammatically
              wrapperStyle={{
                position: 'static'
              }}
              style={{
                minWidth: '200px',
                transform: 'translate(calc(-100% + 25px), 25px)',
                textAlign: 'left'
              }}
              options={[
                {
                  label: 'Attend to',
                  onClick: () => {
                    setModal({
                      name: 'warning',
                      data: {
                        endpoint: `/alarm/update/${alarm.id}`,
                        title: 'Attend to alarm',
                        content: 'I am working on this alarm and want to update the status accordingly.',
                        successFlashMessage: 'Alarm updated successfully.',
                        onComplete: () => {
                          getTableDataStart(currentQuery)
                          setModal(null)
                        },
                        close: () => {
                          setModal(null)
                        },
                        postData: {
                          attended_user_id: auth.user.id,
                          date_attended: moment().format(),
                        }
                      }
                    })
                  }
                },
                {
                  label: 'Resolve',
                  onClick: () => {
                    setModal({
                      name: 'warning',
                      data: {
                        endpoint: `/alarm/update/${alarm.id}`,
                        title: 'Resolve alarm',
                        content: 'Do you want to update the alarm status to resolved?',
                        successFlashMessage: 'Alarm updated successfully.',
                        onComplete: () => {
                          getTableDataStart(currentQuery)
                          setModal(null)
                        },
                        close: () => {
                          setModal(null)
                        },
                        postData: {
                          resolved_user_id: auth.user.id,
                          date_resolved: moment().format(),
                          attended_user_id: alarm.attended_by?.id || auth.user.id,
                          date_attended: alarm.date_attended || moment().format(),
                        }
                      }
                    })
                  }
                },
                {
                  label: 'Add comment',
                  onClick: () => {
                    setModal({
                      name: 'comment',
                      data: {
                        alarmId: alarm.id
                      }
                    })
                  }
                }
              ]}
            />
          ),
          collapsibleContent: AlarmCommentSection(alarm, setModal, auth.user)
        }
      })

      let tableData = {
        ...data.alarms,
        data: alarmData
      }

      setTableData(tableData)
      setTableDataLoading(false)
    } catch (error) {
      Bugsnag.notify(error)
    }
  }, 1000), [
    auth,
    setLastQuery,
    setModal,
    setTableData,
    setTableDataLoading,
  ])

  useEffect(() => {
    getTableData({
      pageSize: 15,
      pageIndex: 0
    })
  }, [getTableData])

  const getTableDataStart = useCallback(params => {
    setTableDataLoading(true)
    getTableData(params)
  }, [getTableData, setTableDataLoading])

  const tableColumns = useMemo(
    () => [
      {
        Header: 'Alarm',
        accessor: 'type',
        width: '15%',
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: '10%',
      },
      {
        Header: 'Location',
        accessor: 'location',
        width: '10%',
      },
      {
        Header: 'Site',
        accessor: 'site',
        width: '10%',
      },
      {
        Header: 'Program',
        accessor: 'program',
        width: '15%',
      },
      {
        Header: 'Details',
        accessor: 'details',
        width: '19%',
      },
      {
        Header: 'Date logged',
        accessor: 'dateLogged',
        width: '15%',
      },
      {
        Header: 'Attended by',
        accessor: 'attendedBy',
        width: '15%',
      },
      {
        Header: 'Resolved by',
        accessor: 'resolvedBy',
        width: '15%',
      },
      {
        Header: '',
        accessor: 'action',
        width: '5%',
      },
    ], []
  )

  const searchTypes = useMemo(() => {
    return [
      {
        value: 'site',
        label: 'Site',
      },
      {
        value: 'location',
        label: 'Location',
      },
      {
        value: 'program',
        label: 'Program',
      }
    ]
  }, [])

  const filterTabs = useMemo(() => {
    return [
      {
        title: 'View all',
        key: null,
      },
      {
        title: 'Attended',
        key: 'attended',
      },
      {
        title: 'Resolved',
        key: 'resolved',
      },
      {
        title: 'Unattended',
        key: 'unattended',
      },
    ]
  }, [])

  return (
    <>
      {
        tableData &&
        <Table
          collapsibleContent
          columns={tableColumns}
          data={tableData}
          filterSearch
          filterTabs={filterTabs}
          getTableData={getTableDataStart}
          header="Alarms"
          loading={tableDataLoading}
          searchTypes={searchTypes}
          headerPills={[
            {
              title: `${tableData.total} total`,
              color: '#175CD3'
            }
          ]}
        />
      }

      {
        modal?.name === 'comment' &&
        <CommentModal
          data={modal?.data}
          close={() => {
            setModal(null)
          }}
          complete={() => {
            getTableDataStart(lastQuery)
            setModal(null)
          }}
        />
      }
    </>
  )
}

export default AlarmsAll
