import styled from 'styled-components'
import { rem } from 'polished'

const PageContainerElement = styled.div`
  font-size: ${rem(30)};
  font-weight: 500;
  margin-bottom: 20px;
`

function PageContainer(props) {
  return (
    <PageContainerElement {...props}>
        {props.children}
    </PageContainerElement>
  )
}

export default PageContainer
