import styled from 'styled-components'
import { isFunction, map } from 'lodash'
import { rem } from 'polished'

import Dropdown from '@components/Dropdown'

const Item = styled.div`
  background-color: white;
  color: var(--text-dark);
  cursor: pointer;
  font-size: ${rem(14)};
  padding: 10px 16px;
  user-select: none;
  white-space: nowrap;
  min-width: 200px;

  &:first-of-type {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  &:last-of-type {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &:hover {
    background-color: #ddebff;
  }
`

function DropdownList(props) {
  return (
    <Dropdown
      {...props}
      style={{
        right: 0,
        top: 'calc(100% + 10px)',
        ...props.style
      }}
    >
      {
        props.options &&
        map(props.options, item => {
          return (
            <Item
              key={item.label}
              onClick={() => {
                item.onClick()
              }}
              onMouseDown={e => {
                if(isFunction(item.onMiddleClick) && e.button === 1) {
                  item.onMiddleClick()
                }
              }}
            >
              {item.label}
            </Item>
          )
        })
      }
    </Dropdown>
  )
}

export default DropdownList
