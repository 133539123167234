import styled from 'styled-components'

const PillContent = styled.div`
  display: inline-block;
  margin-right: 10px;
  margin-top: -4px;
  margin-bottom: -4px;
`

function PillWrapper(props) {
  return (
    <PillContent>
        {props.children}
    </PillContent>
  )
}

export default PillWrapper
