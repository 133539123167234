import styled from 'styled-components'
import { atom, useRecoilValue } from 'recoil'
import { isFunction } from 'lodash'

import Modal from '@components/Modal'
import { Anchor, Button } from '@components/form/Buttons'

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 28px;
`

const isLoadingState = atom({
  key: 'isLoading',
  default: false
})

function ConfirmPromptModal(props) {
  const isLoading = useRecoilValue(isLoadingState)

  return (
    <Modal
      icon={<i className="fa-regular fa-circle-check fa-2xl"></i>}
      title={props.data.title}
      close={props.close}
      closeOnOutsideClick={props.closeOnOutsideClick}
      modalWidth="544px"
    >
      {props.data?.content}

      <ButtonGroup>
        <Anchor
          style={{
            width: 'calc(50% - 5px)'
          }}
          className="transparent"
          onClick={() => {
            props.close()
          }}
        >
          Close
        </Anchor>

        <Button
          style={{
            width: 'calc(50% - 5px)'
          }}
          onClick={() => {
            if (isFunction(props.data?.onConfirm)) {
              props.data.onConfirm()
            }
          }}
          disabled={isLoading ? true : false}
        >
          {isLoading ? <div className="primary-loader light"></div> : 'Save'}
        </Button>
      </ButtonGroup>
    </Modal>
  )
}

export default ConfirmPromptModal
