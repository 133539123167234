import * as yup from 'yup'
import { isEmpty, isObject } from "lodash"

// Add Methods
yup.addMethod(yup.mixed, 'populatedObject', function(message) {
  return this.test({
    name: 'populatedObject',
    message: message,
    test: (value, schema) => {
      if (isObject(value) && !isEmpty(value)) {
        return true
      }

      if (!message) {
        let name = schema?.schema?.spec?.label || schema.path

        return schema.createError({ message: `${name} is a required field` })
      }

      return false
    }
  })
})