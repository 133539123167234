import PageContainer from '@components/layout/helpers/PageContainer'
import PageTitle from '@components/layout/helpers/PageTitle'
import ProgramsAll from '@components/tables/ProgramsAll'

function ProgramIndex() {
  return (
    <PageContainer contained>
      <PageTitle>
        All programs
      </PageTitle>

      <ProgramsAll />
    </PageContainer>
  )
}

export default ProgramIndex
