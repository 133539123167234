import styled from 'styled-components'
import { atom, useRecoilState } from 'recoil'
import { useMemo, useCallback } from 'react'
import { debounce } from 'lodash'

import Input from '@components/form/Input'
import Map from '@components/Map'
import Select from '@components/form/Select'
import Table from '@components/Table'
import axios from '@utilities/axios'
import { useEffect } from 'react'

const Wrapper = styled.div`
  max-width: 800px;
  width: 100%;
  margin: 20px auto;
`

const Container = styled.div`
  margin: 20px 0 50px;
  max-width: ${props => props.large ? '1200px' : '400px'};
  padding: 0 15px;
  width: 100%;
`

const inputState = atom({
  key: 'testInput',
  default: [],
})

const optionsState = atom({
  key: 'testOptions',
  default: [],
})

const loadingState = atom({
  key: 'testLoading',
  default: true,
})

const tableDataState = atom({
  key: 'testTableData',
  default: null,
})

const tableDataLoadingState = atom({
  key: 'testTableDataLoadingData',
  default: true,
})

function KitchenSink() {
  const [input, setInput] = useRecoilState(inputState)
  const [loading, setLoading] = useRecoilState(loadingState)
  const [options, setOptions] = useRecoilState(optionsState)
  const [tableData, setTableData] = useRecoilState(tableDataState)
  const [tableDataLoading, setTableDataLoading] = useRecoilState(tableDataLoadingState)

  const getTableData = useMemo(() => debounce(async ({ pageIndex, pageSize, filters }) => {
    const query = new URLSearchParams([
      ['page', pageIndex + 1],
      ['pageSize', pageSize],
      ['search', filters?.search?.value || ''],
      ['searchType', filters?.search?.type || ''],
      ['tab', filters?.tab || ''],
    ])

    const { data } = await axios.get(`/location/query?${query}`)

    setTableData(data.locations)
    setTableDataLoading(false)
  }, 1000), [setTableData, setTableDataLoading])

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)

      setOptions([
        { value: 'blueberry', label: 'Blueberry' },
        { value: 'cherry', label: 'Cherry' },
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'peanutButter', label: 'Peanut Butter' },
        { value: 'redVelvet', label: 'Red Velvet' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' },
      ])
    }, 4000)

    getTableData({
      pageIndex: 0,
      pageSize: 15
    })
  }, [getTableData, setOptions, setLoading])

  const tabSelected = value => {
    console.log(value)
  }

  const setValue = event => {
    const { value } = event.target

    setInput(value)
  }

  const markers = useMemo(() => {
    return [
      {
        id: 'siteId#1',
        position: {
          lat: -33.918861,
          lng: 18.423300
        },
        title: 'Site Name #1',
        fillColor: 'green',
        draggable: true,
        dragEnd: (event, id) => {
          console.log(event)
          console.log(id)
        },
        clickHandler: (event, id) => {
          console.log(event)
          console.log(id)
        }
      },
      {
        id: 'siteId#2',
        position: {
          lat: -34.918861,
          lng: 18.423300
        },
        title: 'Site Name #2',
        fillColor: 'red'
      },
    ]
  }, [])

  const tableColumns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        width: '25%',
      },
      {
        Header: 'Address',
        accessor: 'address',
        width: '50%',
      },
      {
        Header: 'Description',
        accessor: 'description',
        width: '25%',
      },
    ], []
  )

  const getTableDataStart = useCallback(params => {
    setTableDataLoading(true)
    getTableData(params)
  }, [getTableData, setTableDataLoading])

  return (
    <Wrapper>
      <Container large>
        <h2>Table</h2>

        {
          tableData &&
          <Table
            header="Locations"
            columns={tableColumns}
            data={tableData}
            loading={tableDataLoading}
            getTableData={getTableDataStart}
            filterSearch
            // topSearch
            searchTypes={[
              {
                value: 'location',
                label: 'Location',
              },
              {
                value: 'site',
                label: 'Site',
              }
            ]}
            filterTabs={[
              {
                title: 'View all',
                key: null,
                onClick: tabSelected
              },
              {
                title: 'Active',
                key: 'active',
                onClick: tabSelected
              },
              {
                title: 'Inactive',
                key: 'inactive',
                onClick: tabSelected
              },
            ]}
            headerPills={[
              {
                title: 'Active',
                color: 'green'
              },
              {
                title: 'Failed',
                color: 'red'
              }
            ]}
          />
        }
      </Container>

      <Container large>
        <h2>Map</h2>

        <Map
          markers={markers}
        />
      </Container>

      <Container>
        <h2>Inputs</h2>

        <Select
          options={options}
          loading={loading}
          isMulti={false}
          isSearchable={true}
        />

        <Input
          value={input}
          onChange={setValue}
        />
      </Container>
    </Wrapper>
  )
}

export default KitchenSink
