import '@utilities/yup'
import * as yup from 'yup'
import Bugsnag from '@bugsnag/js'
import DayPicker from '@components/form/DayPicker'
import DropdownList from '@components/DropdownList'
import Input from '@components/form/Input'
import InputError from '@components/form/InputError'
import Modal from '@components/Modal'
import Select from '@components/form/Select'
import axios from '@utilities/axios'
import styled from 'styled-components'
import { Anchor, Button } from '@components/form/Buttons'
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { camelCase, filter, head, isEmpty, isFunction, map, mapKeys, padStart, split, toNumber } from 'lodash'
import { useCallback, useEffect, useRef } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 28px;
`

const ButtonGroup = styled.div`
  display: flex;
  width: calc(50% - 5px);
`

const Label = styled.div`
  color: var(--text-dark);
  display: flex;
  font-size: 14px;
  margin-bottom: 6px;
  margin-top: 20px;
`

const GroupedInputs = styled.div`
  align-items: center;
  display: flex;

  input {
    margin-right: 20px;
  }
`

const TimeSeperator = styled.div`
  margin: 0 10px 0 -10px;
`

const Form = styled.form`
  label {
    margin-top: 20px;
  }

  .buttons {
    margin-top: 40px;
  }
`

const functionState = atom({
  key: 'functionOptions',
  default: {}
})

const functionsLoadingState = atom({
  key: 'functionsLoading',
  default: true
})

const siteOptionsState = atom({
  key: 'siteOptions',
  default: null
})

const siteState = atom({
  key: 'sites',
  default: {}
})

const sitesLoadingState = atom({
  key: 'sitesLoading',
  default: true
})

const pageAlertState = atom({
  key: 'alert',
  default: {}
})

const rtuProgramNumberState = atom({
  key: 'rtuProgramNumbers',
  default: []
})

const isLoadingState = atom({
  key: 'isLoading',
  default: false
})

const minBiggerThanMax = function () {
  if (this.parent.flowMeterMin < this.parent.flowMeterMax) {
    return true
  }

  return false
}

const schema = yup.object({
  siteId: yup.mixed().label('Site').populatedObject(),
  programFunction: yup.mixed().label('Function').populatedObject(),
  name: yup.string().label('Name').required().min(3).max(50),
  description: yup.string().label('Description').required().min(3).max(250),
  rtuProgramNumber: yup.mixed().label('RTU Program Number').populatedObject(),
  operationalStartTimeHours: yup.number().label('Start time hours').required().min(0).max(23),
  operationalStartTimeMinutes: yup.number().label('Start time minutes').required().min(0).max(59),
  flowMeterMin: yup.number().label('Mainline flow meter min').nullable().required().min(0).transform(value => value === toNumber(value) ? value : null).test(
    'minLargerThanMax',
    'Flow meter min must be smaller than flow meter max',
    minBiggerThanMax
  ),
  flowMeterMax: yup.number().label('Mainline flow meter max').nullable().required().min(0).transform(value => value === toNumber(value) ? value : null),
  flowMeterResolution: yup.number().label('Mainline flow resolution').nullable().required().positive().transform(value => value === toNumber(value) ? value : null),
})

function ProgramModal(props) {
  const [functions, setFunctions] = useRecoilState(functionState)
  const [functionsLoading, setFunctionsLoading] = useRecoilState(functionsLoadingState)
  const [siteOptions, setSiteOptions] = useRecoilState(siteOptionsState)
  const [sitesLoading, setSitesLoading] = useRecoilState(sitesLoadingState)
  const [isLoading, setIsLoading] = useRecoilState(isLoadingState)
  const [rtuProgramNumberOptions, setRtuProgramNumberOptions] = useRecoilState(rtuProgramNumberState)
  const rtuProgramNumberFirstLoad = useRef(true)
  const firstLoad = useRef(true)
  const form = useRef(null)
  const navigate = useNavigate()
  const setAlert = useSetRecoilState(pageAlertState)
  const sites = useRecoilValue(siteState)
  const { reset, watch, setValue, control, register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  })
  const operationalDays = watch('operationalDays')

  console.log(errors)

  useEffect(() => {

  }, [register, props])

  useEffect(() => {
    (async () => {
      try {
        const { data } = await axios.get(`/functions`)

        const functionOptions = map(data.functions, (programFunction) => {
          return { value: programFunction.id, label: programFunction.name }
        })

        if (functionOptions.length === 1) {
          setValue('programFunction', head(functionOptions))
        }

        setFunctionsLoading(false)
        setFunctions(functionOptions)
      } catch (error) {
        Bugsnag.notify(error)
        setAlert({ type: 'error', content: 'Failed to retrieve program functions.' })
      }
    })()
  }, [setFunctionsLoading, setFunctions, setAlert, setValue])

  useEffect(() => {
    const getSites = async () => {
      try {
        const { data } = await axios.get(`/sites`)

        if (data && data.sites) {
          const siteOptions = map(data.sites, (site) => {
            return { value: site.id, label: site.name }
          })

          setSiteOptions(siteOptions)
          setSitesLoading(false)
        }
      } catch (error) {
        Bugsnag.notify(error)
        setAlert({ type: 'error', content: 'Failed to retrieve sites.' })
      }
    }

    getSites()
  }, [setSitesLoading, setSiteOptions, sites, setAlert])

  const getRtuProgramNumbers = useCallback(async (e) => {
    rtuProgramNumberFirstLoad.current = false

    if (e.value) {
      try {
        const  { data } = await axios.get(`/program/available-rtu-program-numbers?site_id=${e.value}`)

        if (data?.success) {
          const options = map(data.programNumbers, programNumber => {
            return { label: programNumber, value: programNumber }
          })

          setRtuProgramNumberOptions(options)
        }
      } catch (error) {
        Bugsnag.notify(error)
      }
    }
  }, [setRtuProgramNumberOptions])

  useEffect(() => {
    if (isEmpty(rtuProgramNumberOptions) && rtuProgramNumberFirstLoad.current) {
      getRtuProgramNumbers({ value: props.data?.program?.siteId })
    }
  }, [rtuProgramNumberOptions, rtuProgramNumberFirstLoad, getRtuProgramNumbers, props])

  useEffect(() => {
    if (props.data?.program && firstLoad.current) {
      firstLoad.current = false

      const program = mapKeys(props.data.program, (value, key) => camelCase(key))
      const startTime = program.operationalStartTime ? split(program.operationalStartTime, ':') : split(program.operational_start_time, ':')

      // Manually register operational days schedule
      register('operationalDays', {
        value: program.operationalDays,
      })

      setValue('operationalStartTimeHours', startTime[0])
      setValue('operationalStartTimeMinutes', startTime[1])
    }
  }, [
    functions,
    reset,
    setValue,
    register,
    siteOptions,
    props.data?.program,
    operationalDays
  ])

  useEffect(() => {
    return () => {
      firstLoad.current = true
    }
  }, [])

  useEffect(() => {
    if (props.data?.siteId) {
      getRtuProgramNumbers({value: props.data?.siteId})
    }
  }, [props, setValue, getRtuProgramNumbers])

  const scheduleChange = schedule => {
    setValue('operationalDays', schedule)
  }

  const onSubmit = async (data, afterSave = null) => {
    setAlert(null)
    setIsLoading(true)

    try {
      let { data: responseData } = await axios.post(`/program/${props.data?.program ? `update/${props.data.program.id}`: 'create'} `, {
        site_id: data.siteId.value,
        program_function_id: data.programFunction.value,
        name: data.name,
        description: data.description,
        rtu_program_number: data.rtuProgramNumber?.value,
        operational_days: data.operationalDays,
        operational_start_time: `${padStart(data.operationalStartTimeHours, 2, 0)}:${padStart(data.operationalStartTimeMinutes, 2, 0)}:00`,
        operational_stop_time: `00:00:00`,
        flow_meter_min: data.flowMeterMin,
        flow_meter_max: data.flowMeterMax,
        flow_meter_resolution: data.flowMeterResolution,
      })

      if (isFunction(afterSave)) {
        await afterSave()
      }

      if (responseData.success) {
        if (isFunction(props.data?.onSave)) {
          props.data.onSave()
        } else {
          navigate(`/program/manage/${responseData.program.id}`)
        }

        setAlert({ type: 'success', content: `${responseData.program.name} has been successfully ${props.data?.program ? 'updated' : 'created'}.` })
        props.close()
      }
    } catch (error) {
      setAlert({ type: 'error', content: 'An error has occured and we were not able to save this program. Please try again.' })
      Bugsnag.notify(error)
    } finally {
      setIsLoading(false)
    }
  };

  return (
    <Modal
      icon={<i className="fa-light fa-sliders-up fa-2xl"></i>}
      iconFill="true"
      title={props.data?.site ? `${props.data?.program ? 'Edit': 'Create'} a program for ${props.data?.site.name}` : `${props.data?.program ? 'Edit': 'Create'} program`}
      close={props.close}
      closeOnOutsideClick={props.closeOnOutsideClick}
    >
      <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off" ref={form}>
        {
          siteOptions &&
          <>
            <Controller
              control={control}
              defaultValue={head(filter(siteOptions, ['value', props.data?.siteId || props.data?.program?.siteId])) || ''}
              name="siteId"
              render={({ field }) => (
                <Select
                  {...field}
                  isMulti={false}
                  isSearchable={true}
                  label="Site"
                  isLoading={sitesLoading}
                  options={siteOptions}
                  placeholder="Search"
                  onChange={(e) => {
                    getRtuProgramNumbers(e)
                    setValue('siteId', { value: e.value, label: e.label })
                  }}
                  hasError={!!errors.siteId}
                />
              )}
            />
            {errors.siteId && <InputError message={errors.siteId.message} />}
          </>
        }

        {
          functions.length > 1 &&
          <>
            <Controller
              control={control}
              defaultValue={head(filter(functions, ['value', props.data?.program?.programFunction]))}
              name="programFunction"
              render={({ field }) => (
                <Select
                  {...field}
                  placeholder="Select Function"
                  isMulti={false}
                  isSearchable={true}
                  label="Function"
                  options={functions}
                  loading={functionsLoading}
                  hasError={!!errors.programFunction}
                />
              )}
            />
            {errors.programFunction && <InputError message={errors.programFunction.message} />}
          </>
        }

        <Input
          label="Name"
          type="text"
          className={errors.name && 'error'}
          {...register('name', { value: props.data?.program?.name })}
        />
        {errors.name && <InputError message={errors.name.message} />}

        <Input
          label="Description"
          type="text"
          className={errors.description && 'error'}
          {...register('description', { value: props.data?.program?.description })}
        />
        {errors.description && <InputError message={errors.description.message} />}

        <Controller
          control={control}
          defaultValue={
            props.data?.program?.rtuProgramNumber ?
            { label: props.data?.program?.rtuProgramNumber, value: props.data?.program?.rtuProgramNumber } : ''
          }
          name="rtuProgramNumber"
          render={({ field }) => (
            <Select
              {...field}
              isMulti={false}
              isSearchable={true}
              label="RTU program number"
              options={rtuProgramNumberOptions}
              hasError={!!errors.rtuProgramNumber}
              noOptionsMessage={() => "Select a site to see available RTU program numbers"}
            />
          )}
        />
        {errors.rtuProgramNumber && <InputError message={errors.rtuProgramNumber.message} />}

        <div className="row">
          <div className="col-12 col-md-6">
            <Input
              label="Mainline flow meter min"
              type="number"
              className={errors.flowMeterMin && 'error'}
              {...register('flowMeterMin', { value: props.data?.program?.flowMeterMin })}
            />
            {errors.flowMeterMin && <InputError message={errors.flowMeterMin.message} />}
          </div>

          <div className="col-12 col-md-6">
            <Input
              label="Mainline flow meter max"
              type="number"
              className={errors.flowMeterMax && 'error'}
              {...register('flowMeterMax', { value: props.data?.program?.flowMeterMax })}
            />
            {errors.flowMeterMax && <InputError message={errors.flowMeterMax.message} />}
          </div>
        </div>

        <Input
          label="Mainline flow resolution (gal per pulse)"
          type="number"
          step="0.0001"
          className={errors.flowMeterResolution && 'error'}
          {...register('flowMeterResolution', { value: props.data?.program?.flowMeterResolution })}
        />
        {errors.flowMeterResolution && <InputError message={errors.flowMeterResolution.message} />}

        <DayPicker
          name="schedule"
          label="Schedule"
          placeholder="Select fertigation days"
          onChange={scheduleChange}
          rules={{ required: true }}
          options={functions}
          indexCount="4"
          initialSchedule={props.data?.program?.operationalDays || props.data?.program?.operational_days || null}
        />

        <div className="row">
          <div className="col-12 col-md-6 col-lg-4">
            <Label>Start time</Label>

            <GroupedInputs>
              <Input
                placeholder="hh"
                maxlength="2"
                type="number"
                width="60px"
                textCenter
                className={errors.operationalStartTimeHours && 'error'}
                {...register('operationalStartTimeHours', {
                  onChange: (e) => {

                  }
                })}
              />

              <TimeSeperator>
                :
              </TimeSeperator>

              <Input
                placeholder="mm"
                maxlength="2"
                type="number"
                width="60px"
                textCenter
                className={errors.operationalStartTimeMinutes && 'error'}
                {...register('operationalStartTimeMinutes', {
                  onChange: (e) => {

                  }
                })}
              />
            </GroupedInputs>

            {
              (
                (
                  errors.operationalStartTimeHours?.type === 'min' ||
                  errors.operationalStartTimeHours?.type === 'max' ||
                  errors.operationalStartTimeMinutes?.type === 'min' ||
                  errors.operationalStartTimeMinutes?.type === 'max'
                ) &&
                <InputError message="Start time should be between 00:00 and 23:59" />
              ) ||
              (
                (errors.operationalStartTimeHours || errors.operationalStartTimeMinutes) &&
                <InputError message="Start time is a required field" />
              )
            }
          </div>
        </div>

        <ButtonWrapper className='buttons'>
          <Anchor
            style={{
              width: 'calc(50% - 5px)'
            }}
            className='transparent'
            onClick={() => {
              setAlert(null)
              reset()
              props.close()
            }}
          >
            Close
          </Anchor>

          <ButtonGroup>
            <Button
              disabled={isLoading ? true : false}
              style={{
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                flexGrow: 1
              }}
            >
              {isLoading ? <div className="primary-loader light"></div> : 'Save'}
            </Button>
            <DropdownList
              disabled={isLoading ? true : false}
              wrapperStyle={{
                display: 'flex'
              }}
              style={{
                top: '-100%',
              }}
              icon={
                <Anchor
                  disabled={isLoading ? true : false}
                  style={{
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    borderLeft: '1px solid rgba(255, 255, 255, 0.4)'
                  }}
                >
                  <i className="fa-solid fa-caret-down"></i>
                </Anchor>
              }
              options={[
                {
                  label: 'Save & Sync',
                  onClick: () => {
                    let afterSave = async () => {
                      await axios.post(`/program/sync/${props.data.program.id}`)
                    }

                    handleSubmit(data => onSubmit(data, afterSave))()
                  }
                }
              ]}
              list={true}
            />
          </ButtonGroup>
        </ButtonWrapper>
      </Form>
    </Modal>
  )
}

export default ProgramModal
