import ActionIcon from '@components/ActionIcon'
import Bugsnag from '@bugsnag/js'
import DropdownList from '@components/DropdownList'
import InputField from '@components/form/Input'
import Map from '@components/Map'
import axios from '@utilities/axios'
import styled from 'styled-components'
import { Anchor } from '@components/form/Buttons'
import { atom, useRecoilState, useSetRecoilState } from 'recoil'
import { isEmpty, isUndefined, split, toLower, toNumber, upperFirst } from 'lodash'
import { rem } from 'polished'
import { useEffect, useCallback, useMemo } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'

const PageContainer = styled.div`
  padding: 0 26px;
  width: 100%;
`

const PageTitle = styled.div`
  color: var(--heading-color);
  font-size: ${rem(30)};
  margin-bottom: 20px;
`

const DetailContainer = styled.div`
  .button-container {
    display: flex;
    justify-content: flex-end;
    margin: 20px 0;
  }
`

const Card = styled.div`
  border: 1px solid #E4E7EC;
  border-radius: 8px;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
  padding-bottom: 30px;
  width: 100%;
`

const CardTitle = styled.div`
  align-items: center;
  border-bottom: 1px solid #E4E7EC;
  color: var(--heading-color);
  display: flex;
  justify-content: space-between;
  font-size: ${rem(18)};
  padding: 20px 24px;

  .more-actions {
    align-items: center;
    display: flex;
    position: relative;

    svg {
      &:hover {
        cursor: pointer;
      }
    }
  }
`

const CardRow = styled.div`
  display: flex;
  font-size: ${rem(14)};
  justify-content: space-between;
  padding: 13px 24px;
`

const CardRowTitle = styled.div`
  align-items: center;
  color: var(--heading-color);
  display: flex;
  font-size: ${rem(14)};
  flex-basis: 50%;
`

const CardRowContent = styled.div`
  color: var(--text-light);
  display: flex;
  font-size: ${rem(14)};
  flex-basis: 50%;
`

const SetPinBlock = styled.div`
  align-items: center;
  background-color: #f5fbff;
  color: var(--text-light);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px 12px 0;
  border-radius: 8px;
  margin: 20px 0;

  .title {
    color: var(--primary);
    font-size: ${rem(18)};
  }

  .content {
    margin: 20px 0;
    text-align: center;
  }
`

const SiteName = styled.div`
  text-transform: capitalize;
`

const MapContainer = styled.div`
  display: flex;
  height: calc(100vh - 169px);
  justify-content: center;
`

const MarkerForm = styled.div`
  width: 100%;
`

const MarkerFields = styled.div`
  display: flex;
  justify-content: space-around;

  .field-wrapper {
    margin-bottom: 12px;
  }
`

const siteState = atom({
  key: 'siteData',
  default: {}
})

const siteLocationState = atom({
  key: 'siteLocation',
  default: {}
})

const alertState = atom({
  key: 'alert',
  default: {}
})

const gpsCoordinatesState = atom({
  key: 'gpsCoordinates',
  default: ''
})

const showMapMarkerFormState = atom({
  key: 'showMapMarkerForm',
  default: false
})

const modalState = atom({
  key: 'modal',
  default: null,
})

const isLoadingState = atom({
  key: 'isLoading',
  default: false
})

function SiteView() {
  const [gpsCoordinates, setGpsCoordinates] = useRecoilState(gpsCoordinatesState)
  const [showMapMarkerForm, setShowMapMarkerForm] = useRecoilState(showMapMarkerFormState)
  const [site, setSite] = useRecoilState(siteState)
  const [siteLocation, setSiteLocation] = useRecoilState(siteLocationState)
  const navigate = useNavigate()
  const routerLocation = useLocation()
  const setAlert = useSetRecoilState(alertState)
  const setModal = useSetRecoilState(modalState)
  const setIsLoading = useSetRecoilState(isLoadingState)
  const urlParams = useParams()

  const updateLatLng = async () => {
    const latLng = split(gpsCoordinates, ',')

    try {
      setIsLoading(true)

      let { data } = await axios.post(`/site/update/${urlParams.id}`, {
        lat: parseFloat(latLng[0]),
        long: parseFloat(latLng[1])
      })

      if (data && data.success) {
        setModal(null)
        setAlert({ type: 'success', content: 'Coordinates updated successfully.' })
        setShowMapMarkerForm(false)
        setGpsCoordinates('')
        getSite()
      }

    } catch (error) {
      Bugsnag.notify(error)
      setModal(null)
      setAlert({ type: 'error', content: 'Failed to update coordinates.' })
    } finally {
      setIsLoading(false)
    }
  }

  const markers = useMemo(() => {
    if (!isEmpty(site) && site.lat && site.lng) {
      return [
        {
          id: `siteId#${site.id}`,
          position: {
            lat: parseFloat(site.lat),
            lng: parseFloat(site.lng)
          },
          title: site.name,
          fillColor: '#4E5BA6',
          draggable: showMapMarkerForm,
          dragEnd: (event, id) => {
            setGpsCoordinates(`${event.latLng.lat().toFixed(6)}, ${event.latLng.lng().toFixed(6)}`)
          },
        }
      ]
    }

    return []
  }, [site, setGpsCoordinates, showMapMarkerForm])

  const getSite = useCallback(async () => {
    try {
      let { data } = await axios.get(`/site/view/${urlParams.id}?with[]=location&with[]=programs`)

      if (data && data.success) {
        const siteData = data.site

        setSite({
          id: siteData.id,
          name: siteData.name,
          description: siteData.description,
          rtuId: siteData.rtu_id,
          lineLinkId: siteData.line_link_id,
          radioZone: siteData.radio_zone,
          radioLinkId: siteData.radio_link_id,
          fepId: siteData.fep_id,
          locationId: siteData.location_id,
          location: siteData.location,
          lat: siteData.lat,
          lng: siteData.long,
          linkType: siteData.link_type,
          programs: siteData.programs
        })

        if (siteData.lat && siteData.long) {
          setSiteLocation({
            lat: toNumber(siteData.lat),
            lng: toNumber(siteData.long)
          })
        } else {
          const getCoordinates = async () => {
            let { data } = await axios.get(`/google/maps/search/text?query=${siteData.location.address}`)

            if (data.success) {
              setSiteLocation(data.result.geometry.location)
            }
          }

          getCoordinates()
        }
      }
    } catch (error) {
      Bugsnag.notify(error)
      navigate('/404')
    }
  }, [setSite, urlParams, navigate, setSiteLocation])

  useEffect(() => {
    getSite()
  }, [getSite])

  useEffect(() => {
    if (routerLocation.state && routerLocation.state.showAlert) {
      setAlert({ type: 'success', content: routerLocation.state.message })
    }
  }, [routerLocation, setAlert])

  useEffect(() => {
    if (!isEmpty(site) && (isUndefined(site.lat) || isUndefined(site.lng))) {
      setShowMapMarkerForm(true)
    }
  }, [site, setShowMapMarkerForm])

  const updateMapMarker = useCallback(async () => {
    if (!isEmpty(gpsCoordinates)) {
      const latLng = split(gpsCoordinates, ',')
      const hasLettersRegex = /[a-zA-Z]/g

      if (latLng.length === 2 && latLng[0] && latLng[1] && !hasLettersRegex.test(gpsCoordinates)) {
        setSite({
          ...site,
          lat: parseFloat(latLng[0]),
          lng: parseFloat(latLng[1])
        })
      } else {
        let { data } = await axios.get(`/google/maps/search/text?query=${gpsCoordinates}`)

        console.log({
          ...site,
          ...data.result.geometry.location
        })

        if (data.success) {
          setSite({
            ...site,
            ...data.result.geometry.location
          })
          setGpsCoordinates(`${data.result.geometry.location.lat}, ${data.result.geometry.location.lng}`)
        }
      }
    }
  }, [gpsCoordinates, setSite, site, setGpsCoordinates])

  const mapClick = event => {
    if (!isUndefined(site.lat) && !isUndefined(site.lng)) {
      return
    }

    setSite({
      ...site,
      lat: parseFloat(event.latLng.lat().toFixed(6)),
      lng: parseFloat(event.latLng.lng().toFixed(6))
    })

    setGpsCoordinates(`${event.latLng.lat().toFixed(6)}, ${event.latLng.lng().toFixed(6)}`)
  }

  return (
    <PageContainer>
      <div className="row">
        <PageTitle>
          Manage Site
        </PageTitle>
      </div>

      <div className='row'>
        <DetailContainer className="col-12 col-md-4">
          <Card>
            <CardTitle>
              <SiteName>
                {site.name}
              </SiteName>

              <div className='more-actions'>
                <DropdownList
                  icon={<ActionIcon />}
                  options={[
                    {
                      label: 'Edit Site',
                      onClick: () => {
                        setModal({
                          name: 'site',
                          data: { site: site, locationId: site.locationId }
                        })
                      }
                    },
                    {
                      label: 'Edit Coordinates',
                      onClick: () => {
                        setShowMapMarkerForm(true)
                      }
                    },
                    {
                      label: 'Delete Site',
                      onClick: () => {
                        setModal({
                          name: 'warning',
                          data: {
                            endpoint: `/site/delete/${site.id}`,
                            title: 'Delete site',
                            content: site.programs.length ? `${site.name} has ${site.programs.length} associated program${site.programs.length > 1 ? 's' : ''}. Please delete the associated program${site.programs.length > 1 ? 's' : ''} before deleting the site.` : `Are you sure you want to delete ${site.name}? This action cannot be undone.`,
                            successFlashMessage: 'Site deleted successfully.',
                            redirect: '/sites',
                            disableConfirmButton: site.programs.length ? true : false
                          }
                        })
                      }
                    }
                  ]}
                />
              </div>
            </CardTitle>

            <CardRow>
              <CardRowTitle>Description</CardRowTitle>
              <CardRowContent>{site.description}</CardRowContent>
            </CardRow>

            <CardRow>
              <CardRowTitle>Site ID</CardRowTitle>
              <CardRowContent>{site.rtuId}</CardRowContent>
            </CardRow>

            <CardRow>
              <CardRowTitle>Communication Type</CardRowTitle>
              <CardRowContent>
                {upperFirst(toLower(site.linkType))}
              </CardRowContent>
            </CardRow>

            {site.linkType === 'LINE' && (
              <CardRow>
                <CardRowTitle>Line Link ID</CardRowTitle>
                <CardRowContent>{site.lineLinkId}</CardRowContent>
              </CardRow>
            )}

            {site.linkType === 'RADIO' && (
              <CardRow>
                <CardRowTitle>Radio Zone</CardRowTitle>
                <CardRowContent>{site.radioZone}</CardRowContent>
              </CardRow>
            )}

            {site.linkType === 'RADIO' && (
              <CardRow>
                <CardRowTitle>Radio Link ID</CardRowTitle>
                <CardRowContent>{site.radioLinkId}</CardRowContent>
              </CardRow>
            )}
          </Card>

          {showMapMarkerForm && (
            <>
              <SetPinBlock>
                <div className="title">Pinpoint you site</div>

                <div className="content">
                  {
                    !isUndefined(site.lat) && !isUndefined(site.lng) ?
                    'Define the exact location for your site by providing GPS coordinates or by dragging the site pin on the map.' :
                    'Click on the map to pinpoint your site. Alternatively, please provide GPS coordinates to locate your site.'
                  }
                </div>

                <MarkerForm>
                  <MarkerFields className="row">
                    <div className="col-12 col-sm-8 col-md-12 col-xl-7 field-wrapper">
                      <InputField
                        placeholder="Enter GPS coordinates"
                        onChange={(e) => {
                          setGpsCoordinates(e.target.value)
                        }}
                        isLoading={false}
                        searchIcon={true}
                        value={gpsCoordinates || ''}
                        background="white"
                      />
                    </div>
                    <div className="col-12 col-sm-4 col-md-12 col-xl-5 field-wrapper">
                      <Anchor
                        style={{
                          width: '100%'
                        }}
                        onClick={() => {
                          updateMapMarker()
                        }}
                      >
                        Update pin
                      </Anchor>
                    </div>
                  </MarkerFields>
                </MarkerForm>
              </SetPinBlock>
              <div className="button-container">
                <Anchor
                  style={{
                    width: '100%'
                  }}
                  onClick={() => {
                    setModal({
                      name: 'confirmation',
                      data: {
                        title: "Save GPS coordinates",
                        content: `Would you like to save the GPS coordinates ${gpsCoordinates} for ${site.name}? `,
                        onConfirm: () => { updateLatLng() }
                      }
                    })
                  }}
                >
                  Save
                </Anchor>
              </div>
            </>
          )}

          {!showMapMarkerForm && (
            <div className='button-container'>
              <Anchor
                style={{
                  width: 'calc(50% - 5px)'
                }}
                onClick={() => {
                  setModal({
                    name: 'program',
                    data: {
                      siteId: site.id
                    }
                  })
                }}
              >
                Add Program
              </Anchor>
            </div>
          )}
        </DetailContainer>

        <MapContainer className="col-12 col-md-8">
          <Map
            center={siteLocation || []}
            markers={markers}
            onClick={(event) => {
              mapClick(event)
            }}
          />
        </MapContainer>
      </div>
    </PageContainer>
  )
}

export default SiteView
