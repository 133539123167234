import * as yup from 'yup'
import Bugsnag from '@bugsnag/js'
import DropdownList from '@components/DropdownList'
import Input from '@components/form/Input'
import InputError from '@components/form/InputError'
import LightSwitch from '@components/form/LightSwitch'
import Modal from '@components/Modal'
import Select from '@components/form/Select'
import axios from '@utilities/axios'
import moment from 'moment'
import styled from 'styled-components'
import { Anchor, Button } from '@components/form/Buttons'
import { ReactComponent as PumpIcon } from '@icons/pump.svg'
import { atom, useRecoilState, useSetRecoilState } from 'recoil'
import { forEach, isFunction, isUndefined, replace } from 'lodash'
import { map, lowerCase, split, toNumber } from 'lodash'
import { useForm, Controller } from 'react-hook-form'
import { useMemo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 28px;
`

const ButtonGroup = styled.div`
  display: flex;
  width: calc(50% - 5px);
`

const FieldWrapper = styled.div`
  flex-basis: ${props => `calc(${props.fieldWidth}% - 30px)` || '100%'};
  margin: 0 15px;
`

const Label = styled.div`
  color: var(--text-dark);
  display: flex;
  font-size: 14px;
  margin-bottom: 6px;
  margin-top: 20px;
`

const GroupedInputs = styled.div`
  align-items: center;
  display: flex;

  input {
    margin-right: 20px;
  }
`

const FertigationForm = styled.form`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;

  label {
    margin-top: 20px;
  }

  .buttons {
    flex-basis: 100%;
    margin-top: 40px;
    justify-content: space-around;

    a, button {
      flex-basis: calc(50% - 30px);
    }
  }
`

const materialsState = atom({
  key: 'materials',
  default: {}
})

const alertState = atom({
  key: 'alert',
  default: {}
})

const isLoadingState = atom({
  key: 'isLoading',
  default: false
})

const generateSchema = props => {
  let rules = {}

  forEach(props.data.function.attributes.fields, field => {
    if (field.layout === 'textField') {
      rules[field.attributes.key] = yup.string().label(field.attributes.name).nullable().required().min(3).max(25)
    }

    if (field.layout === 'numberField') {
      rules[field.attributes.key] = yup.number().label(field.attributes.name).nullable().positive().required().transform(value => value === toNumber(value) ? value : null)
    }

    if (field.layout === 'selectField') {
      rules[field.attributes.key] = yup.mixed().label(field.attributes.name).populatedObject()
    }

    if (field.layout === 'selectField' || field.layout === 'materialSelectField') {
      rules[field.attributes.key] = yup.mixed().label(field.attributes.name).populatedObject()
    }

    if (field.layout === 'timeField') {
      rules[`${field.attributes.key}Days`] = yup.number().label(field.attributes.name).required().min(0).max(7)
      rules[`${field.attributes.key}Hours`] = yup.number().label(field.attributes.name).required().min(0).max(23)
      rules[`${field.attributes.key}Minutes`] = yup.number().label(field.attributes.name).required().min(0).max(59)
    }
  })

  return yup.object(rules)
}

function HardwareSettingsModal(props) {
  const [materials, setMaterials] = useRecoilState(materialsState)
  const [isLoading, setIsLoading] = useRecoilState(isLoadingState)
  const setAlert = useSetRecoilState(alertState)
  const navigate = useNavigate()
  const { setValue, control, register, handleSubmit, watch, formState: { errors } } = useForm({
    resolver: yupResolver(generateSchema(props))
  })

  const watchFields = {}

  forEach(props.data.function.attributes.fields, field => {
    if (field.layout === 'materialSelectField') {
      watchFields[field.attributes.key] = watch(field.attributes.key)
    }
  })

  function convertMinutes(num) {
    const days = Math.floor(num / 1440)
    const hours = Math.floor((num - (days * 1440)) / 60)
    const minutes = Math.round(num % 60)

    return {
      days: days || null,
      hours: hours || null,
      minutes: minutes || null
    }
  }

  useEffect(() => {
    if (props.data.functionData?.id) {
      forEach(props.data.function.attributes.fields, field => {
        if (field.layout === 'timeField') {
          let duration = moment.duration(props.data.functionData?.data.values[field.attributes.key], 'minutes')

          setValue(`${field.attributes.key}Days`, duration.days())
          setValue(`${field.attributes.key}Hours`, duration.hours())
          setValue(`${field.attributes.key}Minutes`, duration.minutes())
        }
      })
    }
  }, [setValue, props])


  useMemo(async () => {
    try {
      const { data } = await axios.get('/materials')

      if (data && data.materials) {
        setMaterials(data.materials)
      }
    } catch (error) {
      setAlert({ type: 'error', content: 'Failed to retrieve function materials.' })
      Bugsnag.notify(error)
    }
  }, [setMaterials, setAlert])

  const onSubmit = async (data, afterSave = null) => {
    setAlert(null)

    map(data, (inputValue, inputKey) => {
      if (inputKey.endsWith("Days")) {
        const trimmedKey = replace(inputKey, 'Days', '')
        const valueToMinutes = inputValue > 0 ? inputValue * 1440 : 0
        data[trimmedKey] = data[trimmedKey] ? data[trimmedKey] + valueToMinutes : valueToMinutes
        delete data[inputKey]
      }

      if (inputKey.endsWith("Hours")) {
        const trimmedKey = replace(inputKey, 'Hours', '')
        const valueToMinutes = inputValue > 0 ? inputValue * 60 : 0
        data[trimmedKey] = data[trimmedKey] ? data[trimmedKey] + valueToMinutes : valueToMinutes
        delete data[inputKey]
      }

      if (inputKey.endsWith("Minutes")) {
        const trimmedKey = replace(inputKey, 'Minutes', '')
        data[trimmedKey] = data[trimmedKey] ? data[trimmedKey] + inputValue : inputValue
        delete data[inputKey]
      }
    })

    try {
      setIsLoading(true)
      const updateId = props.data.functionData?.id

      let { data: responseData } = await axios.post(`/function-data/${updateId ? `update/${updateId}` : 'create'}`, {
        program_id: props.data?.programData?.id,
        program_function_id: props.data.programData?.programFunctionId || props.data.programData.program_function_id,
        data: {
          internal: {
            function_key: props.data.function.key
          },
          values: data,
        },
      })

      if (isFunction(afterSave)) {
        await afterSave()
      }

      if (responseData.success) {
        navigate(window.location.pathname)
        setAlert({ type: 'success', content: `${responseData.functionData.data.values.name} has been successfully ${updateId ? 'edited' : 'created'}.` })

        if (isFunction(props.data.onSave)) {
          props.data.onSave()
        }

        props.close()
      }
    } catch (error) {
      console.log(error)
      setAlert({ type: 'error', content: 'An error has occured and we were not able to save this program function. Please try again.' })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      icon={<PumpIcon />}
      iconFill="true"
      title={`${props.data.functionData?.id ? 'Edit' : 'Add'} ${lowerCase(props.data.function.attributes?.name)}`}
      close={props.close}
      closeOnOutsideClick={props.closeOnOutsideClick}
    >
      <FertigationForm onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        {map(props.data.function.attributes?.fields, (field, key) => {
          if (field.layout === 'textField') {
            return (
              <FieldWrapper fieldWidth={field.attributes.width} key={key}>
                <Input
                  label={field.attributes.name}
                  className={errors[field.attributes.key] && 'error'}
                  {...register(field.attributes.key, { value: props.data.functionData?.data.values[field.attributes.key] })}
                />
                {errors[field.attributes.key] && <InputError message={errors[field.attributes.key].message} />}
              </FieldWrapper>
            )
          }

          if (field.layout === 'numberField') {
            return (
              <FieldWrapper fieldWidth={field.attributes.width} key={key}>
                <Input
                  label={field.attributes.name}
                  type="number"
                  step="0.0001"
                  className={errors[field.attributes.key] && 'error'}
                  {...register(field.attributes.key, { value: props.data.functionData?.data.values[field.attributes.key] })}
                />
                {errors[field.attributes.key] && <InputError message={errors[field.attributes.key].message} />}
              </FieldWrapper>
            )
          }

          if (field.layout === 'selectField') {
            const options = split(field.attributes.options, '\n')

            return (
              <FieldWrapper fieldWidth={field.attributes.width} key={key}>
                <Controller
                  control={control}
                  name={field.attributes.key}
                  defaultValue={props.data.functionData?.data.values[field.attributes.key] || []}
                  render={({ field: controlledField }) => (
                    <Select
                      {...controlledField}
                      isMulti={false}
                      isSearchable={true}
                      label={field.attributes.name}
                      options={map(options, option => {
                        return { value: option, label: option}
                      })}
                      hasError={!!errors[field.attributes.key]}
                    />
                  )}
                />
                {errors[field.attributes.key] && <InputError message={errors[field.attributes.key].message} />}
              </FieldWrapper>
            )
          }

          if (field.layout === 'materialSelectField') {
            return (
              <FieldWrapper fieldWidth={field.attributes.width} key={key}>
                <Controller
                  control={control}
                  name={field.attributes.key}
                  defaultValue={props.data.functionData?.data.values[field.attributes.key] || []}
                  render={({ field: controlledField }) => (
                    <Select
                      {...controlledField}
                      isMulti={true}
                      isSearchable={true}
                      label={field.attributes.name}
                      placeholder={`Select ${lowerCase(field.attributes.name)}`}
                      options={map(materials, material => {
                        return { value: material.id, label: material.title, color: material.color}
                      })}
                      isOptionDisabled={option => watchFields[field.attributes.key].length >= 1}
                      hasError={!!errors[field.attributes.key]}
                    />
                  )}
                />
                {errors[field.attributes.key] && <InputError message={errors[field.attributes.key].message} />}
              </FieldWrapper>
            )
          }

          if (field.layout === 'lightSwitchField') {
            return (
              <FieldWrapper fieldWidth={field.attributes.width} key={key}>
                <LightSwitch
                  label={field.attributes.name}
                  onToggle={(name, value) => {
                    setValue(name, value)
                  }}
                  defaultState={isUndefined(props.data.functionData?.data.values[field.attributes.key]) ? field.attributes.on_by_default : props.data.functionData?.data.values[field.attributes.key]}
                  {...register(field.attributes.key, { value: isUndefined(props.data.functionData?.data.values[field.attributes.key]) ? field.attributes.on_by_default : props.data.functionData?.data.values[field.attributes.key] })}
                />
              </FieldWrapper>
            )
          }

          if (field.layout === 'timeField') {
            const showError = errors[`${field.attributes.key}Days`] ||
            errors[`${field.attributes.key}Hours`] ||
            errors[`${field.attributes.key}Minutes`] ||
            errors[field.attributes.key]

            return (
              <FieldWrapper fieldWidth={field.attributes.width} key={key}>
                <Label>{field.attributes.name}</Label>

                <GroupedInputs>
                  <Input
                    placeholder="days"
                    maxlength="2"
                    type="number"
                    width="80px"
                    textCenter
                    className={errors[`${field.attributes.key}Days`] && 'error'}
                    {...register(`${field.attributes.key}Days`)}
                  />

                  <Input
                    placeholder="hours"
                    maxlength="2"
                    type="number"
                    width="80px"
                    textCenter
                    className={errors[`${field.attributes.key}Hours`] && 'error'}
                    {...register(`${field.attributes.key}Hours`)}
                  />

                  <Input
                    placeholder="min"
                    maxlength="2"
                    type="number"
                    width="80px"
                    textCenter
                    className={errors[`${field.attributes.key}Minutes`] && 'error'}
                    {...register(`${field.attributes.key}Minutes`)}
                  />
                </GroupedInputs>

                {
                  showError &&
                  <InputError message={`${field.attributes.name} is a required field`} />
                }
              </FieldWrapper>
            )
          }
        })}

        <ButtonWrapper className='buttons'>
          <Anchor
            style={{
              width: 'calc(50% - 5px)'
            }}
            className='transparent'
            onClick={() => {
              setAlert(null)
              props.close()
            }}
          >
            Close
          </Anchor>

          <ButtonGroup>
            <Button
              disabled={isLoading ? true : false}
              style={{
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                flexGrow: 1
              }}
            >
              {isLoading ? <div className="primary-loader light"></div> : 'Save'}
            </Button>
            <DropdownList
              disabled={isLoading ? true : false}
              wrapperStyle={{
                display: 'flex'
              }}
              style={{
                top: '-100%',
              }}
              icon={
                <Anchor
                  disabled={isLoading ? true : false}
                  style={{
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    borderLeft: '1px solid rgba(255, 255, 255, 0.4)'
                  }}
                >
                  <i className="fa-solid fa-caret-down"></i>
                </Anchor>
              }
              options={[
                {
                  label: 'Save & Sync',
                  onClick: () => {
                    let afterSave = async () => {
                      await axios.post(`/program/sync/${props.data.programData.id}`)
                    }

                    handleSubmit(data => onSubmit(data, afterSave))()
                  }
                }
              ]}
              list={true}
            />
          </ButtonGroup>
        </ButtonWrapper>
      </FertigationForm>
    </Modal>
  )
}

export default HardwareSettingsModal
