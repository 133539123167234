import OutsideClickHandler from 'react-outside-click-handler'
import styled from 'styled-components'
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService"
import { useState } from 'react'

import InputField from '@components/form/Input'
import axios from '@utilities/axios'
import { isFunction } from 'lodash'

const Options = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 1px 5px rgba(16, 24, 40, 0.25);
  position: absolute;
  transform: translateY(7px);
  width: calc(100% - 48px);
  z-index: 1;
`

const Item = styled.div`
  background-color: white;
  color: var(--text-dark);
  padding: 7px 12px;

  &:first-child {
    border-radius: 8px 8px 0 0;
  }

  &:last-child {
    border-radius: 0 0 8px 8px;
  }

  &:hover {
    background-color: #ddebff;
    cursor: pointer;
  }
`

function GooglePlacesAutocomplete(props) {
  const [showOptions, setShowOptions] = useState(false)

  const {
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    debounce: 1000
  })

  return (
    <>
      <InputField
        {...props}
        placeholder="Search"
        onChange={e => {
          getPlacePredictions({ input: e.target.value })
          setShowOptions(true)

          if (isFunction(props.onChange)) {
            props.onChange(props.name, e.target.value)
          }
        }}
        isLoading={isPlacePredictionsLoading}
        searchIcon={true}
        value={props.value}
      />

      <OutsideClickHandler
        onOutsideClick={() => {
          if (showOptions) {
            setShowOptions(false)
          }
        }}
      >
        <Options>
          {showOptions && (placePredictions.map((item) => {
            return <Item
              key={item.description}
              onClick={async () => {
                setShowOptions(false)

                if (isFunction(props.onChange)) {
                  props.onChange(props.name, item.description)
                }

                if (isFunction(props.coordinatesChange)) {
                  let { data } = await axios.get(`/google/maps/search/place-id?query=${item.place_id}`)

                  if (data.success) {
                    props.coordinatesChange(props.name, data.result.geometry.location)
                  }
                }
              }}
            >
              {item.description}
            </Item>
          }))}
        </Options>
      </OutsideClickHandler>
    </>
  )
}

export default GooglePlacesAutocomplete

