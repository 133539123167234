import styled from 'styled-components'

const CenterContent = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
`

const Content = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  margin: -20px 0;

  iframe {
    border: 0;
    border-radius: 8px;
    width: 100%;
    height: calc(100vh - 93px);
  }
`

function Reporting() {
  return (
    <CenterContent>
      <Content>
        <iframe title="reporting" src={process.env.REACT_APP_KIBANA_URL}></iframe>
      </Content>
    </CenterContent>
  )
}

export default Reporting
