import styled from 'styled-components'
import { rem, rgba } from 'polished'

const PillContent = styled.div`
  align-items: center;
  display: inline-flex;
  font-size: ${rem(12)};
  color: ${props => props.color ? props.color : '#101828'};
  background: ${props => rgba(props.color || '#101828', 0.1)};
  padding: 2px 8px;
`

const Dot = styled.div`
  background-color: ${props => props.color};
  border-radius: 100%;
  display: inline-block;
  margin-right: 5px;
  height: 6px;
  width: 6px;
`

const PillBackground = styled.div`
  background: #ffffff;
  border-radius: 20px;
  display: inline-flex;
  margin-right: 8px;
  overflow: hidden;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-left: ${props => props.hasDot ? '4px' : '0'};

  &:last-of-type {
    margin-right: 0;
  }
`

function Pill(props) {
  return (
    <PillBackground hasDot={props.hasDot}>
      <PillContent color={props.color}>
        {props.hasDot && (<Dot color={props.color} />)} {props.children}
      </PillContent>
    </PillBackground>
  )
}

export default Pill
