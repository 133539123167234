import axios from 'axios'

const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: `${process.env.REACT_APP_API_URL}/api`
})

axiosInstance.interceptors.response.use(response => {
  return response
}, error => {
  if (error.response.status === 401) {
    window.location = '/user/logout'
  }

  return Promise.reject(error)
})

export default axiosInstance